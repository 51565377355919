import Advertorial from '@media/rui-advertorial';
import { useEffect, useRef } from 'react';
import { getSizesToRequest, getStyle, saveSize } from '../ad-functions/pinAds';
import {
  AdProps,
  AdServerResponse,
  HtmlAdParams,
  RuiAdOptionsWithDataLayer,
} from './types';

const roadblockAdKeys: {
  [key: string]: boolean;
} = {};

const Ad = ({
  adKey,
  id,
  appName,
  className,
  cacheKey,
  position,
  sizes,
  pinAdsConfig,
  renderSizes,
  renderPrebidNativeSize,
  section,
  autoHide,
  heading,
  dataLayer,
  logError,
  onLoad,
  leadClickCallback,
  customParams,
  isPrebid = false,
}: AdProps): JSX.Element => {
  const adEl = useRef<HTMLDivElement>(null);
  const shouldSaveSize = pinAdsConfig != undefined;

  const sizesToRequest = shouldSaveSize
    ? getSizesToRequest(sizes, adKey, position)
    : sizes;

  useEffect(() => {
    if (adEl.current) {
      adEl.current.innerHTML = '';
    }

    let normalisedCustomParams = customParams;

    if (customParams?.rb === 'true') {
      if (roadblockAdKeys[adKey] == undefined) {
        roadblockAdKeys[adKey] = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { rb, ...customParamsWithoutRb } = customParams;

        normalisedCustomParams = customParamsWithoutRb;
      }
    }

    const adOptions: RuiAdOptionsWithDataLayer = {
      appName,
      container: adEl.current,
      config: {
        sz: sizesToRequest.join(','),
        autoHide,
        renderSize: renderSizes?.join(','),
        renderPrebidNativeSize,
        showHeading: heading != undefined,
        headingAlignment: heading,
        isPrebid,
        ...(sizes.includes('native') && { version: '5' }),
      },
      params: {
        pos: position,
        ...(section && { sect: section }),
        ...normalisedCustomParams,
      },
      dataLayer,
      logError,
      cacheKey: cacheKey,
      loadCompleteCallback: (
        adProperties?: Record<string, unknown>,
        isAdLoaded?: boolean,
      ) => {
        adProperties != undefined &&
          shouldSaveSize &&
          saveSize(adProperties as AdServerResponse, adKey, position);
        onLoad?.(adProperties as AdServerResponse, !!isAdLoaded);
      },
      resizeCompleteCallback: (adProperties: HtmlAdParams) => {
        adProperties != undefined &&
          shouldSaveSize &&
          saveSize(adProperties, adKey, position);
      },
      leadClickCallback,
    };

    Advertorial.createAdWithDataLayer(adOptions);
  }, [adKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <aside
      style={
        shouldSaveSize ? getStyle(sizesToRequest, pinAdsConfig) : undefined
      }
      id={id}
      className={className}
      aria-hidden="true"
      ref={adEl}
    />
  );
};

export default Ad;
