import {
  Address,
  AddToCalendarDialog,
  Event,
} from "@realestate.com.au/planner-add-to-calendar";
import {
  addEventToPlanner,
  EventInput,
} from "@realestate.com.au/planner-event-service";
import React, { FC, useEffect, useState } from "react";
import RegistrationDialog from "../shared/registration-dialog";
import AddedToPlanDialog from "./AddedToPlanDialog";
import {
  ErrorDialog,
  ErrorMessage,
  errorMessageMap,
  ErrorType,
} from "../shared/error-dialog";
import { getErrorType } from "../shared/errorHandlers";
import SuccessDialog from "../shared/success-dialog";
import { DialogType, EventTrackers, SingleEvent } from "lib/types";

export type Props = {
  address: Address;
  event: Event;
  shouldShowRegister?: boolean;
  shouldCallAddEventToPlanner?: boolean;
  eventTrackers: EventTrackers;
  input: EventInput;
  onClose: () => void;
  templatedUrl: string;
  onSuccess: (eventId: string) => void;
};

export const DialogBox: FC<Props> = ({
  address,
  event,
  input,
  onClose,
  templatedUrl,
  onSuccess,
  shouldShowRegister = false,
  shouldCallAddEventToPlanner = true,
  eventTrackers,
}) => {
  const [errorType, setErrorType] = useState<ErrorType | null>(null);
  const [dialog, setDialog] = useState<DialogType | null>(null);
  const {
    addToCalendar: eventTriggersProps,
    inspectionRegistration: registrationTrackers,
    addToPlanner: addToPlannerTracker,
  } = eventTrackers;
  const addedToPlanCalendarTrackers = eventTriggersProps(
    "planner_confirmation_modal_inspection"
  );
  const registrationCalendarTrackers = eventTriggersProps(
    "planner_confirmation_modal_inspection_registration"
  );

  useEffect(() => {
    async function handleMount() {
      try {
        const result = await addEventToPlanner(input);
        onSuccess(result.eventId);
        addToPlannerTracker("add");
        setDialog("addedToPlan");
      } catch (err) {
        handleError(err);
      }
    }
    if (shouldCallAddEventToPlanner) {
      void handleMount();
    }
  }, [input, onSuccess, addToPlannerTracker, shouldCallAddEventToPlanner]);

  const handleError = (error: unknown) => {
    setErrorType(getErrorType(error));
    setDialog("error");
  };

  const handleClose = () => {
    onClose();
    setErrorType(null);
    setDialog(null);
  };

  const onAddedToPlanAddToCalendarClick = () => {
    setDialog("addedToPlanCalendar");
    addedToPlanCalendarTrackers.onSaveToCalendar();
  };

  const onRegistrationAddToCalendarClick = () => {
    setDialog("registrationCalendar");
    registrationCalendarTrackers.onSaveToCalendar();
  };

  if (dialog === "addedToPlanCalendar") {
    return (
      <AddToCalendarDialog
        event={event}
        address={address}
        onClose={handleClose}
        onSaveToCalendarSuccess={
          addedToPlanCalendarTrackers.onSaveToCalendarSuccess
        }
      />
    );
  }

  if (dialog === "registrationCalendar") {
    return (
      <AddToCalendarDialog
        event={event}
        address={address}
        onClose={handleClose}
        onSaveToCalendarSuccess={
          registrationCalendarTrackers.onSaveToCalendarSuccess
        }
      />
    );
  }

  if (dialog === "success") {
    return (
      <SuccessDialog
        onClose={handleClose}
        event={event}
        address={address}
        templatedUrl={templatedUrl}
        onAddToCalendarClick={onRegistrationAddToCalendarClick}
        sourcePage={eventTrackers.sourcePage}
      />
    );
  }

  if (dialog === "registration") {
    const singleEvent: SingleEvent = {
      input,
      displayDate: event.displayDate,
      displayTime: event.displayTime,
    };
    return (
      <RegistrationDialog
        eventOrEvents={singleEvent}
        address={address}
        onClose={handleClose}
        templatedUrl={templatedUrl}
        onRegistrationSuccess={() => {
          registrationTrackers.inspectionRegistrationFormSubmitted();
          setDialog("success");
        }}
      />
    );
  }

  if (dialog === "addedToPlan") {
    return (
      <AddedToPlanDialog
        event={event}
        address={address}
        onClose={handleClose}
        onAddToCalendarClick={onAddedToPlanAddToCalendarClick}
        shouldShowRegister={shouldShowRegister}
        showRegistrationDialog={() => {
          registrationTrackers.inspectionRegistrationFormOpen();
          setDialog("registration");
        }}
        templatedUrl={templatedUrl}
        sourcePage={eventTrackers.sourcePage}
      />
    );
  }

  if (dialog === "error") {
    const errorMessage: ErrorMessage = errorMessageMap[errorType ?? "unknown"];
    return <ErrorDialog errorMessage={errorMessage} onClose={handleClose} />;
  }

  return null;
};

export default DialogBox;
