import { SAFEFRAME_ASSETS_PATH } from '../../../rui-env-variables';

const renderOutstreamVideoSafeframe = (content, renderTarget, adSpot) =>
  new Promise((resolve) => {
    const isDesktop = adSpot.params.platform !== 'msite';
    const customWidth = isDesktop ? 640 : 340;
    const customHeight = isDesktop ? 360 : 191;

    const iframeEl = document.createElement('iframe');
    iframeEl.src = `${SAFEFRAME_ASSETS_PATH}/outstream-video/outstream-video.html`;
    iframeEl.width = customWidth;
    const advertisementBarHeight = 19;
    iframeEl.height = customHeight + advertisementBarHeight;
    iframeEl.scrolling = 'no';
    iframeEl.sandbox =
      'allow-scripts allow-popups allow-popups-to-escape-sandbox allow-same-origin';
    iframeEl.style.display = 'block';
    iframeEl.style.margin = 'auto';

    iframeEl.addEventListener('load', () => {
      const data = {
        message: 'init_video',
        adContentJson: content,
        customWidth,
        customHeight,
      };
      iframeEl.contentWindow.postMessage(JSON.stringify(data), '*');
      resolve(iframeEl);
    });

    renderTarget.appendChild(iframeEl);
  });

export default renderOutstreamVideoSafeframe;
