import { lowercase, removeNonAlpha } from '../targeting/generateTargeting';

function shouldNotConvert(value) {
  return (
    value === undefined || value === null || value === true || value === false
  );
}

const convertKeyToLowerCase = (object) => {
  if (object) {
    return Object.keys(object).reduce((acc, key) => {
      if (shouldNotConvert(acc[key])) {
        return acc;
      }

      return {
        ...acc,
        [key]: acc[key].toString().toLowerCase(),
      };
    }, object);
  }
  return object;
};

const omitObjectKey = (object, omitKey) => {
  return Object.keys(object).reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(object, key) && key !== omitKey) {
      return {
        ...acc,
        [key]: object[key],
      };
    }

    return acc;
  }, {});
};

const uniqueConcat = (firstArray, secondArray) => {
  const availableSizes = firstArray.reduce((acc, value) => {
    if (acc.indexOf(value) === -1) {
      acc.push(value);
    }
    return acc;
  }, secondArray);
  return availableSizes.join(',');
};

const mergeOverrideValues = (values, overrides) => {
  if (overrides === undefined) {
    return values;
  }

  const valueKeys = Object.keys(values);

  Object.keys(overrides).forEach((key) => {
    if (valueKeys.indexOf(key) > -1) {
      values[key] = overrides[key];
    }
  });

  return values;
};

const turnParamsIntoUrl = (params, blackList) => {
  let url = '';
  const paramKeys = Object.keys(params).sort();
  for (let i = 0; i < paramKeys.length; i += 1) {
    const paramKey = paramKeys[i];
    if (blackList.indexOf(paramKey) < 0) {
      //Remove non-alphanumeric characters from the value of the sub parameter
      const paramValue =
        paramKey === 'sub'
          ? removeNonAlpha(lowercase(params[paramKey]))
          : params[paramKey];
      if (paramValue !== null && paramValue !== undefined) {
        let tagValue = '';

        if (typeof paramValue === 'string') {
          tagValue = paramValue.indexOf('|')
            ? paramValue.replace(/\|/g, ',')
            : paramValue;
        } else {
          tagValue = paramValue;
        }

        url += `/${paramKey}=${tagValue}`;
      }
    }
  }
  return url;
};

export {
  convertKeyToLowerCase,
  omitObjectKey,
  uniqueConcat,
  mergeOverrideValues,
  turnParamsIntoUrl,
};
