import { CrossLg } from "@rea-group/construct-kit-core";
import React from "react";
import { StyledButton, StyledText } from "./styles";
import { CloseButton, SmallDialog, Header, Title } from "../styles";
import { ErrorMap, ErrorMessage } from "./types";

export const errorMessageMap: ErrorMap = {
  unknown: {
    title: "Oops! Something's gone wrong.",
    body: "Please refresh the page and try again or contact the agent to schedule an inspection.",
  },
  network: {
    title: "Your internet’s down",
    body: "Looks like your internet connection is missing or too slow. Check it and try again.",
  },
};

type Props = {
  onClose: () => void;
  errorMessage: ErrorMessage;
};

export const ErrorDialog = ({
  onClose,
  errorMessage,
}: Props): React.ReactElement => {
  return (
    <SmallDialog>
      <CloseButton onClick={onClose} icon={<CrossLg />} />
      <Header>
        <Title>{errorMessage.title}</Title>
      </Header>
      <StyledText variant="body01">{errorMessage.body}</StyledText>
      <StyledButton variant="primary" onClick={onClose}>
        OK
      </StyledButton>
    </SmallDialog>
  );
};
