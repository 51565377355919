const renderSafeframe = (safeframeSrc, renderTarget) =>
  new Promise((resolve) => {
    const iframeEl = document.createElement('iframe');
    iframeEl.src = safeframeSrc;
    iframeEl.width = '100%';
    iframeEl.height = '100%';
    iframeEl.sandbox =
      'allow-scripts allow-popups allow-popups-to-escape-sandbox allow-same-origin';

    iframeEl.addEventListener('load', () => {
      resolve(iframeEl);
    });

    renderTarget.appendChild(iframeEl);
  });

export default renderSafeframe;
