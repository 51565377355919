const videoTemplate = `
<section class="native-video">
  <div class="native-video__sponsored-label">
    {{#adsponsoredtext}}
      {{adsponsoredtext}}
    {{/adsponsoredtext}}
    {{^adsponsoredtext}}
      ADVERTISEMENT
    {{/adsponsoredtext}}
  </div>
  <div class="native-video__card">
    {{#termstitle}}
    {{#termstext}}
      <div class="native-video__card-overlay">
        <h2>Conditions</h2>
        <div class="native-video__terms">{{termstext}}</div>
          <button class="native-video__terms-close-button" type="button"><a class="native-video__terms-close-icon"></a>Close</button>
      </div>
    {{/termstext}}
    {{/termstitle}}
    {{#brandwebsiteurl}}
      <a class="native-video__brand" href="{{brandwebsiteurl}}" target="_blank" rel="noopener noreferrer"><img
          src="{{brandlogourl}}" alt="logo"></a>
    {{/brandwebsiteurl}}
    {{^brandwebsiteurl}}
      <div class="native-video__brand"><img src="{{brandlogourl}}" alt="logo"></div>
    {{/brandwebsiteurl}}
    <div class="native-video__container">
      <div class="native-video__image" data-src="{{posterimage}}" style="background-image: url('{{posterimage}}');"></div>
      <div class="native-video__element-container">
        <video class="native-video__video" src="{{videourl}}" preload="metadata" playsinline></video>
        <button class="native-video__sound-control"></button>
      </div>
      <button class="native-video__play"></button>
    </div>
    <div class="native-video__card_cta">
    <div class="native-video__card-content{{#termstitle}}{{#termstext}} native-video__card-content-with-terms{{/termstext}}{{/termstitle}}{{^admessagetext2}} native-video__card-content-no-description{{/admessagetext2}}">
      <div class="native-video__text">
        {{#clickurl}}
          <a class="native-video__message-text native-video__title" href="{{clickurl}}" target="_blank" rel="noopener">
          {{admessagetext}}
          </a>
          {{#admessagetext2}}
          <a class="native-video__message-text native-video__description" href="{{clickurl}}" target="_blank" rel="noopener">
            {{admessagetext2}}
          </a>
          {{/admessagetext2}}
        {{/clickurl}}
        {{^clickurl}}
          <div class="native-video__message-text native-video__title">{{admessagetext}}</div>
          {{#admessagetext2}}
            <div class="native-video__message-text native-video__description">{{admessagetext2}}</div>
          {{/admessagetext2}}
        {{/clickurl}}
      </div>
      <div class="native-video__cta">
        {{#actionTypeCall}}
          {{#actiondata}}
            <a class="native-video__action-link" href="tel:{{actiondata}}" onclick="fetch('{{actionurl}}')">
              {{#actionmessagetext}}
                {{actionmessagetext}}
              {{/actionmessagetext}}
              {{^actionmessagetext}}
                Call now
              {{/actionmessagetext}}
            </a>
          {{/actiondata}}
        {{/actionTypeCall}}
        {{#actionTypeWebsite}}
          <a class="native-video__action-link" href="{{actiondata}}" target="_blank" onclick="fetch('{{actionurl}}')">
            {{#actionmessagetext}}
              {{actionmessagetext}}
            {{/actionmessagetext}}
            {{^actionmessagetext}}
              Tap for more
            {{/actionmessagetext}}
          </a>
        {{/actionTypeWebsite}}
      </div>
    </div>
  {{#termstitle}}
      {{#termstext}}
        <button class="native-video__terms-open-button native-video__terms-up-arrow">{{termstitle}}</button>
      {{/termstext}}
      {{/termstitle}}
  </div>
</section>
`;

export default videoTemplate;
