import { log, logError, logWarning } from '../utils/loggingUtil';
import { PREBID_IFRAME_ORIGIN } from '../rui-env-variables';

const prebidIframeMessageListener = (message, handler) => (event) => {
  if (event.origin !== PREBID_IFRAME_ORIGIN) return;

  let data;
  try {
    data = JSON.parse(event.data);
  } catch {
    return;
  }

  if (data.message && data.message === message) {
    handler(data);
  }
};

let eventListeners = [];
const removePrebidEventListeners = () => {
  eventListeners.forEach((eventListener) =>
    window.removeEventListener('message', eventListener),
  );
  eventListeners = [];
};

const postMsgToRemoveAdUnits = () => {
  if (typeof window.frames.prebid?.postMessage !== 'function') {
    return;
  }
  const message = JSON.stringify({
    message: 'remove_ad_units',
  });
  window.frames.prebid.postMessage(message, PREBID_IFRAME_ORIGIN);
};

const clearPrebidState = () => {
  removePrebidEventListeners();
  postMsgToRemoveAdUnits();
};

const waitForPrebidIfRequired = (adSpot) => {
  if (!adSpot.isPrebid) {
    return Promise.resolve({ bidResult: undefined, isDemoApp: false });
  }

  return new Promise((resolve) => {
    const prebidResultListener = prebidIframeMessageListener(
      `bidding_completed_${adSpot.params.pos}`,
      ({ bidResult, isDemoApp }) => {
        clearTimeout(timeoutId);
        removeListeners();

        log(
          `Prebid result received for position ${adSpot.params.pos}. bidResult: `,
          bidResult,
        );

        resolve({ bidResult, isDemoApp });
      },
    );

    const prebidErrorListener = prebidIframeMessageListener(
      'prebid_iframe_error',
      ({ errorMessage, source, lineno, colno, error }) => {
        clearTimeout(timeoutId);
        removeListeners();

        logError(
          `Skipping Prebid for position ${adSpot.params.pos} as there was an error in Prebid iframe. ${errorMessage} at ${source}:${lineno}:${colno}`,
          error,
        );

        resolve({ bidResult: undefined, isDemoApp: false });
      },
    );

    window.addEventListener('message', prebidResultListener, false);
    window.addEventListener('message', prebidErrorListener, false);

    eventListeners = [
      ...eventListeners,
      prebidResultListener,
      prebidErrorListener,
    ];

    const removeListeners = () => {
      window.removeEventListener('message', prebidResultListener);
      window.removeEventListener('message', prebidErrorListener);
    };

    const prebidTimeout = 5000;

    const timeoutId = setTimeout(() => {
      removeListeners();

      logWarning(
        `Skipping Prebid for position ${adSpot.params.pos} as it took longer than ${prebidTimeout}ms to wait for Prebid auction results.`,
      );

      resolve({ bidResult: undefined, isDemoApp: false });
    }, prebidTimeout);
  });
};

export { waitForPrebidIfRequired, clearPrebidState };
