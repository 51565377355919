import getDate from './getDate';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
} from './utils/localStorageUtil';

export const PERSONALISED_ADS_LOGGED_IN_CACHE_KEY =
  'allow_personalised_ads_my_account';

export const PERSONALISED_ADS_ANONYMOUS_CACHE_KEY =
  'allow_personalised_ads_anonymous';

export const PERSONALISED_ADS_PREF_ID = 'allow_personalised_ads';

export const convertResponseToJson = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response.json();
};

export const arePersonalisedAdsAllowed = (prefs) => {
  const allowPersonalisedAdsPref = prefs.find(
    (p) => p.identity === PERSONALISED_ADS_PREF_ID,
  );
  return !allowPersonalisedAdsPref || allowPersonalisedAdsPref.enabled;
};

export const getDate24HoursLater = () =>
  new Date(getDate().getTime() + 24 * 60 * 60 * 1000);

export const cacheExistsAndNotExpired = (cache) =>
  cache && new Date(cache.expiry) > getDate();

export const allowPersonalisedAdsCache = () =>
  JSON.parse(getLocalStorageItem(PERSONALISED_ADS_LOGGED_IN_CACHE_KEY));

export const outputErrorToConsole = (err) => console.error(err.message);

export const setAllowPersonalisedAdsCache = (
  myAccountAllowPersonalisedAds,
  userId,
) => {
  const allowPersonalisedAdsMyAccount = {
    value: myAccountAllowPersonalisedAds,
    expiry: getDate24HoursLater(),
    id: userId || null,
  };

  setLocalStorageItem(
    PERSONALISED_ADS_LOGGED_IN_CACHE_KEY,
    JSON.stringify(allowPersonalisedAdsMyAccount),
  );
};

const isNewLoginSession = (cache, userId) => cache && cache.id !== userId;

export const removeTokenIfInvalid = (isUserLoggedIn, userId) => {
  if (
    !isUserLoggedIn ||
    isNewLoginSession(allowPersonalisedAdsCache(), userId)
  ) {
    removeLocalStorageItem(PERSONALISED_ADS_LOGGED_IN_CACHE_KEY);
  }
};
