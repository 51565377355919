import { Modal } from "@rea-group/construct-kit-core";
import React, { SyntheticEvent } from "react";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: (e: SyntheticEvent) => void;
  modalMountID: string;
  isLargeScreen: boolean;
};
export const ResponsiveModal = ({
  children,
  isOpen,
  onClose,
  modalMountID,
  isLargeScreen,
}: Props): React.ReactElement => {
  const fullScreenStyle = {
    style: { content: { height: "100%", width: "100%" } },
  };
  const reactModalProps = isLargeScreen ? undefined : fullScreenStyle;

  return (
    <Modal
      reactModalProps={reactModalProps}
      getAppElement={() => modalMountID}
      onRequestClose={(e) => {
        e.stopPropagation();
        onClose(e);
      }}
      opened={isOpen}
    >
      {children}
    </Modal>
  );
};
