const loadScriptElement = (scriptElement, adContainer) =>
  new Promise((resolve) => {
    scriptElement.onload = resolve;

    adContainer.appendChild(scriptElement);
  });

const createScriptElement = (adLink) => {
  const scriptElement = document.createElement('script');
  scriptElement.src = `${adLink}`;
  scriptElement.async = true;
  scriptElement.type = 'text/javascript';

  return scriptElement;
};

const generateJavaScriptAd = (adJsLink, adContainer, loadCompleteCallback) => {
  const scriptElement = createScriptElement(adJsLink);

  return loadScriptElement(scriptElement, adContainer).then(() => {
    if (typeof loadCompleteCallback === 'function') {
      loadCompleteCallback({ isAdLoaded: true }, true);
    }
  });
};

export default generateJavaScriptAd;
