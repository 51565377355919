import { Button, H2, Text, spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

export const EnquiryButton = styled(Button).attrs({ variant: 'primary' })`
  margin-top: ${spacingHelper('extraSmall')};
`;

export const StyledEnquiryForm = styled.form`
  padding: ${spacingHelper('medium')};
`;

export const StyledProjectTitle = styled(H2)`
  padding: ${spacingHelper('extraSmall')} 0 ${spacingHelper('medium')} 0;
`;

export const StyledText = styled(Text)`
  margin: ${spacingHelper('medium')} 0 ${spacingHelper('large')} 0;
`;

export const StyledBuyTime = styled.div`
  display: flex;
  flex-direction: column;
  > {
    &:not(:last-child) {
      margin-bottom: ${spacingHelper('medium')};
    }
  }
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: flex-start;
    > {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: ${spacingHelper('twoExtraLarge')};
      }
    }
  }
`;
