const reissueUrl = process.env.AUTH_REISSUE_URL;

const fetchReIssue = () =>
  fetch(`${reissueUrl}`, { method: 'POST', credentials: 'include' });

const throwErrorWhenNotOk = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

const fetchAuthenticated = (ajaxCall) =>
  ajaxCall().then((response) => {
    if (response.status === 401) {
      return fetchReIssue().then(throwErrorWhenNotOk).then(ajaxCall);
    }

    return response;
  });

export default fetchAuthenticated;
