import AdvertorialAdProvider from '../provider';
import { createNewGuid, generateRandom, getViewId } from '../guidGenerator';
import * as adSpotUtil from './adSpotUtil';
import { createContainer } from '../utils/domUtil';
import {
  convertKeyToLowerCase,
  omitObjectKey,
  uniqueConcat,
} from '../utils/convertObject';
import { convertArrayToString, getInitialSize } from '../utils/transformer';

const defaultOptions = { config: {}, params: {}, sasOverrides: {} };

function AdvertorialAdSpot(
  options = defaultOptions,
  createGuid = createNewGuid,
  getViewIdFn = getViewId,
  generateRandomFn = generateRandom,
) {
  this.guid = createGuid();
  this.viewId = getViewIdFn();
  this.random = generateRandomFn();

  this.config = options.config || {};
  this.params = options.params || {};
  this.cacheKey = options.cacheKey;
  this.sasOverrides = options.sasOverrides || {};
  this.appName = options.appName;
  this.isPrebid = this.config.isPrebid ?? false;
  this.allowSandboxTopNavByUserActivation =
    this.config.allowSandboxTopNavByUserActivation ?? false;

  if (this.config.shouldRenderAd == undefined) {
    this.config.shouldRenderAd = true;
  }

  if (this.config['auto-hide'] !== undefined) {
    if (this.config.autoHide === undefined) {
      this.config.autoHide = this.config['auto-hide'];
    }
    console.warn(
      'Advertorial',
      "options.config['auto-hide'] is deprecated, use options.config.autoHide instead.",
    );
  }

  if (!this.config.type) {
    this.config.type = 'native';
  } else if (this.config.type !== 'native' && this.config.type !== 'lazyjs') {
    console.warn('Advertorial', `${this.config.type} is not supported!`);
  }

  if (options.container) {
    this.element = createContainer(options.config.classes);
    options.container.appendChild(this.element);
  }

  if (this.config.sz) {
    this.config.sz = convertArrayToString(this.config.sz);
  }
  this.loadCompleteCallback = options.loadCompleteCallback;
  this.resizeCompleteCallback = options.resizeCompleteCallback;
  this.nativeRenderCallback = options.nativeRenderCallback;
  this.leadClickCallback = options.leadClickCallback;
  this.nativeBeforeRenderCallback = options.nativeBeforeRenderCallback;
  this.nativeTimeout = options.nativeTimeout;
  this.logError = options.logError;

  if (this.config.site) {
    this.config.abbreviatedSite = this.config.site.split('.')[0];
  }

  if (this.element && this.element.className) {
    this.unhide();
  }
  this.convertParamsToLowerCase();
  this.previousWindowWidth = document.documentElement.clientWidth;
  this.adHidden = false;
}

AdvertorialAdSpot.prototype.convertParamsToLowerCase =
  function convertParamsToLowerCase() {
    this.params = convertKeyToLowerCase(this.params);
  };

AdvertorialAdSpot.prototype.unhide = function unhide() {
  this.element.className = this.element.className.replace(/ rui-ad-hidden/, '');
};

AdvertorialAdSpot.prototype.hideIfRequired = function hideIfRequired() {
  if (this.config.autoHide && this.emptyAd) {
    this.element.className = `${this.element.className} rui-ad-hidden`;
    this.adHidden = true;
  }
};

AdvertorialAdSpot.prototype.updateEmptyAdFlag = function updateEmptyAdFlag() {
  this.emptyAd = adSpotUtil.checkIfEmptyAd(this.nativeType, this.nativeContent);
};

AdvertorialAdSpot.prototype.getLink = function getLink(bidResult) {
  return AdvertorialAdProvider.getCurrent().generateAdLink(this, bidResult);
};

AdvertorialAdSpot.prototype.getUniqueParams = function getUniqueParams() {
  return { pos: this.params.pos };
};

AdvertorialAdSpot.prototype.getPageParams = function getPageParams() {
  return omitObjectKey(this.params, 'pos');
};

AdvertorialAdSpot.prototype.isAdLoaded = function isAdLoaded() {
  return !(this.emptyAd || this.adHidden);
};

AdvertorialAdSpot.prototype.isMultiSize = (sizes) => sizes.indexOf(',') > -1;

AdvertorialAdSpot.prototype.getInitialSize = (availableSizes) =>
  getInitialSize(availableSizes);

AdvertorialAdSpot.prototype.getAvailableSizes = function getAvailableSizes() {
  const sizes = this.config.sz.split(',');
  const renderSizes = this.config.renderSize
    ? this.config.renderSize.split(',')
    : [];

  const availableSizes = uniqueConcat(sizes, renderSizes);

  return availableSizes;
};

AdvertorialAdSpot.prototype.reset = function reset() {
  this.unhide();
  this.element.innerHTML = '';
};

AdvertorialAdSpot.prototype.cleanup = function cleanup() {
  this.updateEmptyAdFlag();
  this.hideIfRequired();
};

AdvertorialAdSpot.prototype.insertAdvertisementHeading =
  function showHeadingIfRequired() {
    if (this.config.showHeading) {
      adSpotUtil.appendHeading(this.element, this.config.headingAlignment);
    }
  };

AdvertorialAdSpot.prototype.isStripAd = function isStripAd() {
  return /^strip\d+$/.test(this.params.pos);
};

export default AdvertorialAdSpot;
