import styled from "styled-components";
import { spacingHelper } from "@rea-group/construct-kit-core";
import { CloseButtonBase } from "../CloseButtonBase";
import DialogBase from "../DialogBase";

const DIALOG_WIDTH = "750px";
export const Dialog = styled(DialogBase)`
  padding: ${spacingHelper("4rem medium")};
  & > *:not(:last-child) {
    margin-bottom: ${spacingHelper("large")};
  }
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    padding: ${spacingHelper("extraLarge")};
    width: ${DIALOG_WIDTH};
    height: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: ${spacingHelper("medium")};
`;

export const CloseButton = styled(CloseButtonBase)`
  right: ${spacingHelper("small")};
  top: ${spacingHelper("small")};
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    right: ${spacingHelper("large")};
    top: ${spacingHelper("large")};
  }
`;
