import type { Data, MexaResponse, Variables } from "../types";
import type { DocumentNode } from "graphql";
import { reissueToken } from "@realestate.com.au/user";
import { generateError } from "./generateError";
const URL = "https://mexa.realestate.com.au/graphql";

async function fetchWithReissue(
  url: string,
  request: RequestInit
): Promise<Response> {
  try {
    const response = await fetch(url, request);

    if (response.status === 401) {
      await reissueToken();
      return await fetch(URL, request);
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      return await Promise.reject(generateError([error]));
    }
    return await Promise.reject(new Error(JSON.stringify(error)));
  }
}
async function eventService<T extends keyof Data>(
  mutationType: T,
  documentQuery: DocumentNode,
  variables: Variables[T]
): Promise<Data[T]> {
  const query = documentQuery.loc?.source.body;
  const body = JSON.stringify({
    query,
    variables,
  });
  const request: RequestInit = {
    body,
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  };
  const response = await fetchWithReissue(URL, request);

  const { data, errors }: MexaResponse = await response.json();

  if (response.ok) {
    const plannerData = data && data[mutationType];
    if (plannerData) return plannerData;
    const message = `Event-service error: missing data in response. Expected data from MEXA to include ${mutationType}.`;
    return Promise.reject(generateError([{ message }]));
  } else {
    return Promise.reject(generateError(errors));
  }
}
export { eventService };
