import { Modal } from '@construct-kit/core';
import React, { useState } from 'react';
import Ad from '../Ad';
import { AdaptedLeadAdData, LeadAdData } from '../types';
import LeadAdEnquiryDialog from './components/LeadAdEnquiryDialog';
import SuccessDialog from './components/SuccessDialog';
import { adaptLeadAdDataResponse } from './helper/adaptLeadAdResponse';
import { attemptTrack, getTrackerUrlByName } from './helper/track';
import {
  ABTestProps,
  AdWithFormProps,
  LeadAdModalState,
  TrackFunction,
} from './types';

const AdWithForm = ({
  appElementId,
  logError,
  clientStorage,
  abTest,
  trackExperiment,
  isNMALToggleOn,
  ...adProps
}: AdWithFormProps): JSX.Element => {
  const [modalState, setModalState] = useState<LeadAdModalState>('hide-modal');
  const [adWithFormData, setAdWithFormData] = useState<
    AdaptedLeadAdData | undefined
  >();

  const leadClickCallback = (leadAdData: LeadAdData): void => {
    setAdWithFormData(adaptLeadAdDataResponse(leadAdData));
    setModalState('show-enquiry-modal');
    attemptTrack(logError, leadAdData.actionurl, 'GET');

    adProps.leadClickCallback?.(leadAdData);
  };

  const renderLeadAdContent = (
    adWithFormData: AdaptedLeadAdData,
    abTest: React.ComponentType<ABTestProps>,
    trackExperiment: TrackFunction,
  ): JSX.Element | null => {
    const submitFormTracker =
      adWithFormData.trackers &&
      getTrackerUrlByName(adWithFormData.trackers, 'submitform');
    switch (modalState) {
      case 'show-thank-you-modal':
        return (
          <SuccessDialog
            leadcompleteurl={adWithFormData.leadcompleteurl}
            logError={logError}
            projectTitle={adWithFormData.projecttitle}
            handleClose={() => setModalState('hide-modal')}
            submitFormTrackerUrls={submitFormTracker?.beaconurls}
          />
        );
      case 'show-enquiry-modal':
        return (
          <LeadAdEnquiryDialog
            isNMALToggleOn={isNMALToggleOn}
            projectId={adWithFormData.projectid}
            agencyId={adWithFormData.agencyid}
            creativeId={adWithFormData.creativeid}
            extensionCampaignId={adWithFormData.extensioncampaignid}
            projectTitle={adWithFormData.projecttitle}
            highlightText={adWithFormData.admessagetext}
            paragraphDescription={adWithFormData.addescription}
            enquirySubmissionUrl={adWithFormData.enquirysubmissionurl}
            lmsCampaignId={adWithFormData.lmscampaignid}
            brandLogoUrl={adWithFormData.brandlogourl}
            heroImageUrl={adWithFormData.heroimageurl}
            onClose={() => setModalState('hide-modal')}
            onEnquirySuccess={() => {
              setModalState('show-thank-you-modal');
            }}
            logError={logError}
            prefillStorage={clientStorage}
            impressionurl={adWithFormData.impressionurl}
            trackers={adWithFormData.trackers}
            phoneRequired={adWithFormData.phonerequired}
            runExperiment={adWithFormData.runexperiment}
            abTest={abTest}
            trackExperiment={trackExperiment}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Ad
        {...adProps}
        leadClickCallback={leadClickCallback}
        logError={logError}
      />
      {adWithFormData && (
        <Modal
          opened={modalState !== 'hide-modal'}
          onRequestClose={() => setModalState('hide-modal')}
          getAppElement={() => appElementId}
        >
          {renderLeadAdContent(adWithFormData, abTest, trackExperiment)}
        </Modal>
      )}
    </>
  );
};

export default AdWithForm;
