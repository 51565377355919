import { createContainer } from '../utils/domUtil';

function getHeadingTextClassName(alignment) {
  const textAlignment = alignment || 'left';
  return `rui-ad-heading rui-ad-heading-${textAlignment}`;
}

function createHeading(alignment) {
  const heading = createContainer(getHeadingTextClassName(alignment));
  heading.innerText = 'ADVERTISEMENT';
  return heading;
}

function createLayoutContainer(element, heading) {
  const layoutContainer = createContainer('rui-ad-layout-wrapper');
  layoutContainer.appendChild(heading);
  layoutContainer.appendChild(element);
  return layoutContainer;
}

const isEmptyImage = (elementContents) =>
  elementContents !== undefined &&
  elementContents.match(/^.*$/m)[0].includes('FCID=-4');

const checkIfEmptyAd = (nativeType, nativeContent) => {
  switch (nativeType) {
    case 'json':
      return !nativeContent || Object.keys(nativeContent).length === 0;
    case 'html':
      return isEmptyImage(nativeContent);
    default:
      return true;
  }
};

const appendHeading = (element, headingAlignment) => {
  const adContainer = element.parentNode;

  if (adContainer == undefined) {
    return;
  }

  const heading = createHeading(headingAlignment);
  adContainer.removeChild(element);
  const layoutContainer = createLayoutContainer(element, heading);
  adContainer.appendChild(layoutContainer);
};

export { checkIfEmptyAd, appendHeading };
