import { Dialog, H2, P } from '@construct-kit/core';
import { useEffect } from 'react';
import { LogError } from '../../types';
import { attemptTrack, fireThirdPartyTracking } from '../helper/track';
import { SuccessDialogStack, SuccessIcon } from './styles/SuccessDialog.style';

type Props = {
  handleClose: () => void;
  projectTitle: string;
  leadcompleteurl: string;
  logError: LogError;
  submitFormTrackerUrls: string[] | undefined;
};

const SuccessDialog = ({
  handleClose,
  projectTitle,
  leadcompleteurl,
  logError,
  submitFormTrackerUrls,
}: Props): JSX.Element => {
  useEffect(() => {
    attemptTrack(logError, leadcompleteurl, 'GET');
    submitFormTrackerUrls && fireThirdPartyTracking(submitFormTrackerUrls);
  }, [leadcompleteurl, submitFormTrackerUrls, logError]);

  return (
    <Dialog onClose={handleClose} noMargins={true} maxWidth="45rem">
      <SuccessDialogStack>
        <SuccessIcon />
        <H2>Thank you!</H2>
        <P>{`Your enquiry has been successfully sent to ${projectTitle}.`}</P>
      </SuccessDialogStack>
    </Dialog>
  );
};

export default SuccessDialog;
