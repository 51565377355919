import { Modal } from "@rea-group/construct-kit-core";
import React, { useState, useEffect } from "react";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  modalMountID: string;
};
const ResponsiveModal = ({
  children,
  isOpen,
  onClose,
  modalMountID,
}: Props): React.ReactElement => {
  const fullScreenStyle = {
    style: { content: { height: "100%", width: "100%" } },
  };

  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const isCurrentScreenLarge = window.innerWidth > 500;
    if (isOpen && isCurrentScreenLarge !== isLargeScreen) {
      setIsLargeScreen(isCurrentScreenLarge);
    }
  }, [isOpen, isLargeScreen]);

  return (
    <Modal
      reactModalProps={isLargeScreen ? undefined : fullScreenStyle}
      getAppElement={() => modalMountID}
      onRequestClose={(e) => {
        e.stopPropagation();
        onClose();
      }}
      opened={isOpen}
    >
      {children}
    </Modal>
  );
};

export default ResponsiveModal;
