let throttledFunction;

const isElementInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  if (rect.bottom <= 0 || rect.top > windowHeight) {
    return false;
  }
  return true;
};

const throttle = (callback, limit) => {
  let wait = false;
  return () => {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

const checkIsAdContainerInViewport = (element) =>
  new Promise((resolve) => {
    // When user navigates on the clint-side to a new page remove old listener before adding a new one
    if (throttledFunction) {
      window.removeEventListener('scroll', throttledFunction, false);
    }

    // Check once on initialisation - it could be in the viewport already
    if (isElementInViewport(element)) {
      resolve();
      // If it is not in the viewport add scroll event listener
    } else {
      const checkIsElementInViewport = () => {
        if (isElementInViewport(element)) {
          window.removeEventListener('scroll', throttledFunction, false);

          resolve();
        }
      };

      throttledFunction = throttle(checkIsElementInViewport, 250);

      window.addEventListener('scroll', throttledFunction, false);
    }
  });

export default checkIsAdContainerInViewport;
