import React, { FC } from "react";
import RegistrationDialog from "../shared/registration-dialog";
import { RegistrationModalPropsWithoutConfirmation } from "../../types";

export const DialogBoxWithoutConfirmation: FC<
  RegistrationModalPropsWithoutConfirmation
> = ({ address, event, onClose, templatedUrl, onRegistrationSuccess }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <RegistrationDialog
      data-testid="register-without-confirmation"
      eventOrEvents={event}
      address={address}
      onClose={handleClose}
      templatedUrl={templatedUrl}
      onRegistrationSuccess={({
        eventId,
        eventType,
        listingId,
        startTime,
        endTime,
      }) => {
        onRegistrationSuccess({
          eventId,
          eventType,
          listingId,
          startTime,
          endTime,
        });
      }}
    />
  );
};

export default DialogBoxWithoutConfirmation;
