import {
  AdServerResponse,
  HtmlAdParams,
  PinAdsConfig,
} from '../ad-components/types';

const isDebug =
  typeof window !== 'undefined' &&
  typeof URLSearchParams !== 'undefined' &&
  new URLSearchParams(window.location.search).get('rui_debug') === 'true';

const debugLog = (...args: unknown[]): void => {
  isDebug && console.log(...args);
};

const pinnedAdSizes: {
  [key: string]: string;
} = {};

export const cachedAdSizes: {
  [position: string]: { w: number; h: number } | 'native';
} = {};

const isNativeAdvert = (adJson: AdServerResponse): boolean =>
  'adtype' in adJson;

export const saveSize = (
  adJson: AdServerResponse,
  adKey: string,
  position: string,
): void => {
  debugLog('saveSize', { position, adJson, adKey });

  if (isNativeAdvert(adJson)) {
    pinnedAdSizes[adKey] = 'native';
    cachedAdSizes[position] = 'native';
    debugLog(
      { position },
      'isNativeAdvert pinnedAdSizes[adKey]=',
      pinnedAdSizes[adKey],
    );
    return;
  }

  if (
    typeof adJson.width != 'undefined' &&
    typeof adJson.height != 'undefined'
  ) {
    const { width, height } = adJson as HtmlAdParams;
    pinnedAdSizes[adKey] = `${width}x${height}`;
    cachedAdSizes[position] = { w: width, h: height };

    debugLog(
      { position },
      'saveSize pinnedAdSizes[adKey]=',
      pinnedAdSizes[adKey],
    );
  }
};

const getSizeHeight = (size: string): number => parseInt(size.split('x')[1]);

export const getSizesToRequest = (
  sizes: string[],
  adKey: string,
  position: string,
): string[] => {
  const savedAdSize = pinnedAdSizes[adKey];
  debugLog('getSizesToRequest', { position, savedAdSize, sizes, adKey });
  return savedAdSize != undefined ? [savedAdSize] : sizes;
};

const getAdSizeHeight = (sizes: string[], isMobileScreen: boolean): number => {
  const nativeAdHeightOnDesktop = 509;
  const nativeAdHeightOnMobile = 333;
  return sizes
    .map((size) => {
      if (size.indexOf('x') !== -1) {
        return getSizeHeight(size);
      }
      return isMobileScreen ? nativeAdHeightOnMobile : nativeAdHeightOnDesktop;
    })
    .reduce((a, b) => Math.min(a, b));
};

export const getStyle = (
  sizes: string[],
  pinAdsConfig: PinAdsConfig,
): { minHeight: number } => {
  const adSectionTitleHeight = 24;
  const isMobileScreen = pinAdsConfig.isMobileScreen;

  return {
    minHeight: adSectionTitleHeight + getAdSizeHeight(sizes, isMobileScreen),
  };
};
