import type { DeleteEventFromPlannerInput, EventData } from "../types";
import { eventService } from "../service";
import DeleteEventFromPlannerQuery from "./DeleteEventFromPlanner.graphql";

async function deleteEventFromPlanner(
  input: DeleteEventFromPlannerInput
): Promise<EventData> {
  return await eventService(
    "deleteEventFromPlanner",
    DeleteEventFromPlannerQuery,
    { input }
  );
}
export { deleteEventFromPlanner };
