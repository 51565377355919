import { getLocalStorageItem } from './utils/localStorageUtil';
import fetchAuthenticated from './fetchAuthenticated';
import { isReaUserLoggedIn, getReaUserId } from './utils/auth';
import {
  convertResponseToJson,
  arePersonalisedAdsAllowed,
  cacheExistsAndNotExpired,
  allowPersonalisedAdsCache,
  outputErrorToConsole,
  PERSONALISED_ADS_ANONYMOUS_CACHE_KEY,
  PERSONALISED_ADS_PREF_ID,
  setAllowPersonalisedAdsCache,
  removeTokenIfInvalid,
} from './allowPersonalisedAdsUtil';

const allowPersonalisedAdsForAnonymousUser = () =>
  getLocalStorageItem(PERSONALISED_ADS_ANONYMOUS_CACHE_KEY) !== 'false';

const communicationsApiUrl = process.env.COMMUNICATIONS_API_URL;

const fetchCommunicationPreferences = () =>
  fetch(
    `${communicationsApiUrl}/resi_user/preferences/privacy?include_extra[]=${PERSONALISED_ADS_PREF_ID}`,
    { credentials: 'include' },
  );

const fetchMyAccountAllowPersonalisedAds = () =>
  fetchAuthenticated(fetchCommunicationPreferences)
    .then(convertResponseToJson)
    .then(arePersonalisedAdsAllowed);

let isfetching = false;

const allowPersonalisedAdsForLoggedInUser = () => {
  if (!isReaUserLoggedIn()) {
    return true;
  }

  const cache = allowPersonalisedAdsCache();

  if (cacheExistsAndNotExpired(cache)) {
    return cache.value;
  }

  if (!isfetching) {
    isfetching = true;
    fetchMyAccountAllowPersonalisedAds()
      .then((myAccountAllowPersonalisedAds) =>
        setAllowPersonalisedAdsCache(
          myAccountAllowPersonalisedAds,
          getReaUserId(),
        ),
      )
      .catch(outputErrorToConsole)
      .finally(() => {
        isfetching = false;
      });
  }

  return false;
};

const allowPersonalisedAds = () => {
  removeTokenIfInvalid(isReaUserLoggedIn(), getReaUserId());

  const allowAnonymous = allowPersonalisedAdsForAnonymousUser();

  if (!allowAnonymous) {
    return false;
  }

  return isReaUserLoggedIn()
    ? allowPersonalisedAdsForLoggedInUser()
    : allowAnonymous;
};

export default allowPersonalisedAds;
