const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // suppress error
  }
};

const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

const removeLocalStorageItem = (key) => {
  try {
    return localStorage.removeItem(key);
  } catch {
    return null;
  }
};

export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem };
