const carouselTemplate = `
<section class="native-carousel">
  <div class="native-carousel__sponsored-label">
    {{#adsponsoredtext}} {{adsponsoredtext}} {{/adsponsoredtext}} {{^adsponsoredtext}} ADVERTISEMENT {{/adsponsoredtext}}
  </div>
  <div class="native-carousel__card">
    {{#termstitle}}
    {{#termstext}}
      <div class="native-carousel__card-overlay">
        <h2>Conditions</h2>
        <div class="native-carousel__terms">{{termstext}}</div>
        <button class="native-carousel__terms-close-button" type="button"><a class="native-carousel__terms-close-icon"></a>Close</button>
      </div>
    {{/termstext}}
    {{/termstitle}}
    {{#isLeadAd}}
      <div class="native-carousel__brand"><img src="{{brandlogourl}}" alt="logo"></div>
    {{/isLeadAd}}
    {{^isLeadAd}}
      {{#brandwebsiteurl}}
        <a class="native-carousel__brand" href="{{brandwebsiteurl}}" target="_blank" rel="noopener noreferrer"><img src="{{brandlogourl}}" alt="logo"></a>
      {{/brandwebsiteurl}}
      {{^brandwebsiteurl}}
        <div class="native-carousel__brand"><img src="{{brandlogourl}}" alt="logo"></div>
      {{/brandwebsiteurl}}
    {{/isLeadAd}}
    <div class="native-carousel__slide-container">
      <div class="native-carousel__slide-wrapper">
        {{#pages}}
          {{#heroimageurl}}
            <div class="native-carousel__slide">
              <div class="native-carousel__image" style="background-image: url('{{heroimageurl}}');">
                {{^isLeadAd}}
                  <a href="{{clickurl}}" target="_blank" onclick="fetch('{{clickurlbeacon}}')"></a>
                {{/isLeadAd}}
              </div>
            </div>
          {{/heroimageurl}}
        {{/pages}}
      </div>
      <div class="native-carousel__arrow native-carousel__arrow-prev" style="display: none;">
        <svg width="45" height="55" viewBox="0 0 45 55">
          <path d="M30.6,12.5L30.6,12.5c0.4-0.6,0.4-1.6-0.1-2c-0.6-0.6-1.6-0.6-2.2,0l-15.8,16c-0.6,0.6-0.6,1.6,0,2.1l15.9,16c0.6,0.6,1.6,0.6,2.2,0s0.6-1.6,0-2.1l-16-16.1v2.1L30.6,12.5L30.6,12.5z" />
        </svg>
      </div>
      <div class="native-carousel__arrow native-carousel__arrow-next">
        <svg width="45" height="55" viewBox="0 0 45 55">
          <path d="M14.5,12.5l16,16v-2.1l-16,16.1c-0.6,0.6-0.6,1.6,0,2.1c0.6,0.6,1.6,0.6,2.2,0l15.9-16c0.6-0.6,0.6-1.6,0-2.1l-15.8-16c-0.6-0.6-1.6-0.6-2.2,0C14.1,10.9,14.1,11.9,14.5,12.5L14.5,12.5L14.5,12.5z" />
        </svg>
      </div>
      <div class="native-carousel__arrow-prev-placeholder"></div>
      <div class="native-carousel__arrow-next-placeholder"></div>
    </div>
    <div class="native-carousel__card_cta">
      {{#pages}}
        <div class="native-carousel__card-content">
          <div class="native-carousel__text-container {{#termstitle}}{{#termstext}}native-carousel__card-content-with-terms{{/termstext}}{{/termstitle}} {{^admessagetext2}}native-carousel__card-content-no-description{{/admessagetext2}}">
            {{#isLeadAd}}
              <div class="native-carousel__text native-carousel__text-title">
                {{admessagetext}}
              </div>
              {{#admessagetext2}}
                <div class="native-carousel__text native-carousel__text-description">
                  {{admessagetext2}}
                </div>
              {{/admessagetext2}}
            {{/isLeadAd}}
            {{^isLeadAd}}
              <a class="native-carousel__text native-carousel__text-title" href="{{clickurl}}" target="_blank" onclick="fetch('{{clickurlbeacon}}')">
                {{admessagetext}}
              </a>
              {{#admessagetext2}}
                <a class="native-carousel__text native-carousel__text-description" href="{{clickurl}}" target="_blank" onclick="fetch('{{clickurlbeacon}}')">
                  {{admessagetext2}}
                </a>
              {{/admessagetext2}}
            {{/isLeadAd}}
          </div>
          <div class="native-carousel__cta">
            {{#isLeadAd}}
              <button class="native-carousel__action-button">
                {{#actionmessagetext}}
                  {{actionmessagetext}}
                {{/actionmessagetext}}
                {{^actionmessagetext}}
                  Tap for more
                {{/actionmessagetext}}
              </button>
            {{/isLeadAd}}
            {{^isLeadAd}}
              <a class="native-carousel__action-link" href="{{clickurl}}" target="_blank" onclick="fetch('{{buttontapbeacon}}')">
                {{#actionmessagetext}}
                  {{actionmessagetext}}
                {{/actionmessagetext}}
                {{^actionmessagetext}}
                  Tap for more
                {{/actionmessagetext}}
              </a>
            {{/isLeadAd}}
          </div>
        </div>
      {{/pages}}
      {{#termstitle}}
      {{#termstext}}
        <button class="native-carousel__terms-open-button native-carousel__terms-up-arrow">{{termstitle}}</button>
     {{/termstext}}
    {{/termstitle}}
    </div>
  </div>
</section>
{{#beaconurl}}<img src="{{beaconurl}}" width=1 height=1 border=0 />{{/beaconurl}}
`;

export default carouselTemplate;
