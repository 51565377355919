import { AdaptedEventType, EventType } from "../types";

const eventTypeMap: { [key in EventType]: AdaptedEventType } = {
  Inspection: {
    displayString: "Inspection",
    fileName: "Inspection",
  },
  Auction: {
    displayString: "Auction",
    fileName: "Auction",
  },
  Display_Suite: {
    displayString: "Display suite",
    fileName: "Display_Suite",
  },
};

export default eventTypeMap;
