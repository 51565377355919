import React, { FC, SyntheticEvent, useRef, useState } from "react";
import {
  Button,
  TextInput,
  typographyHelper,
  paletteHelper,
  spacingHelper,
  useToast,
  Stack,
  Text,
} from "@rea-group/construct-kit-core";
import styled from "styled-components";
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
} from "./validations";
import useStateWithCallback from "../../useStateWithCallback";
import usePrefillData from "./usePrefillData";
import { registerForEvent } from "@realestate.com.au/planner-event-service";
import {
  MultipleEvents,
  OnRegistrationSuccessProps,
  SingleEvent,
  isMultipleEvents,
} from "../../../../types";
import { InspectionDropdown } from "./InspectionDropdown";

const RequiredLabel = styled.span`
  ${typographyHelper("body02")}
  color: ${paletteHelper("textSecondary")};
  margin-left: ${spacingHelper("twoExtraSmall")};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  gap: ${spacingHelper("medium")};
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  margin-bottom: ${spacingHelper("large")};
`;

const StyledTextTime = styled(Text).attrs({
  variant: "title02",
})`
  margin-bottom: ${spacingHelper("extraSmall")};
`;

const StyledTextDetails = styled(Text).attrs({
  variant: "title02",
})`
  margin: ${spacingHelper("extraLarge 0 medium")};
`;

type ValidationErrorMessages = {
  nameError: string;
  emailError: string;
  phoneError: string;
};

const defaultValidationErrorMessages = {
  nameError: "",
  emailError: "",
  phoneError: "",
};

const getValueOrEmptyString = (ref: {
  current: null | HTMLInputElement;
}): string => ref.current?.value.trim() ?? "";

type Props = {
  eventOrEvents: SingleEvent | MultipleEvents;
  onRegistrationSuccess: (props: OnRegistrationSuccessProps) => void;
};

const RegistrationForm: FC<Props> = ({
  onRegistrationSuccess,
  eventOrEvents,
}) => {
  const [validationErrorMessages, setValidationErrorMessages] =
    useStateWithCallback(defaultValidationErrorMessages);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const addToast = useToast();
  const prefillData = usePrefillData();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  const handleNameBlur = () => {
    setValidationErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      nameError: validateName(getValueOrEmptyString(nameRef)),
    }));
  };
  const handleEmailBlur = () => {
    setValidationErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      emailError: validateEmail(getValueOrEmptyString(emailRef)),
    }));
  };
  const handlePhoneBlur = () => {
    setValidationErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      phoneError: validatePhoneNumber(getValueOrEmptyString(phoneRef)),
    }));
  };
  const hasError = (errorMessages: ValidationErrorMessages) =>
    Object.values(errorMessages).some((error) => error !== "");

  const toastError = (error: unknown) => {
    return (error as Error).message === "Failed to fetch"
      ? "No internet connection, please reconnect and try again."
      : "Oops! Something’s gone wrong. Refresh and try again.";
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const name = getValueOrEmptyString(nameRef);
    const email = getValueOrEmptyString(emailRef);
    const phone = getValueOrEmptyString(phoneRef);

    const eventInput = isMultipleEvents(eventOrEvents)
      ? eventOrEvents.selectedEvent?.input
      : eventOrEvents.input;

    if (!eventInput) {
      return;
    }

    setValidationErrorMessages(
      {
        nameError: validateName(name),
        emailError: validateEmail(email),
        phoneError: validatePhoneNumber(phone),
      },
      async (updatedErrorMessages) => {
        if (hasError(updatedErrorMessages)) {
          setFormSubmitting(false);
        } else {
          setFormSubmitting(true);
          try {
            const eventData = await registerForEvent({
              ...eventInput,
              name,
              email,
              ...(phone && { phone }),
            });
            onRegistrationSuccess({
              eventId: eventData.eventId,
              eventType: eventInput.eventType,
              startTime: eventInput.startTime,
              endTime: eventInput.endTime,
              listingId: eventInput.listingId,
            });
          } catch (error) {
            setFormSubmitting(false);
            addToast({
              message: toastError(error),
              variant: "warning",
            });
          }
        }
      }
    );
  };

  const shouldDisableRegisterButton = () => {
    if (formSubmitting) {
      return true;
    }
    if (isMultipleEvents(eventOrEvents) && !eventOrEvents.selectedEvent) {
      return true;
    }
    return false;
  };

  return (
    <Stack>
      <form onSubmit={handleSubmit} role="form">
        {isMultipleEvents(eventOrEvents) && (
          <>
            <StyledTextTime>Time</StyledTextTime>
            <InspectionDropdown
              events={eventOrEvents.eventItems}
              onEventSelect={eventOrEvents.onEventSelect}
            />
            <StyledTextDetails>Your details</StyledTextDetails>
          </>
        )}
        <Grid>
          <TextInput
            name="name"
            label={
              <>
                Name
                <RequiredLabel>(required)</RequiredLabel>
              </>
            }
            autoComplete="name"
            ref={nameRef}
            onBlur={handleNameBlur}
            errorMessage={validationErrorMessages.nameError}
            defaultValue={prefillData.name}
          />
          <TextInput
            name="email"
            label={
              <>
                Email address
                <RequiredLabel>(required)</RequiredLabel>
              </>
            }
            autoComplete="email"
            autoValidate={false}
            type="email"
            iconLeft={false}
            ref={emailRef}
            onBlur={handleEmailBlur}
            errorMessage={validationErrorMessages.emailError}
            defaultValue={prefillData.email}
          />
          <TextInput
            name="phone"
            label="Phone number"
            autoComplete="tel"
            type="tel"
            iconLeft={false}
            ref={phoneRef}
            onBlur={handlePhoneBlur}
            errorMessage={validationErrorMessages.phoneError}
            defaultValue={prefillData.phoneNumber}
          />
        </Grid>
        <Button
          fullWidth
          type="submit"
          disabled={shouldDisableRegisterButton()}
        >
          Register
        </Button>
      </form>
    </Stack>
  );
};

export default RegistrationForm;
