import { log } from '../utils/loggingUtil';
import { PREBID_IFRAME_ORIGIN } from '../rui-env-variables';
import { getKruxSegments } from '../krux';
import getTealiumUtag, {
  getTealiumRegion,
  getTealiumSegments,
  getTealiumUid2id,
} from './getTealiumUtag';
import withAdvertTargeting from '../targeting';
import { getPrebidIframeUrlOnClient } from './getPrebidIframeUrl';
import { getCookie } from '../utils/cookiesUtils';

// For testing purposes, to clear loadPrebidIframePromise state
export const resetPrebidIframe = () => {
  window.loadPrebidIframePromise = undefined;
};

const addIframe = (src) =>
  new Promise((resolve) => {
    const iframeEl = document.createElement('iframe');
    iframeEl.src = src;
    iframeEl.id = 'prebid';
    iframeEl.name = 'prebid';
    iframeEl.width = '0';
    iframeEl.height = '0';
    iframeEl.style.display = 'none';
    iframeEl.sandbox =
      'allow-scripts allow-popups allow-popups-to-escape-sandbox allow-forms allow-same-origin';
    iframeEl.addEventListener('load', () => resolve(iframeEl));
    document.getElementsByTagName('body')[0].appendChild(iframeEl);
  });

const loadPrebidIframe = (src) => {
  log('Prebid -', 'Loading Prebid iframe');

  if (window.loadPrebidIframePromise) {
    return window.loadPrebidIframePromise;
  }

  window.loadPrebidIframePromise = addIframe(src);
  return window.loadPrebidIframePromise;
};

const getFirstPartyData = (options, page, referrer, utag) => {
  const {
    config: { site },
    params,
  } = options.dataLayer
    ? withAdvertTargeting(options)
    : options.targetingConfig;
  const { channel, sect } = params;

  return {
    site: {
      page: page,
      ref: referrer,
      ext: {
        data: {
          site,
          ad_area: `${channel}.${sect}`,
          ...params,
          referrer: page,
        },
      },
    },
    user: {
      ext: {
        data: {
          segment: getKruxSegments(),
          tseg: getTealiumSegments(utag),
          tregion: getTealiumRegion(utag),
        },
      },
    },
  };
};

const getLiveRampEnvelope = () => {
  const liveRampEnvelope = localStorage.getItem('_lr_env');
  return liveRampEnvelope ?? undefined;
};

const getPairIdEnvelope = () => {
  const pairIdEnvelope = localStorage.getItem('_lr_pairId');
  return pairIdEnvelope ?? undefined;
};

const getId5id = () => {
  const id5id = localStorage.getItem('id5id');
  return id5id ?? undefined;
};

const getReauid = () => {
  const reauid = getCookie('reauid');
  return reauid ?? undefined;
};

const startPrebidBidding = (adUnits, options = {}) => {
  if (adUnits.length === 0) {
    log('Prebid -', 'No ad units given, not doing bidding');
    return Promise.resolve();
  }

  const {
    isDemoApp = false,
    mockBidsResponses = {},
    isDemandManagerTest = false,
    page = window.location.href,
    referrer = document.referrer,
    onPrebidIframeLoaded,
  } = options;

  const prebidIframeSrc = getPrebidIframeUrlOnClient(isDemandManagerTest);

  return Promise.all([
    loadPrebidIframe(prebidIframeSrc),
    getTealiumUtag(),
  ]).then(([iframeEl, utag]) => {
    log('Prebid -', 'Prebid iframe loaded');

    onPrebidIframeLoaded?.(iframeEl);

    const message = JSON.stringify({
      message: 'startDemandManagerPrebidBidding',
      prebidConfigs: adUnits,
      firstPartyData: getFirstPartyData(options, page, referrer, utag),
      isDemandManagerTest,
      isDemoApp,
      mockBidsResponses,
      liveRampEnvelope: getLiveRampEnvelope(),
      pairIdEnvelope: getPairIdEnvelope(),
      id5id: getId5id(),
      uid2id: getTealiumUid2id(utag) ?? undefined,
      reauid: getReauid(),
    });

    log('Prebid -', 'Start Prebid bidding', message);

    iframeEl.contentWindow.postMessage(message, PREBID_IFRAME_ORIGIN);
  });
};

export default startPrebidBidding;
