import {
  RegistrationModalPropsWithConfirmation,
  RegistrationModalPropsWithoutConfirmation,
  RegistrationModalType,
} from "./types";

export const isRegistrationWithConfirmation = (
  registrationModalProps:
    | RegistrationModalPropsWithConfirmation
    | RegistrationModalPropsWithoutConfirmation
): registrationModalProps is RegistrationModalPropsWithConfirmation =>
  registrationModalProps.modalType === RegistrationModalType.WithConfirmation;
