import { IS_CLIENT } from '../../../utils/browserUtils';

let onFullscreenChange;

function isEventTargetElementAVideo(ev) {
  return ev.target && ev.target.nodeName.toLowerCase() === 'video';
}

function isVideoAtBeginning(video) {
  return video.currentTime <= 0.1;
}

function doesVideoHaveParentNativeVideoContainer(video) {
  return (
    video.parentElement &&
    (video.parentElement.className === 'native-video__element-container' ||
      video.parentElement.className === 'native-video-element-container')
  );
}

function hideVideoAndShowPoster(videoContainer, posterImage, playButton) {
  posterImage.style.display = 'inline-block';
  playButton.style.visibility = 'visible';
  videoContainer.style.visibility = 'hidden';
}

function isFullscreen(video) {
  return !!(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullscreenElement ||
    document.msFullscreenElement ||
    video.webkitDisplayingFullscreen
  );
}

function fullscreenChange(ev) {
  if (typeof onFullscreenChange === 'function') {
    onFullscreenChange();
  }

  if (
    !isEventTargetElementAVideo(ev) ||
    isFullscreen(ev.target) ||
    !isVideoAtBeginning(ev.target)
  ) {
    return;
  }

  const video = ev.target;

  if (!doesVideoHaveParentNativeVideoContainer(video)) {
    return;
  }

  const videoContainer = video.parentElement;
  const posterImage =
    videoContainer.parentElement.getElementsByClassName(
      'native-video__image',
    )[0] ||
    videoContainer.parentElement.getElementsByClassName('native-image')[0];
  const playButton =
    videoContainer.parentElement.getElementsByClassName(
      'native-video__play',
    )[0] || videoContainer.parentElement.getElementsByClassName('play')[0];
  hideVideoAndShowPoster(videoContainer, posterImage, playButton);
}

function addFullscreenChangeListener() {
  document.addEventListener('fullscreenchange', fullscreenChange, false);
  document.addEventListener('webkitfullscreenchange', fullscreenChange, false);
  document.addEventListener('mozfullscreenchange', fullscreenChange, false);
  document.addEventListener('msfullscreenchange', fullscreenChange, false);
}

IS_CLIENT && addFullscreenChangeListener();

function VideoAdFullscreenManager(
  video,
  videoContainer,
  playButton,
  posterImage,
) {
  let audioState;

  function unmute() {
    audioState = video.muted;
    video.muted = false;
  }

  function resetIfNotInFullscreen() {
    if (!isFullscreen(video)) {
      onFullscreenChange = undefined;
      video.muted = audioState;
    }
  }

  function setOnFullscreenChange() {
    onFullscreenChange = resetIfNotInFullscreen;
  }

  function changeToFullscreenWhenClicked() {
    video.addEventListener('click', () => {
      if (video.controls || isFullscreen(video)) {
        return;
      }

      const requestFullscreen =
        video.requestFullscreen ||
        video.webkitRequestFullscreen ||
        video.mozRequestFullscreen ||
        video.msRequestFullscreen;

      if (typeof requestFullscreen === 'function') {
        requestFullscreen.call(video);
        setOnFullscreenChange();
        unmute();
      }
    });
  }

  function hideVideoAndShowPosterIfNotInFullscreen() {
    if (isFullscreen(video)) {
      return;
    }

    hideVideoAndShowPoster(videoContainer, posterImage, playButton);
  }

  function addEndedEventListener() {
    video.addEventListener('ended', hideVideoAndShowPosterIfNotInFullscreen());
  }

  video.addEventListener('webkitendfullscreen', fullscreenChange, false);
  changeToFullscreenWhenClicked();
  addEndedEventListener();
}

export default VideoAdFullscreenManager;
