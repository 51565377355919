import { MessageData } from '../../LockeSignIn';
import { TERMINATED_TAB } from '../../util/messageConstants';

type Props = {
  callbackOrigin: string;
  windowInjection?: Window;
  handleMessage?: (data: MessageData) => void;
  handleError?: (err: string) => void;
};

export default class LockeWindow {
  props: Props;
  window: Window;
  tabWindow?: Window | null;
  tabListener?: number | NodeJS.Timeout;
  isOpen: boolean;

  constructor(props: Props) {
    this.props = props;
    this.window = props.windowInjection || window;
    this.isOpen = false;
  }

  handleMessage = (event: MessageEvent<MessageData>) => {
    const isFromNewTab: boolean =
      event.origin === this.props.callbackOrigin &&
      typeof event.data === 'object' &&
      event.data.loginSuccess === true;
    if (isFromNewTab && this.props.handleMessage) {
      this.props.handleMessage(event.data);
    }
  };

  openTab(url: string) {
    if (!this.isOpen) {
      this.props.handleMessage &&
        this.window.addEventListener('message', this.handleMessage);
      this.tabWindow = this.window.open(url);
      this.setUpTabListener();
      this.isOpen = true;
    }
    return this;
  }

  closeTab() {
    if (this.isOpen && this.tabWindow && !this.tabWindow.closed) {
      this.tabListener && clearInterval(this.tabListener);
      this.tabWindow.close();
      this.props.handleMessage &&
        this.window.removeEventListener('message', this.handleMessage);
      this.isOpen = false;
    }
    return this;
  }

  setUpTabListener() {
    if (this.props.handleError) {
      this.tabListener = setInterval(() => {
        if (this.tabWindow && this.tabWindow.closed) {
          this.isOpen = false;
          this.tabListener && clearInterval(this.tabListener);
          if (this.props.handleError) {
            this.props.handleError(TERMINATED_TAB);
          }
        }
      }, 100);
    }
  }
}
