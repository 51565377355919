import providerUtil from './providerUtil';

const {
  getClient,
  getServerUrlPart,
  getUniqueParamUrlPart,
  getPageParamUrlPart,
  getViewIdUrlPart,
  getAdType,
  getKruxParamsUrlPart,
} = providerUtil;

const BASE_SAS_URL = 'https://sas.rea.global/';
const PARAMS_BLACKLIST = [
  'sect',
  'tile',
  'type',
  'dcopt',
  'areaprefix',
  'channel',
];
const AdvertorialSasGlobal = {};

AdvertorialSasGlobal.generateAdLink = (adSpot) => {
  const url =
    `${BASE_SAS_URL}` +
    `${getClient()}` +
    `${getServerUrlPart(adSpot.config.type)}` +
    `${getViewIdUrlPart(adSpot.viewId)}` +
    `${getPageParamUrlPart(adSpot, PARAMS_BLACKLIST)}` +
    `${getKruxParamsUrlPart(adSpot.config.abbreviatedSite)}` +
    `${getUniqueParamUrlPart(adSpot, PARAMS_BLACKLIST)}` +
    `${getAdType(adSpot.config.type)}`;
  return url;
};

export default AdvertorialSasGlobal;
