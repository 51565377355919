import { useEffect, useState } from "react";

const PREFILL_DATA_KEY = "enquiry-prefill-data";

type PrefillData = {
  name: string;
  email: string;
  phoneNumber: string;
};

const usePrefillData = () => {
  const [prefillData, setPrefillData] = useState<PrefillData>({
    name: "",
    email: "",
    phoneNumber: "",
  });
  useEffect(() => {
    try {
      const storedData = localStorage.getItem(PREFILL_DATA_KEY);
      if (storedData) {
        const prefillData = JSON.parse(window.atob(storedData));
        setPrefillData({
          name: prefillData.name ?? "",
          email: prefillData.email ?? "",
          phoneNumber: prefillData.phoneNumber ?? "",
        });
      }
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    } catch (error) {
      console.error(error);
    }
  }, []);
  return prefillData;
};

export default usePrefillData;
