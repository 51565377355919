import { mergeOverrideValues, turnParamsIntoUrl } from '../utils/convertObject';
import { getFeatureToggle } from '../utils/featureTogglesUtils';
import allowPersonalisedAds from '../allowPersonalisedAds';
import allowRcaPersonalisedAds from '../allowRcaPersonalisedAds';
import { getKruxSegments, getKruxUID } from '../krux';
import { getCookie } from '../utils/cookiesUtils';

const getClient = (win = window) => {
  if (win.ruiAdClient) {
    return win.ruiAdClient;
  }

  if (getFeatureToggle('sasStaging')) {
    return 'reasfdc';
  }

  return 'rea';
};

const getServerUrlPart = (type) =>
  type === 'lazyjs' ? '/jserver' : '/hserver';

const getUniqueParamUrlPart = (adSpot, blackList) => {
  mergeOverrideValues(adSpot.config, adSpot.sasOverrides);
  const params = mergeOverrideValues(
    adSpot.getUniqueParams(),
    adSpot.sasOverrides,
  );
  const urlParams = turnParamsIntoUrl(params, blackList);
  let url = `${urlParams}/size=${adSpot.config.sz}`;

  if (adSpot.config.version) {
    url += `/version=${adSpot.config.version}`;
  }

  return url;
};

const getPageParamUrlPart = (adSpot, blackList) => {
  const config = mergeOverrideValues(adSpot.config, adSpot.sasOverrides);
  const params = mergeOverrideValues(
    adSpot.getPageParams(),
    adSpot.sasOverrides,
  );
  const siteComponents = config.site.split('.');
  const siteAbbreviation = siteComponents[0];
  const channel = params.areaprefix || params.channel || siteComponents[1];
  const section = params.sect;
  const urlParams = turnParamsIntoUrl(params, blackList);
  const area =
    channel != undefined && section != undefined
      ? `/area=${channel}.${section}`
      : '';

  const url = `/site=${siteAbbreviation}${area}${urlParams}/random=${adSpot.random}`;
  return url;
};

const getViewIdUrlPart = (viewId) => `/viewid=${viewId}`;
const getAdType = (type) => (type === 'native' ? '/adtype=safeframe' : '');

const getKruxParamsUrlPart = (site) => {
  if (site === 'rca' ? !allowRcaPersonalisedAds() : !allowPersonalisedAds()) {
    return '/dnt=1';
  }

  let params = '';
  const kruxUID = getKruxUID();
  const kruxSegments = getKruxSegments();

  if (kruxSegments.length > 0) {
    params += `/segment=${kruxSegments.join(',')}`;
  }
  if (kruxUID.length > 0) {
    params += `/kxkuid=${kruxUID}`;
  }

  return params;
};

const getReauidPart = (site) => {
  if (site === 'rca' ? !allowRcaPersonalisedAds() : !allowPersonalisedAds()) {
    return '';
  }
  const reauid = getCookie('reauid');
  const rcauid = getCookie('rcauid');

  return site === 'rca'
    ? rcauid != null
      ? `/rcauid=${rcauid}`
      : ''
    : reauid != null
      ? `/reauid=${reauid}`
      : '';
};

export default {
  getClient,
  getServerUrlPart,
  getUniqueParamUrlPart,
  getPageParamUrlPart,
  getViewIdUrlPart,
  getAdType,
  getKruxParamsUrlPart,
  getReauidPart,
};
