import React from 'react';
import {
  Modal,
  H3,
  P,
  Button,
  Card,
  CrossLg,
  Dialog,
} from '@construct-kit/core';
import styled from 'styled-components';

import { UserAction } from '../../LockeSignIn';

const InnerContainer = styled(Card)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
`;

const CrossContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  button {
    flex-shrink: 1;
    width: 100%;
  }
`;

type Props = {
  onClose: () => void;
  handleModalButtonsClick: (arg0: UserAction) => void;
  appElement: string;
  heading: string;
  subHeading?: string;
};

const LockeForm = ({
  onClose,
  handleModalButtonsClick,
  appElement,
  heading,
  subHeading,
}: Props) => {
  return (
    <Modal
      opened
      onRequestClose={() => onClose()}
      getAppElement={() => appElement}
    >
      <Dialog noMargins>
        <InnerContainer>
          <CrossContainer>
            <Button
              icon={<CrossLg />}
              variant="naked"
              sizeVariant="medium"
              hideLabel
              compact
              onClick={() => onClose()}
            >
              Close
            </Button>
          </CrossContainer>
          <H3>{heading}</H3>
          {subHeading && <P>{subHeading}</P>}
          <ButtonContainer>
            <Button
              variant="outline"
              onClick={() => handleModalButtonsClick(UserAction.SignUp)}
            >
              Join
            </Button>
            <Button onClick={() => handleModalButtonsClick(UserAction.SignIn)}>
              Sign in
            </Button>
          </ButtonContainer>
        </InnerContainer>
      </Dialog>
    </Modal>
  );
};

export default LockeForm;
