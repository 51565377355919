function VideoAdSeekingManager(video) {
  let supposedCurrentTime = 0;

  video.addEventListener('timeupdate', () => {
    if (!video.seeking) {
      supposedCurrentTime = video.currentTime;
    }
  });

  video.addEventListener('seeking', () => {
    const delta = video.currentTime - supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      video.currentTime = supposedCurrentTime;
    }
  });

  video.addEventListener('ended', () => {
    supposedCurrentTime = 0;
  });
}

export default VideoAdSeekingManager;
