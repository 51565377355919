import { createNewGuid } from '../guidGenerator';

const generateEmptyIframe = () => {
  const uniqId = createNewGuid();
  const adIFrame = document.createElement('iframe');
  adIFrame.frameBorder = 0;
  adIFrame.scrolling = 'no';
  adIFrame.style.border = '0';
  adIFrame.marginWidth = '0';
  adIFrame.marginHeight = '0';
  adIFrame.tabIndex = '-1';
  adIFrame.id = `rui_frame_${uniqId}`;
  return adIFrame;
};

const appendFriendlyIframe = (content, adContainer) => {
  const friendlyIframe = generateEmptyIframe();
  adContainer.appendChild(friendlyIframe);
  const frameContent = `
  <!DOCTYPE html>
  <html>
  <head></head>
    <body>${content}</body>
  </html>`;
  const doc = friendlyIframe.contentWindow.document;
  doc.open();
  doc.write(frameContent);
  doc.close();

  return friendlyIframe;
};

const generateFriendlyIframeAd = (adSpot, content) => {
  const adContainer = adSpot.element;
  const friendlyIframe = appendFriendlyIframe(content, adContainer);

  friendlyIframe.addEventListener('load', () => {
    const iframeWin =
      friendlyIframe.contentWindow ||
      friendlyIframe.contentDocument.parentWindow;
    if (iframeWin.document.documentElement && iframeWin.document.body) {
      friendlyIframe.height = Math.max(
        iframeWin.document.documentElement.scrollHeight,
        iframeWin.document.body.scrollHeight,
      );
      friendlyIframe.width = Math.max(
        iframeWin.document.documentElement.scrollWidth,
        iframeWin.document.body.scrollWidth,
      );
    }
  });

  return Promise.resolve('Friendly IFrame Ad Loaded');
};

export default generateFriendlyIframeAd;
