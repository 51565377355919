import generateSafeframeAdImpl from './safeframe/generateSafeframeAd';

export default (generateSafeframeAd = generateSafeframeAdImpl) =>
  (adSpot, response) => {
    const {
      content: { adId, size, format },
    } = response;
    adSpot.nativeType = 'html';
    adSpot.prebidAdId = adId;
    adSpot.mediaType = format;

    if (format === 'native' && adSpot.config.renderPrebidNativeSize) {
      adSpot.prebidSize = adSpot.config.renderPrebidNativeSize;
    } else {
      adSpot.prebidSize = size;
    }

    return generateSafeframeAd(adSpot);
  };
