const getQueryString = (browserWindow) =>
  browserWindow ? browserWindow.location.search : window.location.search;

const getQueryParams = (browserWindow) => {
  const keyValuePairs = getQueryString(browserWindow).substr(1).split('&');
  const params = {};

  if (keyValuePairs.length > 0) {
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      const keyVal = keyValuePairs[i].split('=');
      if (keyVal.length === 2) {
        params[keyVal[0]] = decodeURIComponent(keyVal[1].replace(/\+/g, ' '));
      }
    }
  }
  return params;
};

const IS_CLIENT = typeof window !== 'undefined';

export { getQueryParams, IS_CLIENT };
