import { paletteHelper, spacingHelper } from "@rea-group/construct-kit-core";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";

const Dialog = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: ${paletteHelper("backgroundPrimary")};
  min-height: 100%;
  width: 100%;
  position: relative;
  padding: ${spacingHelper("medium")};
  > *:not(:last-child) {
    margin-bottom: ${spacingHelper("medium")};
  }
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    border-radius: ${({ theme }) => theme.border.radius.large};
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};
const BasicDialog = ({
  children,
  className,
  ...props
}: Props): React.ReactElement<typeof Dialog> => {
  return (
    <Dialog
      {...props}
      className={className}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Dialog>
  );
};
export default BasicDialog;
