import React from "react";
import {
  BathMd,
  BedMd,
  CarMd,
  DeskMd,
  DwellingSizeMd,
  LandSizeMd,
  spacingHelper,
} from "@rea-group/construct-kit-core";
import { GeneralFeatures, PropertySize } from "../../types";
import styled from "styled-components";
import Feature from "./Feature";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingHelper("small")};
  margin-top: ${spacingHelper("twoExtraSmall")};
`;

type Props = {
  generalFeatures?: GeneralFeatures;
  propertySize?: PropertySize;
};

const PropertyGeneralFeatures = ({ generalFeatures, propertySize }: Props) => {
  return (
    <Container>
      <Feature
        icon={<BedMd />}
        label={`${generalFeatures?.bedrooms} bedroom${
          (generalFeatures?.bedrooms ?? 0) > 1 ? "s" : ""
        }`}
        displayValue={generalFeatures?.bedrooms}
      />
      <Feature
        icon={<DeskMd />}
        label="study"
        displayValue={generalFeatures?.studies}
        showDisplayValue={false}
      />
      <Feature
        icon={<BathMd />}
        label={`${generalFeatures?.bathrooms} bathroom${
          (generalFeatures?.bathrooms ?? 0) > 1 ? "s" : ""
        }`}
        displayValue={generalFeatures?.bathrooms}
      />

      <Feature
        icon={<CarMd />}
        label={`${generalFeatures?.parkingSpaces} parking space${
          (generalFeatures?.parkingSpaces ?? 0) > 1 ? "s" : ""
        }`}
        displayValue={generalFeatures?.parkingSpaces}
      />
      {propertySize?.type === "BUILDING" && (
        <Feature
          icon={<DwellingSizeMd />}
          label={`${propertySize.value} ${propertySize.unit} building size`}
          displayValue={`${propertySize.value} ${propertySize.unit}`}
        />
      )}
      {propertySize?.type === "LAND" && (
        <Feature
          icon={<LandSizeMd />}
          label={`${propertySize.value} ${propertySize.unit} building size`}
          displayValue={`${propertySize.value} ${propertySize.unit}`}
        />
      )}
    </Container>
  );
};

export default PropertyGeneralFeatures;
