import type { EventData, RegisterForEventInput } from "../types";
import { eventService } from "../service";
import RegisterForEventQuery from "./RegisterForEvent.graphql";

async function registerForEvent(
  input: RegisterForEventInput
): Promise<EventData> {
  return await eventService("registerForEvent", RegisterForEventQuery, {
    input,
  });
}
export { registerForEvent };
