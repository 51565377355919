import { AddToCalendarDialog } from "@realestate.com.au/planner-add-to-calendar";
import React, { FC, useState } from "react";
import RegistrationDialog from "../shared/registration-dialog";
import SuccessDialog from "../shared/success-dialog";
import {
  RegistrationModalDialogType,
  EventItem,
  MultipleEvents,
  SingleEvent,
  RegistrationModalPropsWithConfirmation,
} from "../../types";

export const DialogBox: FC<RegistrationModalPropsWithConfirmation> = ({
  address,
  generalFeatures,
  propertySize,
  events,
  eventTrackers,
  onClose,
  templatedUrl,
  onRegistrationSuccess,
}) => {
  const [dialog, setDialog] = useState<RegistrationModalDialogType | null>(
    "registration"
  );
  const [selectedEvent, setSelectedEvent] = useState<EventItem | undefined>(
    events.length === 1 ? events[0] : undefined
  );
  const registrationCalendarTrackers = eventTrackers.addToCalendar(
    "inspection_registration_confirmation_modal"
  );

  const handleClose = () => {
    onClose();
    setDialog(null);
  };

  const onAddToCalendarClick = () => {
    setDialog("addToCalendar");
    registrationCalendarTrackers.onSaveToCalendar();
  };

  if (selectedEvent?.event && dialog === "addToCalendar") {
    return (
      <AddToCalendarDialog
        event={selectedEvent.event}
        address={address}
        onClose={handleClose}
        onSaveToCalendarSuccess={
          registrationCalendarTrackers.onSaveToCalendarSuccess
        }
      />
    );
  }

  if (selectedEvent?.event && dialog === "success") {
    return (
      <SuccessDialog
        onClose={handleClose}
        event={selectedEvent.event}
        address={address}
        templatedUrl={templatedUrl}
        onAddToCalendarClick={onAddToCalendarClick}
        sourcePage={eventTrackers.sourcePage}
      />
    );
  }

  if (dialog === "registration") {
    let eventOrEvents;
    if (events.length > 1) {
      eventOrEvents = {
        eventItems: events,
        selectedEvent,
        onEventSelect: setSelectedEvent,
      } as MultipleEvents;
    } else if (events.length === 1 && events[0]) {
      eventOrEvents = {
        input: events[0].input,
        displayDate: events[0].event.displayDate,
        displayTime: events[0].event.displayTime,
      } as SingleEvent;
    } else {
      return null;
    }
    return (
      <RegistrationDialog
        data-testid="register-with-confirmation"
        eventOrEvents={eventOrEvents}
        generalFeatures={generalFeatures}
        propertySize={propertySize}
        address={address}
        onClose={handleClose}
        templatedUrl={templatedUrl}
        onRegistrationSuccess={({
          eventId,
          eventType,
          listingId,
          startTime,
          endTime,
        }) => {
          onRegistrationSuccess({
            eventId,
            eventType,
            listingId,
            startTime,
            endTime,
          });
          setDialog("success");
        }}
      />
    );
  }

  return null;
};

export default DialogBox;
