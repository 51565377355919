import { SelectOption } from '@construct-kit/core';
import { Experiment } from '../types';

export const bedroomConfigOptions: SelectOption[] = [
  {
    value: 'any',
    label: 'Any',
  },
  {
    value: '1 bedroom',
    label: '1 bedroom',
  },
  {
    value: '2 bedrooms',
    label: '2 bedrooms',
  },
  {
    value: '3 bedrooms',
    label: '3 bedrooms',
  },
  {
    value: '4 bedrooms',
    label: '4 bedrooms',
  },
  {
    value: '5+ bedrooms',
    label: '5+ bedrooms',
  },
];

export const budgetConfigOptions: SelectOption[] = [
  {
    value: 'any',
    label: 'Any',
  },
  {
    value: 'under $250,000',
    label: 'Under $250,000',
  },
  {
    value: '$250,000 - $400,000',
    label: '$250,000 - $400,000',
  },
  {
    value: '$400,000 - $550,000',
    label: '$400,000 - $550,000',
  },
  {
    value: '$550,000 - $700,000',
    label: '$550,000 - $700,000',
  },
  {
    value: '$700,000 - $850,000',
    label: '$700,000 - $850,000',
  },
  {
    value: '$850,000 - $1,000,000',
    label: '$850,000 - $1,000,000',
  },
  {
    label: '$1,000,000 - $1,150,000',
    value: '$1,000,000 - $1,150,000',
  },
  {
    value: '$1,150,000 - $1,300,000',
    label: '$1,150,000 - $1,300,000',
  },
  {
    value: '$1,300,000 - $1,500,000',
    label: '$1,300,000 - $1,500,000',
  },
  {
    value: '$1,500,000 - $2,000,000',
    label: '$1,500,000 - $2,000,000',
  },
  {
    value: 'over $2,000,000',
    label: 'Over $2,000,000',
  },
  {
    value: 'rather not say',
    label: 'Rather not say',
  },
];

export const buyTimePeriod = [
  '1 - 3 months',
  '3 - 6 months',
  '6+ months',
  'Rather not say',
];

export const LeadFormAdditionalFieldExperimentConfig: Experiment = {
  id: 'ALL-Web-SRP-Native_Ad_Lead_Form-Additional_Fields',
  variations: {
    control: 'control',
    variationA: 'variationA',
  },
  eventKey: 'enquiry_form_submitted',
  section: 'srp_lead_form',
};

export const LeadFormAdditionalFieldEventKey = {
  bedroomKey: 'bedroom_field_completed',
  budgetKey: 'budget_field_completed',
  buyTimePeriodKey: 'buy_time_period_field_completed',
};
