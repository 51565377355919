import type { Address, AddToCalendarProps, Event, EventType } from "../types";
import { constructICSFileName } from "./constructICSFileName";
import { constructSummary } from "./constructSummary";
import eventTypeMap from "./eventTypeMap";
import { possibleEventTypes } from "../types";
import { logger } from "@rea-argonaut/observability";

const generateAdaptedEventType = (eventType: EventType) => {
  const isValidEventTypes = possibleEventTypes.some(
    (type) => eventType === type
  );
  if (isValidEventTypes) {
    return eventTypeMap[eventType];
  }

  logger.error({
    error: new Error(`Invalid Event Type: ${eventType}`),
    description:
      "AddToCalendar: the provided Event object has an invalid 'eventType' property (should be Inspection, Auction or Display_Suite).",
  });
  return {
    displayString: eventType,
    fileName: eventType,
  };
};

export const adaptEventData = ({
  event,
  address,
}: {
  event: Event;
  address: Address;
}): AddToCalendarProps => {
  const { displayDate, displayTime, eventType } = event;
  const { displayString, fileName } = generateAdaptedEventType(eventType);

  return {
    googleUrl: event.calendarIntegration.google.href,
    summary: constructSummary({
      address,
      displayDate,
      displayTime,
      displayString,
    }),
    icsFileData: {
      content: event.calendarIntegration.ics.content,
      filename: constructICSFileName(fileName, address),
    },
  };
};
