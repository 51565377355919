import { LeadAdTracker, LogError } from '../../types';

export const track = (trackingUrl: string, method: string): Promise<Response> =>
  fetch(trackingUrl, {
    method: method,
  });

export const attemptTrack = async (
  logError: LogError,
  trackingUrl: string,
  method: string,
): Promise<void> => {
  try {
    const response = await track(trackingUrl, method);

    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(responseText);
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      return;
    }

    logError(`Error tracking native lead ad - url: ${trackingUrl}`, err);
  }
};

export const fireThirdPartyTracking = (trackingUrls: string[]): void => {
  trackingUrls
    .filter((url) => url.trim().length > 0)
    .forEach((trackingUrl) => {
      new Image().src = trackingUrl;
    });
};

export const getTrackerUrlByName = (
  trackers: LeadAdTracker[],
  actionName: string,
): LeadAdTracker | undefined => {
  return trackers.find((tracker) => tracker.actionname === actionName);
};
