export const errorMessagesTemplate = {
  nameRequired: "Please enter your full name.",
  emailRequired: "Please enter your email address.",
  containsDots: "Please remove any URLs from this message.",
  emailInvalid:
    "Please enter your email address in the format: email@example.com.",
  phoneInvalid: {
    insufficent: "Phone number must be at least 8 digits.",
    format: "Please enter a valid phone number.",
  },
};

// source: Locke Advanced UI https://git.realestate.com.au/Locke/locke-advanced-ui/blob/master/src/ts/utils/validator.ts
export const EMAIL_REGEX_PATTERN =
  '^([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,}))$';

export const isBlank = (s: string) => s.trim() === "";

export const isSufficientNumber = (s: string) =>
  s.replace(/\D/g, "").length >= 8;

export const isValidPhone = (s: string) =>
  /^[+]?[\s\d]*[(]?\d+[)]?[-\s\d]+$/.test(s);

export const containsBadUrl = (s: string) => {
  const sWithoutReaUrls = s.replace(
    /([\w.]*\.)?(realestate|realcommercial)\.com\.au/,
    ""
  );
  const urlPattern =
    /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\\w .-]+)*\/?/;
  return urlPattern.test(sWithoutReaUrls);
};

export const isValidEmail = (s: string) =>
  new RegExp(EMAIL_REGEX_PATTERN).test(s);

export const validateName = (name: string) =>
  isBlank(name)
    ? errorMessagesTemplate.nameRequired
    : containsBadUrl(name)
    ? errorMessagesTemplate.containsDots
    : "";

export const validatePhoneNumber = (s: string) =>
  isBlank(s)
    ? ""
    : !isValidPhone(s)
    ? errorMessagesTemplate.phoneInvalid.format
    : !isSufficientNumber(s)
    ? errorMessagesTemplate.phoneInvalid.insufficent
    : "";

export const validateEmail = (email: string) =>
  isBlank(email)
    ? errorMessagesTemplate.emailRequired
    : !isValidEmail(email)
    ? errorMessagesTemplate.emailInvalid
    : "";
