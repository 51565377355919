import renderTemplateImpl from '../renderTemplate';
import NativeAdTrackerImpl from '../NativeAdTracker';
import galleryTemplateImpl from './galleryTemplate';

class GalleryAdComponent {
  constructor(
    renderTemplate = renderTemplateImpl,
    NativeAdTracker = NativeAdTrackerImpl,
    galleryTemplate = galleryTemplateImpl,
  ) {
    this.renderTemplate = renderTemplate;
    this.NativeAdTracker = NativeAdTracker;
    this.galleryTemplate = galleryTemplate;
  }

  render(adContentJson, renderTarget) {
    return this.renderTemplate(
      renderTarget,
      adContentJson,
      this.galleryTemplate,
    ).then(() => {
      this.trackThirdParty(adContentJson.trackers);
    });
  }

  trackThirdParty(trackers) {
    this.NativeAdTracker.trackDownloadImpression(trackers);
  }
}

export default GalleryAdComponent;
