const getSafeframeAssetsPath = () => {
  if (process.env.SAFEFRAME_ASSET_DEPLOY_COMMIT_ID) {
    return `${process.env.SAFEFRAME_ASSETS_ORIGIN}/${process.env.SAFEFRAME_ASSET_DEPLOY_COMMIT_ID}`;
  }

  return process.env.SAFEFRAME_ASSETS_ORIGIN;
};

export const SAFEFRAME_ASSETS_PATH = getSafeframeAssetsPath();

export const PREBID_IFRAME_ORIGIN = process.env.SAFEFRAME_ASSETS_ORIGIN;
