import React, { FC } from "react";
import { Link, Text, paletteHelper } from "@rea-group/construct-kit-core";
import styled from "styled-components";

const StyledText = styled(Text)`
  color: ${paletteHelper("textSecondary")};
`;

const PrivacyStatement: FC = () => (
  <StyledText variant="body02">
    Your personal information will be passed to the Agency and/or its authorised
    service provider to provide you with information about your inspection
    registration and the listed property. They are required not to use your
    personal information for any other purpose. Our{" "}
    <Link
      variant="inline"
      href="https://about.realestate.com.au/privacy/"
      rel="noopener noreferrer"
      target="_blank"
    >
      Privacy Policy
    </Link>{" "}
    explains how we handle your personal information.
  </StyledText>
);

export default PrivacyStatement;
