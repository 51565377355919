export const getTealiumSegments = (utag) => {
  // cdp_audiences is a string w/ comma separated list of keys 'rea-group_main_235,rea-group_main_223'
  if (utag.data.cdp_audiences == undefined) {
    return;
  }

  const keys = utag.data.cdp_audiences.split(',');

  // Filter out keys based on values. Keep only those keys whose values start with 'm', e.g. 'm000_001_media_segment'
  return keys.filter(
    (key) => utag.data[`va.audiences.${key}`]?.startsWith('m') ?? false,
  );
};

// Example: 'st_george,canterbury_bankstown'
export const getTealiumRegion = (utag) => utag.data['va.properties.9633'];

export const getTealiumUid2id = (utag) => utag.data['va.properties.11353'];

const getTealiumUtag = () =>
  new Promise((resolve) =>
    window['@argonaut/analytics/loadTealium'](() => resolve(window.utag)),
  );

export default getTealiumUtag;
