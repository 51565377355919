import React from "react";
import { CrossLg, Text, paletteHelper } from "@rea-group/construct-kit-core";
import { Event, Address } from "@realestate.com.au/planner-add-to-calendar";
import { SyntheticEvent } from "react";
import styled from "styled-components";
import { Header, Title, SuccessLgGreen, Dialog, CloseButton } from "./styles";
import InspectionSummaryWithButtons from "../InspectionSummaryWithButtons";
import { PLANNER_CONFIRMATION_MODAL_INSPECTION_REGISTRATION } from "../../../const";

const Subtitle = styled(Text)`
  color: ${paletteHelper("textSecondary")};
`;

export type Props = {
  onClose: (e: SyntheticEvent) => void;
  event: Event;
  address: Address;
  onAddToCalendarClick: () => void;
  templatedUrl: string;
  sourcePage: string;
};

export const SuccessDialog = ({
  onClose,
  event,
  address,
  templatedUrl,
  onAddToCalendarClick,
  sourcePage,
}: Props) => {
  return (
    <Dialog>
      <CloseButton onClick={onClose} icon={<CrossLg />} />
      <Header>
        <SuccessLgGreen />
        <Title>Registered!</Title>
      </Header>
      <Subtitle>
        You’re all set — we’ve let the agent know you want to attend.
      </Subtitle>
      <InspectionSummaryWithButtons
        event={event}
        address={address}
        templatedUrl={templatedUrl}
        onAddToCalendarClick={onAddToCalendarClick}
        sourcePage={sourcePage}
        sourceElement={PLANNER_CONFIRMATION_MODAL_INSPECTION_REGISTRATION}
      />
    </Dialog>
  );
};
