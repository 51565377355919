import type { Address } from "../types";

export function constructICSFileName(
  eventTypeInFileName: string,
  address: Address
): string {
  const shortAddress = address.display.shortAddress;
  const fallbackSuburb = address.suburb.split(" ").join("_");
  const fileFriendlyAddress = shortAddress
    ? shortAddress.split(" ").join("_")
    : fallbackSuburb;
  return `${fileFriendlyAddress}_${eventTypeInFileName}.ics`;
}
