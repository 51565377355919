import { SAFEFRAME_ASSETS_PATH } from '../../../rui-env-variables';
import renderSafeframe from './renderSafeframe';

class VideoGalleryAdComponent {
  render(_, renderTarget) {
    const iframeSrc = `${SAFEFRAME_ASSETS_PATH}/video/video.html`;
    return renderSafeframe(iframeSrc, renderTarget);
  }
}

export default VideoGalleryAdComponent;
