const AGENT_CONTACT_HOST = 'https://agent-contact.realestate.com.au';

const LMS_HOST = 'https://api.leads.developersites.com.au';

export interface EnquirySubmissionParams {
  projectId: string;
  agencyId: string;
  creativeId: string;
  extensionCampaignId: string;
  name: string;
  email: string;
  phoneNumber: string;
  bedroomConfig: string;
  budgetConfig: string;
  buyTimePeriodConfig: string;
  lmsCampaignId: string;
  enquirySubmissionUrl: string;
}

interface CampaignIdEndpointPayload {
  fromName: string;
  fromPhone: string;
  fromAddress: string;
  message?: string;
}

interface CreativeIdEndpointPayload extends CampaignIdEndpointPayload {
  projectId: string;
  agencyId: string;
}

interface LMSSubmissionPayload {
  data: {
    type: 'capture-leads';
    attributes: {
      responses: {
        'project-id'?: string;
        device: 'desktop';
        source: 'Native-Leads-Ad';
        'Number Of Bedrooms'?: string;
      };
      name: string;
      email: string;
      phone: string;
      'request-referrer': 'https://www.realestate.com.au/';
    };
    relationships: {
      'capture-campaign': {
        data: {
          type: 'capture-campaigns';
          id: string;
        };
      };
    };
  };
}

interface SubmissionRequestOptions {
  url: string;
  body:
    | CampaignIdEndpointPayload
    | CreativeIdEndpointPayload
    | LMSSubmissionPayload;
  credentials: RequestCredentials;
}

const adaptLMSPayload = (
  name: string,
  email: string,
  projectId: string,
  phoneNumber: string,
  bedroomConfig: string,
  budgetConfig: string,
  buyTimePeriodConfig: string,
  lmsCampaignId: string,
): LMSSubmissionPayload => ({
  data: {
    type: 'capture-leads',
    attributes: {
      responses: {
        ...(!!projectId && { 'project-id': projectId }),
        device: 'desktop',
        source: 'Native-Leads-Ad',
        ...(!!bedroomConfig && { 'Number Of Bedrooms': bedroomConfig }),
        ...(!!budgetConfig && { Budget: budgetConfig }),
        ...(!!buyTimePeriodConfig && {
          'Buy Time Period': buyTimePeriodConfig,
        }),
      },
      name: name,
      email: email,
      phone: phoneNumber,
      'request-referrer': 'https://www.realestate.com.au/',
    },
    relationships: {
      'capture-campaign': {
        data: {
          type: 'capture-campaigns',
          id: lmsCampaignId,
        },
      },
    },
  },
});

const adaptCampaignIdEndpointPayload = (
  name: string,
  email: string,
  phoneNumber: string,
  bedroomConfig: string,
  budgetConfig: string,
  buyTimePeriodConfig: string,
): CampaignIdEndpointPayload => {
  const message = buildMessageForAgentContactPayload(
    bedroomConfig,
    budgetConfig,
    buyTimePeriodConfig,
  );

  return {
    fromName: name,
    fromPhone: phoneNumber,
    fromAddress: email,
    ...(!!message && { message }),
  };
};

export const getSubmissionRequestOptions = (
  {
    projectId,
    agencyId,
    creativeId,
    extensionCampaignId,
    name,
    email,
    phoneNumber,
    bedroomConfig,
    budgetConfig,
    buyTimePeriodConfig,
    lmsCampaignId,
    enquirySubmissionUrl,
  }: EnquirySubmissionParams,
  isNMALToggleOn?: boolean,
): SubmissionRequestOptions => {
  const campaignIdEndpointPayload: CampaignIdEndpointPayload =
    adaptCampaignIdEndpointPayload(
      name,
      email,
      phoneNumber,
      bedroomConfig,
      budgetConfig,
      buyTimePeriodConfig,
    );

  const creativeIdEndpointPayload: CreativeIdEndpointPayload = {
    ...campaignIdEndpointPayload,
    agencyId,
    projectId,
  };

  const lmsPayload = adaptLMSPayload(
    name,
    email,
    projectId ?? '',
    phoneNumber,
    bedroomConfig,
    budgetConfig,
    buyTimePeriodConfig,
    lmsCampaignId,
  );

  if (!isNMALToggleOn) {
    return {
      url: enquirySubmissionUrl,
      body: projectId ? creativeIdEndpointPayload : lmsPayload,
      credentials: 'same-origin',
    };
  }

  if (projectId) {
    return {
      url: `${AGENT_CONTACT_HOST}/native-lead-ad/creative-id/${creativeId}`,
      body: creativeIdEndpointPayload,
      credentials: 'include',
    };
  } else if (extensionCampaignId) {
    return {
      url: `${AGENT_CONTACT_HOST}/native-lead-ad/campaign-id/${extensionCampaignId}`,
      body: campaignIdEndpointPayload,
      credentials: 'include',
    };
  } else {
    return {
      url: `${LMS_HOST}/api/v1/capture-leads`,
      body: lmsPayload,
      credentials: 'same-origin',
    };
  }
};

export const buildMessageForAgentContactPayload = (
  bedroomConfig: string,
  budgetConfig: string,
  buyTimePeriodConfig: string,
): string => {
  return [
    bedroomConfig ? `\n- Number of bedrooms: ${bedroomConfig}` : '',
    budgetConfig ? `\n- Budget: ${budgetConfig}` : '',
    buyTimePeriodConfig ? `\n- Buy time period: ${buyTimePeriodConfig}` : '',
  ]
    .filter((x) => x !== '')
    .join('');
};

const submitEnquiry = (
  params: EnquirySubmissionParams,
  isNMALToggleOn?: boolean,
): Promise<Response> => {
  const { url, body, credentials } = getSubmissionRequestOptions(
    params,
    isNMALToggleOn,
  );

  return fetch(url, {
    method: 'POST',
    credentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export default submitEnquiry;
