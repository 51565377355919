import {
  Button,
  CrossLg,
  paletteHelper,
  spacingHelper,
  Stack,
  Strong,
  typographyHelper,
} from "@rea-group/construct-kit-core";
import type { Address, Event } from "../types";
import { adaptEventData, constructSaveLinks } from "@utils";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import AddToCalendarLink from "./AddToCalendarLink";
import SectionHeading from "./SectionHeading";

const SectionHeadingWithMargin = styled(SectionHeading)`
  margin-top: ${spacingHelper("medium")};
`;

const AddToCalendarWrapper = styled(Stack).attrs({
  inset: "2.5rem",
})`
  background-color: ${paletteHelper("backgroundPrimary")};
  height: 100%;
  position: relative;
  width: 100%;
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    height: auto;
    width: 450px;
    border-radius: ${({ theme }) => theme.border.radius.large};
  }
`;

const Summary = styled.p`
  ${typographyHelper("body02")}
`;

type CloseButtonProps = {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  icon: JSX.Element;
};

const CloseButton = styled(Button).attrs((props: CloseButtonProps) => {
  return {
    compact: true,
    hideLabel: true,
    icon: props.icon,
    onClick: props.onClick,
    sizeVariant: "medium",
    variant: "naked",
  };
})`
  position: absolute;
  right: ${spacingHelper("large")};
  top: ${spacingHelper("large")};
`;

type Props = {
  event: Event;
  address: Address;
  onClose: () => void;
  onSaveToCalendarSuccess: () => void;
};

export const AddToCalendarDialog = ({
  event,
  address,
  onSaveToCalendarSuccess: onSaveToCalendarSuccess,
  onClose,
}: Props) => {
  const { googleUrl, icsFileData, summary } = adaptEventData({
    event,
    address,
  });

  return (
    <AddToCalendarWrapper
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <CloseButton onClick={onClose} icon={<CrossLg />} />
      <SectionHeadingWithMargin>Add to calendar</SectionHeadingWithMargin>
      <Summary>
        {summary.eventLocation}
        <Strong>{summary.eventDate}</Strong>
      </Summary>
      <Stack gap="medium">
        {constructSaveLinks(icsFileData, googleUrl).map((link, index) => (
          <AddToCalendarLink
            {...link}
            key={index}
            onSaveToCalendarSuccess={onSaveToCalendarSuccess}
          />
        ))}
      </Stack>
    </AddToCalendarWrapper>
  );
};
