import React from "react";
import styled from "styled-components";
import {
  H2,
  paletteHelper,
  spacingHelper,
} from "@rea-group/construct-kit-core";

const Title = styled(H2).attrs({ variant: "title02" })`
  margin-bottom: ${spacingHelper("small")};
  color: ${paletteHelper("textPrimary")};
`;
type Props = React.ComponentPropsWithRef<typeof Title>;
const SectionHeading = React.forwardRef<typeof Title, Props>(
  ({ children, ...props }, ref) => (
    <Title {...props} ref={ref}>
      {children}
    </Title>
  )
);

SectionHeading.displayName = "SectionHeading";

export default SectionHeading;
