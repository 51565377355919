import { SAFEFRAME_ASSETS_PATH } from '../rui-env-variables';
import { IS_CLIENT } from '../utils/browserUtils';
import { isDebugEnabled } from '../utils/loggingUtil';

const getPlatform = () => {
  if (window.innerWidth <= 500) {
    return 'msite';
  } else if (window.innerWidth <= 999) {
    return 'midi';
  } else {
    return 'web';
  }
};

const getDemandManagerBaseUrl = (isDemandManagerTest) => {
  if (isDemandManagerTest) {
    return `${SAFEFRAME_ASSETS_PATH}/prebid/pb_demandmanager_test.html`;
  }

  const platform = getPlatform();
  return `${SAFEFRAME_ASSETS_PATH}/prebid/pb_demandmanager_${platform}.html`;
};

export const getPrebidIframeUrlOnClient = (isDemandManagerTest) => {
  const pbBaseUrl = getDemandManagerBaseUrl(isDemandManagerTest);

  if (
    !IS_CLIENT ||
    typeof URL === 'undefined' ||
    typeof URLSearchParams === 'undefined'
  ) {
    return pbBaseUrl;
  }

  const url = new URL(pbBaseUrl);

  if (isDebugEnabled()) {
    url.searchParams.set('pbjs_debug', 'true');
  }

  return url.toString();
};

const getPrebidIframeUrl = (searchParams) => {
  const params = new URLSearchParams(searchParams);

  const isDemandManagerTest = params.get('demandManagerTest') === 'true';

  const baseUrl = `${SAFEFRAME_ASSETS_PATH}/prebid/${
    isDemandManagerTest
      ? 'pb_demandmanager_test.html'
      : 'pb_demandmanager_(platform).html'
  }`;

  const url = new URL(baseUrl);

  if (params.get('pbjs_debug') === 'true') {
    url.searchParams.set('pbjs_debug', 'true');
  }

  if (params.get('rui_debug') === 'true') {
    url.searchParams.set('rui_debug', 'true');
  }

  return url.href;
};

export default getPrebidIframeUrl;
