const createContainer = (cssClasses) => {
  const container = document.createElement('div');
  if (cssClasses) {
    container.className = cssClasses;
  }
  return container;
};

const getStorageItem = (key) => {
  let match;
  let value;

  if (window.localStorage) {
    value = window.localStorage.getItem(key) || '';
  } else if (navigator.cookieEnabled) {
    match = document.cookie.match(`${key}=([^;]*)`);
    value = (match && unescape(match[1])) || '';
  }
  return value;
};

const isElementVisible = (element) =>
  element.style.display !== 'none' &&
  element.style.visibility !== 'hidden' &&
  element.offsetWidth > 0 &&
  element.offsetHeight > 0;

const loadScriptTag = (scriptTag) =>
  new Promise((resolve) => {
    scriptTag.onload = () => resolve();
  });

export { createContainer, getStorageItem, isElementVisible, loadScriptTag };
