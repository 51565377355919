let isDebug = false;
if (
  typeof document !== 'undefined' &&
  document.location.search.includes('debug_v=1')
) {
  isDebug = true;
}

export const log = (message?: unknown, ...optionalParams: unknown[]): void => {
  if (isDebug) {
    console.log('Outstream video ad', '-', message, ...optionalParams);
  }
};
