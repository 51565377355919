import NativeAdTracker from '../NativeAdTracker';

const TO_TRACK = 'TO_TRACK';
const TRACKED = 'TRACKED';

function trackDownload(trackers) {
  NativeAdTracker.trackDownloadImpression(trackers);
}

function trackPlayButtonClick(trackers) {
  NativeAdTracker.trackVideoPlayButtonClick(trackers);
}

function addListenerToTrackPlayButtonClick(playButton, state) {
  playButton.addEventListener('click', () => {
    trackPlayButtonClick(state.trackers);
  });
}

function trackSoundControlClick(trackers) {
  NativeAdTracker.trackSoundControlClick(trackers);
}

function addListenerToSoundControlClick(soundControl, state) {
  soundControl.addEventListener('click', () => {
    trackSoundControlClick(state.trackers);
  });
}

function addListenerToTrackLogoClick(logo, state) {
  logo.addEventListener('click', () => {
    NativeAdTracker.trackLogoClick(state.trackers);
  });
}

function getPercentageTrackingPoint(percentagePlayed) {
  if (percentagePlayed === 100) {
    return 100;
  }
  if (percentagePlayed >= 75) {
    return 75;
  }
  if (percentagePlayed >= 50) {
    return 50;
  }
  if (percentagePlayed >= 25) {
    return 25;
  }
  if (percentagePlayed >= 1) {
    return 1;
  }

  return 0;
}

function initPercentageToTrack() {
  return {
    1: TO_TRACK,
    25: TO_TRACK,
    50: TO_TRACK,
    75: TO_TRACK,
    100: TO_TRACK,
  };
}

function updatePercentageTracked(state, action) {
  switch (action.type) {
    case 'TRACK_PERCENTAGE': {
      const percentageTrackingPoint = action.percentageTrackingPoint;
      if (percentageTrackingPoint === 100) {
        state.percentageTracked = initPercentageToTrack();
      } else {
        state.percentageTracked[percentageTrackingPoint] = TRACKED;
      }

      break;
    }
    default:
      break;
  }
}

function trackPercentagePlayed(trackers, percentage) {
  NativeAdTracker.trackPercentagePlayed(trackers, percentage);
}

function trackPercentagePlayedIfRequired(state) {
  const percentageTracked = state.percentageTracked;
  const percentageTrackingPoint = state.percentageTrackingPoint;

  if (percentageTracked[percentageTrackingPoint] === TO_TRACK) {
    const action = { type: 'TRACK_PERCENTAGE', percentageTrackingPoint };
    updatePercentageTracked(state, action);
    trackPercentagePlayed(state.trackers, percentageTrackingPoint);
  }
}

function updatePercentageTrackingPoint(state, action) {
  switch (action.type) {
    case 'UPDATE_TIME': {
      const percentagePlayed = Math.floor(
        (action.currentTime / action.duration) * 100,
      );

      if (percentagePlayed === 100) {
        return;
      }

      const percentageTrackingPoint =
        getPercentageTrackingPoint(percentagePlayed);
      state.percentageTrackingPoint = percentageTrackingPoint;
      break;
    }
    case 'END': {
      state.percentageTrackingPoint = 100;
      break;
    }
    default:
      break;
  }
  trackPercentagePlayedIfRequired(state);
}

function addListenerToTrackPercentagePlayed(video, state) {
  video.addEventListener('timeupdate', () => {
    if (!video.seeking) {
      const action = {
        type: 'UPDATE_TIME',
        currentTime: video.currentTime,
        duration: video.duration,
      };
      updatePercentageTrackingPoint(state, action);
    }
  });

  video.addEventListener('ended', () => {
    const action = { type: 'END' };
    updatePercentageTrackingPoint(state, action);
  });
}

function VideoAdTracker(video, soundControl, playButton, logo, trackers) {
  const trackingState = {
    trackers,
    percentageTracked: initPercentageToTrack(),
    percentageTrackingPoint: 0,
  };

  addListenerToTrackPlayButtonClick(playButton, trackingState);
  addListenerToSoundControlClick(soundControl, trackingState);
  addListenerToTrackPercentagePlayed(video, trackingState);
  addListenerToTrackLogoClick(logo, trackingState);
  trackDownload(trackers);
}

export default VideoAdTracker;
