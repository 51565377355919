const prebidNativeSizeMappings = {
  desktop: {
    srp: {
      strip1: '640x380',
      strip2: '640x380',
      strip3: '640x380',
      strip4: '640x380',
      strip5: '640x380',
      header: '728x90',
      devfeaturetop: '300x600',
      skyscraper: '300x600',
      medrec2: '300x600',
      medrec3: '300x600',
    },
    pdp: {
      header: '728x90',
      medrec1: '300x250',
      medrec2: '300x250',
      medrec3: '300x600',
      photosky: '300x600',
      photosky2: '300x600',
      mapsky: '160x600',
      photogallery: '300x250',
    },
  },
  msite: {
    srp: {
      strip1: '300x250',
      strip2: '300x250',
      strip3: '300x250',
      strip4: '300x250',
      strip5: '300x250',
    },
    pdp: {
      medrec1: '300x250',
      photogallery: '300x250',
    },
  },
};

const prebidNativeSize = (pageType, isSmallScreen, position) => {
  if (pageType !== 'srp' && pageType !== 'pdp') {
    throw new Error('Invalid pageType');
  }
  const platform = isSmallScreen ? 'msite' : 'desktop';
  return prebidNativeSizeMappings[platform][pageType][position];
};

export const getSrpPrebidNativeSize = (isSmallScreen, position) =>
  prebidNativeSize('srp', isSmallScreen, position);

export const getPdpPrebidNativeSize = (isSmallScreen, position) =>
  prebidNativeSize('pdp', isSmallScreen, position);
