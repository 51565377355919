const createJsonAdHandlerCommon =
  (nativeComponentRenderer) => (adSpot, adContent) => {
    const { content } = adContent;

    adSpot.nativeType = 'json';
    adSpot.nativeContent = content;
    adSpot.cleanup();

    if (adSpot.nativeRenderCallback) {
      if (typeof adSpot.nativeRenderCallback !== 'function') {
        return Promise.reject(
          new Error('Custom native renderer callback is not a function'),
        );
      }

      adSpot.nativeRenderCallback(content, adSpot.element);
      return Promise.resolve('loaded native ad in nativeRenderCallback');
    }

    if (!adSpot.isAdLoaded()) {
      return Promise.reject(new Error('native basic ad not loaded'));
    }

    const adLoaded = true;

    return nativeComponentRenderer
      .render(content, adSpot.element, adSpot)
      .then(() => {
        if (typeof adSpot.loadCompleteCallback === 'function') {
          adSpot.loadCompleteCallback(content, adLoaded);
        }

        return 'Loaded native ad in native template render';
      });
  };

export default createJsonAdHandlerCommon;
