import type {
  ICSFileData,
  AddToCalendar as AddToCalendarLinkType,
} from "../types";

const constructHref = (icsContent: string): string => {
  return `data:text/calendar;charset=utf-8,${encodeURIComponent(icsContent)}`;
};

export const constructSaveLinks = (
  icsFileData: ICSFileData,
  googleUrl: string
): Array<AddToCalendarLinkType> => {
  return [
    {
      display: "Google calendar",
      icon: "google",
      href: googleUrl,
    },
    {
      display: "Apple calendar",
      icon: "apple",
      href: constructHref(icsFileData.content),
      filename: icsFileData.filename,
    },
    {
      display: "Outlook calendar",
      icon: "outlook",
      href: constructHref(icsFileData.content),
      filename: icsFileData.filename,
    },
    {
      display: "Other",
      icon: "other",
      href: constructHref(icsFileData.content),
      filename: icsFileData.filename,
    },
  ];
};
