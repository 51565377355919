import { getStorageItem } from './utils/domUtil';

const getKruxSegments = () => {
  const value = getStorageItem('kxsegs') ?? '';
  return value.length > 0 ? value.split(',') : [];
};

const getKruxUID = () => getStorageItem('kxkuid') ?? '';

export { getKruxSegments, getKruxUID };
