import { SAFEFRAME_ASSETS_PATH } from '../../rui-env-variables';
import { loadScriptTag } from '../../utils/domUtil';
// In production we want to use the bundled and minified files
const scriptPaths =
  process.env.NODE_ENV === 'production'
    ? ['/lib/base.min.js', '/host/host.min.js']
    : ['/lib/base.js', '/host/host.js', '/lib/boot.js'];

let safeFrameAssetsLoadedPromise;

function addScript(scriptElement) {
  const promise = loadScriptTag(scriptElement);

  document.getElementsByTagName('head')[0].appendChild(scriptElement);

  return promise;
}

const addScriptsToPage = (scripts) =>
  scripts.reduce(
    (acc, script) => acc.then(() => addScript(script)),
    Promise.resolve(),
  );

function createScriptElement(script) {
  const scriptElement = document.createElement('script');
  scriptElement.src = `${SAFEFRAME_ASSETS_PATH}${script}`;
  scriptElement.async = true;
  scriptElement.type = 'text/javascript';

  return scriptElement;
}

function createScripts() {
  return scriptPaths.map((s) => createScriptElement(s));
}

function loadSafeframeAssets() {
  if (safeFrameAssetsLoadedPromise) {
    return safeFrameAssetsLoadedPromise;
  }

  const scripts = createScripts();

  safeFrameAssetsLoadedPromise = addScriptsToPage(scripts);

  return safeFrameAssetsLoadedPromise;
}

export default loadSafeframeAssets;
