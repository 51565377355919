import React from "react";
import { Stack, Text, spacingHelper } from "@rea-group/construct-kit-core";
import { Address } from "@realestate.com.au/planner-add-to-calendar";
import { GeneralFeatures, PropertySize } from "../../types";
import styled from "styled-components";
import PropertyGeneralFeatures from "./PropertyGeneralFeatures";

const TextImageContainer = styled.div`
  display: flex;
`;

type DisplayProps = {
  time: string;
  date: string;
};

const IMAGE_SIZE = 88;

const ImageContainer = styled.div`
  flex-grow: 0;
  height: ${IMAGE_SIZE}px;
`;

const Img = styled.img`
  border-radius: ${({ theme }) => theme.border.radius.large};
`;

const TextWrapper = styled.div`
  margin-left: ${spacingHelper("medium")};
  min-width: 0%;
`;

const AddressText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  padding: ${spacingHelper("extraSmall 0 twoExtraSmall")};
`;

type Props = {
  displayDateTime?: DisplayProps;
  generalFeatures?: GeneralFeatures;
  propertySize?: PropertySize;
  address: Address;
  templatedUrl: string;
};

const InspectionSummary = ({
  displayDateTime,
  address,
  templatedUrl,
  generalFeatures,
  propertySize,
}: Props) => {
  const imgSrcUrl = templatedUrl.replace(
    "{size}",
    `${IMAGE_SIZE}x${IMAGE_SIZE}`
  );
  const webpSrcUrl = templatedUrl.replace(
    "{size}",
    `${IMAGE_SIZE}x${IMAGE_SIZE}-format=webp`
  );

  const addressSecondLine = (address: Address) => {
    let output = address.suburb;
    output += address.state ? `, ${address.state}` : "";
    output += address.postcode ? ` ${address.postcode}` : "";
    return output;
  };

  return (
    <TextImageContainer>
      <ImageContainer>
        <source srcSet={webpSrcUrl} type="image/webp" />
        <Img src={imgSrcUrl} alt={address.display.fullAddress} />
      </ImageContainer>
      <TextWrapper>
        {displayDateTime ? (
          <>
            <Text variant="title03">{displayDateTime.date}</Text>
            <StyledText>{displayDateTime.time}</StyledText>
            <AddressText>{address.display.shortAddress}</AddressText>
          </>
        ) : (
          <Stack gap="twoExtraSmall">
            <AddressText>{address.display.shortAddress}</AddressText>
            <AddressText>{addressSecondLine(address)}</AddressText>
            <PropertyGeneralFeatures
              generalFeatures={generalFeatures}
              propertySize={propertySize}
            />
          </Stack>
        )}
      </TextWrapper>
    </TextImageContainer>
  );
};

export default InspectionSummary;
