export const galleryTemplate = `
<div class="native-gallery">
  <p class="native-gallery__info">{{adsponsoredtext}}{{^adsponsoredtext}}ADVERTISEMENT{{/adsponsoredtext}}</p>
  <div class="native-gallery__logo-container">
    <a href="{{clickurl}}" target="_blank" rel="noopener noreferrer"><img class="native-gallery__logo" src="{{brandlogourl}}" alt="logo"></a>
  </div>
  <div><a class="native-gallery__title" href="{{clickurl}}" target="_blank" rel="noopener noreferrer">{{admessagetitle}}</a></div>
  <div><a class="native-gallery__description" href="{{clickurl}}" target="_blank" rel="noopener noreferrer">{{admessagetext}}</a></div>
  <div><a class="native-gallery__ad-button" href="{{clickurl}}" target="_blank" rel="noopener noreferrer">{{actionmessagetext}}</a></div>
</div>
`;

export default galleryTemplate;
