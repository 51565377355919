import React, { FC } from "react";
import ResponsiveModal from "../shared/ResponsiveModal";
import DialogBox from "./DialogBox";
import DialogBoxWithoutConfirmation from "./DialogBoxWithoutConfirmation";
import {
  RegistrationModalPropsWithConfirmation,
  RegistrationModalPropsWithoutConfirmation,
} from "../../types";
import { isRegistrationWithConfirmation } from "../../utils";

type DialogBoxProps =
  | RegistrationModalPropsWithConfirmation
  | RegistrationModalPropsWithoutConfirmation;

type Props = DialogBoxProps & {
  isOpen: boolean;
  modalMountID: string;
};

const RegistrationModal: FC<Props> = (props) => {
  return (
    <ResponsiveModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      modalMountID={props.modalMountID}
    >
      {isRegistrationWithConfirmation(props) ? (
        <DialogBox {...props} />
      ) : (
        <DialogBoxWithoutConfirmation {...props} />
      )}
    </ResponsiveModal>
  );
};

export default RegistrationModal;
