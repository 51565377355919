import Advertorial from '@media/rui-advertorial';
import { AdResponse } from './types';

const renderAd = (
  adContent: AdResponse,
  container: HTMLElement,
): Promise<unknown> => {
  return Advertorial.renderAd(adContent, container);
};

export default renderAd;
