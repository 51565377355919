import {
  spacingHelper,
  paletteHelper,
  Button,
  Text,
} from "@rea-group/construct-kit-core";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  margin-top: ${spacingHelper("extraSmall")};
  width: 100%;
`;
export const StyledText = styled(Text)`
  color: ${paletteHelper("textSecondary")};
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    text-align: center;
  }
`;
