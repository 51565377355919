import { spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

export const StyledDialogContent = styled.div`
  max-width: 45rem;
`;

export const StyledBrand = styled.div`
  height: ${spacingHelper('twoExtraLarge')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacingHelper('twoExtraSmall')} 0;

  img {
    height: 100%;
  }
`;

export const AspectRatio = styled.div`
  line-height: 0;
  overflow: hidden;

  position: relative;
  width: 100%;
  padding-top: 50%; /* 2:1 Aspect Ratio */

  img {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`;

export const HeroImageContainer = styled.div`
  max-height: 288px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;
