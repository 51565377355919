import { getFeatureToggle } from '../utils/featureTogglesUtils';
import providerUtil from './providerUtil';

const {
  getClient,
  getServerUrlPart,
  getViewIdUrlPart,
  getPageParamUrlPart,
  getUniqueParamUrlPart,
  getAdType,
  getKruxParamsUrlPart,
  getReauidPart,
} = providerUtil;

const AdvertorialSas = {};

const getBaseSasURL = (adServerHost) => {
  if (getFeatureToggle('sasStaging')) {
    return 'https://reasfdc-ads.aimatch.com/';
  }

  return adServerHost != undefined
    ? `https://${adServerHost}/`
    : 'https://sasinator.realestate.com.au/';
};

const PARAMS_BLACKLIST = [
  'sect',
  'tile',
  'type',
  'dcopt',
  'areaprefix',
  'channel',
];

export const getPrebidParamUrlPart = (bidResult) => {
  if (!bidResult) {
    return '';
  }

  return Object.entries(bidResult).reduce(
    (acc, [key, value]) => `${acc}/${key}=${value}`,
    '/hb=1',
  );
};

const getSasStagingPart = () => {
  if (!getFeatureToggle('sasStaging')) {
    return '';
  }
  return '/test=1';
};

AdvertorialSas.generateAdLink = (adSpot, bidResult) => {
  const url =
    `${getBaseSasURL(adSpot.config.adServerHost)}` +
    `${getClient()}` +
    `${getServerUrlPart(adSpot.config.type)}` +
    `${getViewIdUrlPart(adSpot.viewId)}` +
    `${getPageParamUrlPart(adSpot, PARAMS_BLACKLIST)}` +
    `${getKruxParamsUrlPart(adSpot.config.abbreviatedSite)}` +
    `${getAdType(adSpot.config.type)}` +
    `${getUniqueParamUrlPart(adSpot, PARAMS_BLACKLIST)}` +
    `${getPrebidParamUrlPart(bidResult)}` +
    `${getSasStagingPart()}` +
    `${getReauidPart(adSpot.config.abbreviatedSite)}`;

  return url;
};

export default AdvertorialSas;
