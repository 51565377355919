import React, { Fragment, SyntheticEvent, useState } from "react";
import styled from "styled-components";
import {
  Button,
  EventAddLg,
  spacingHelper,
} from "@rea-group/construct-kit-core";
import { AddToCalendarDialog } from "./AddToCalendarDialog";
import { ResponsiveModal } from "./ResponsiveModal";
import type { AddToCalendarButtonModal } from "../types";
import { useEffect } from "react";

const ResponsiveText = styled.span`
  display: none;
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    display: inline-flex;
    height: calc(${spacingHelper("twoExtraLarge")} / 2);
    margin-left: ${spacingHelper("extraSmall")};
  }
`;
const ButtonWrapper = styled(Button).attrs({ variant: "naked", compact: true })`
  display: inline-flex;
`;

const ResponsiveAddToCalendar = (onClick: (e: SyntheticEvent) => void) => (
  <ButtonWrapper onClick={onClick} title="Add to calendar">
    <EventAddLg />
    <ResponsiveText>Add to calendar</ResponsiveText>
  </ButtonWrapper>
);

const AddToCalendar = ({
  address,
  eventTriggers,
  event,
  modalMountID,
  responsiveAddToCalendarButton,
  variant,
  sizeVariant,
  fullWidth,
  icon,
  iconPlacement,
  label,
  compact,
  hideLabel,
  disabled,
  dangerouslySetClassNames,
}: AddToCalendarButtonModal) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const isCurrentScreenLarge = window.innerWidth > 500;
    if (isCurrentScreenLarge !== isLargeScreen) {
      setIsLargeScreen(isCurrentScreenLarge);
    }
  }, [isLargeScreen, isOpen]);

  if (!event.eventType) {
    console.error(
      "AddToCalendar: the provided Event (Auction or Inspection or Display_Suite) object is missing the required property 'eventType'."
    );
    return null;
  }

  if (!modalMountID) {
    console.error("AddToCalendar: modalMountID prop is missing.");
    return null;
  }

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    eventTriggers.onSaveToCalendar();
    setIsOpen(true);
  };
  const onClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const ResponsiveButton = ResponsiveAddToCalendar(onClick);

  return (
    <Fragment>
      {responsiveAddToCalendarButton ? (
        ResponsiveButton
      ) : (
        <Button
          onClick={onClick}
          variant={variant}
          sizeVariant={sizeVariant}
          fullWidth={fullWidth}
          compact={compact}
          icon={icon}
          iconPlacement={iconPlacement}
          hideLabel={hideLabel}
          disabled={disabled}
          dangerouslySetClassNames={dangerouslySetClassNames}
          title="Add to calendar"
        >
          {label}
        </Button>
      )}
      <ResponsiveModal
        isOpen={isOpen}
        onClose={onClose}
        modalMountID={modalMountID}
        isLargeScreen={isLargeScreen}
      >
        <AddToCalendarDialog
          event={event}
          address={address}
          onSaveToCalendarSuccess={eventTriggers.onSaveToCalendarSuccess}
          onClose={() => setIsOpen(false)}
        />
      </ResponsiveModal>
    </Fragment>
  );
};

export default AddToCalendar;
