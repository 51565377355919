export const TIMEOUT =
  "We couldn't seem to connect, please check your network.";
export const REQUIRED_FIELDS = 'Your email and password are required.';
export const INVALID_PASSWORD =
  'Password needs at least 8 characters that includes letters and numbers.';
export const INVALID_EMAIL = 'Email address is invalid.';
export const INVALID_EMAIL_OR_PASSWORD =
  'Invalid email address or password. Please try again!';
export const EMAIL_EXISTS =
  'Email address is already registered. Please sign in.';
export const UNEXPECTED_ACCOUNT_FAILURE = 'Sign up failed.';
export const TERMINATED_TAB = 'Login tab has been closed.';
