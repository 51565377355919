import styled from "styled-components";
import { Button } from "@rea-group/construct-kit-core";
import { SyntheticEvent } from "react";

type CloseButtonProps = {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  icon: JSX.Element;
};
export const CloseButtonBase = styled(Button).attrs(
  ({ onClick, icon }: CloseButtonProps) => ({
    compact: true,
    hideLabel: true,
    icon: icon,
    onClick: onClick,
    sizeVariant: "medium",
    variant: "naked",
  })
)`
  position: absolute;
`;
