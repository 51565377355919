export type AbsoluteLinks = {
  __typename?: "AbsoluteLinks";
  href: string;
  templated: boolean;
};
export type Address = {
  __typename?: "Address";
  display: AddressDisplay;
  postcode?: string;
  state?: string;
  suburb: string;
};
export type AddressDisplay = {
  __typename?: "AddressDisplay";
  fullAddress?: string;
  geocode?: GeocodeDisplay;
  shortAddress?: string;
};

export type GeocodeDisplay = {
  __typename?: "GeocodeDisplay";
  latitude: number;
  longitude: number;
};

export type IcsType = {
  __typename?: "IcsType";
  content: string;
};
export type CalendarIntegration = {
  __typename?: "CalendarIntegration";
  google: AbsoluteLinks;
  ics: IcsType;
};
export type EventData = {
  address: Address;
  event: Event;
};

export type EventSummaryData = {
  address: Address;
  displayDate: string;
  displayTime: string;
  displayString: string;
};
export const possibleEventTypes = [
  "Inspection",
  "Auction",
  "Display_Suite",
] as const;
export type EventType = (typeof possibleEventTypes)[number];
export type Event = {
  eventType: EventType;
  calendarIntegration: CalendarIntegration;
  displayDate: string;
  displayTime: string;
};

export type AdaptedEventType = {
  displayString: string;
  fileName: string;
};
