import { getCookie } from './cookiesUtils';

const parseReauinfCookie = () => {
  const reauinfCookieValue = getCookie('reauinf');

  if (reauinfCookieValue === undefined || reauinfCookieValue === null) {
    return undefined;
  }

  try {
    return reauinfCookieValue && JSON.parse(atob(reauinfCookieValue));
  } catch {
    return undefined;
  }
};

export const isReaUserLoggedIn = () => !!getCookie('reauinf');

export const getReaUserId = () => {
  const reauinf = parseReauinfCookie();

  return reauinf && reauinf.geid;
};

export const isRcaUserLoggedIn = () => !!getCookie('lmdstok');

export const getRcaUserId = () => getCookie('rcauid');
