import {
  Button,
  Text,
  paletteHelper,
  spacingHelper,
} from '@construct-kit/core';
import styled from 'styled-components';

export const StyledDisclaimer = styled.div`
  padding: 0 ${spacingHelper('medium')} 1.25rem;
`;

export const StyledButton = styled(Button).attrs({ variant: 'link-secondary' })`
  font-size: 0.875rem;
  height: 1.25rem;
`;

export const StyledDisclaimerDetail = styled(Text).attrs({ variant: 'body02' })`
  margin: ${spacingHelper('small')} 0;
  color: ${paletteHelper('textSecondary')};
`;
