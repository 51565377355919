interface ATS {
  setAdditionalData: (arg1: { type: string; id: string }) => void;
}

declare global {
  interface Window {
    ats?: ATS;
  }
}

const passEmailToLiveRamp = (email: string): void => {
  window.ats?.setAdditionalData({
    type: 'email',
    id: email,
  });
};

export default passEmailToLiveRamp;
