import React, { FC } from "react";
import RegistrationForm from "./registration-form";
import { Address } from "@realestate.com.au/planner-add-to-calendar";
import { CrossLg, H2 } from "@rea-group/construct-kit-core";
import { Dialog, CloseButton, Header } from "./styles";
import InspectionSummary from "../InspectionSummary";
import PrivacyStatement from "./PrivacyStatement";
import WrapperWithGuideMessage from "../WrapperWithGuideMessage";
import {
  MultipleEvents,
  OnRegistrationSuccessProps,
  SingleEvent,
  isMultipleEvents,
  GeneralFeatures,
  PropertySize,
} from "../../../types";

type Props = {
  eventOrEvents: SingleEvent | MultipleEvents;
  address: Address;
  generalFeatures?: GeneralFeatures;
  propertySize?: PropertySize;
  onClose: () => void;
  templatedUrl: string;
  onRegistrationSuccess: (props: OnRegistrationSuccessProps) => void;
};

export const RegistrationDialog: FC<Props> = ({
  eventOrEvents,
  address,
  generalFeatures,
  propertySize,
  templatedUrl,
  onClose,
  onRegistrationSuccess,
  ...props
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog {...props}>
      <Header>
        <H2>Register for inspection</H2>
      </Header>
      <CloseButton onClick={handleClose} icon={<CrossLg />}>
        Close
      </CloseButton>
      <InspectionSummary
        displayDateTime={
          isMultipleEvents(eventOrEvents)
            ? undefined
            : {
                date: eventOrEvents.displayDate,
                time: eventOrEvents.displayTime,
              }
        }
        address={address}
        templatedUrl={templatedUrl}
        generalFeatures={generalFeatures}
        propertySize={propertySize}
      />
      <WrapperWithGuideMessage hideGuideMessage={true}>
        <RegistrationForm
          eventOrEvents={eventOrEvents}
          onRegistrationSuccess={onRegistrationSuccess}
        />
        <PrivacyStatement />
      </WrapperWithGuideMessage>
    </Dialog>
  );
};
