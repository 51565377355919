import { Dialog, H3 } from '@construct-kit/core';
import React, { useEffect } from 'react';
import { LeadAdTracker } from '../../types';
import {
  attemptTrack,
  fireThirdPartyTracking,
  getTrackerUrlByName,
} from '../helper/track';
import { ABTestProps, ClientStorage, TrackFunction } from '../types';
import Disclaimer from './Disclaimer';
import EnquiryForm from './EnquiryForm';
import {
  AspectRatio,
  HeroImageContainer,
  StyledBrand,
  StyledDialogContent,
} from './styles/LeadAdEnquiryDialog.style';

type LeadAdEnquiryDialogProps = {
  projectId: string;
  agencyId: string;
  creativeId: string;
  extensionCampaignId: string;
  projectTitle: string;
  highlightText: string;
  paragraphDescription: string;
  brandLogoUrl: string;
  heroImageUrl: string;
  enquirySubmissionUrl: string;
  lmsCampaignId: string;
  onClose: () => void;
  onEnquirySuccess: () => void;
  logError: (message: string, error?: Error) => void;
  prefillStorage: ClientStorage;
  impressionurl: string;
  trackers?: LeadAdTracker[];
  abTest: React.ComponentType<ABTestProps>;
  trackExperiment: TrackFunction;
  phoneRequired: boolean;
  runExperiment: boolean;
  isNMALToggleOn?: boolean;
};

const LeadAdEnquiryDialog = ({
  isNMALToggleOn,
  projectId,
  agencyId,
  creativeId,
  extensionCampaignId,
  projectTitle,
  highlightText,
  paragraphDescription,
  brandLogoUrl,
  heroImageUrl,
  enquirySubmissionUrl,
  lmsCampaignId,
  onClose,
  onEnquirySuccess,
  logError,
  prefillStorage,
  impressionurl,
  trackers,
  abTest,
  trackExperiment,
  phoneRequired,
  runExperiment,
}: LeadAdEnquiryDialogProps): JSX.Element => {
  useEffect(() => {
    !projectId && attemptTrack(logError, impressionurl, 'PATCH');
    const openLeadFormTrackerUrls =
      trackers && getTrackerUrlByName(trackers, 'openleadform');
    openLeadFormTrackerUrls?.beaconurls &&
      fireThirdPartyTracking(openLeadFormTrackerUrls.beaconurls);
  }, [impressionurl, trackers, logError, projectId]);

  return (
    <Dialog
      header={<H3>Make an enquiry</H3>}
      onClose={onClose}
      autoFullScreen={true}
      maxWidth="45rem"
    >
      <StyledDialogContent>
        <StyledBrand>
          <img src={brandLogoUrl} alt="logo" />
        </StyledBrand>
        <HeroImageContainer>
          <AspectRatio>
            <img src={heroImageUrl} alt="hero-image" />
          </AspectRatio>
        </HeroImageContainer>
        <EnquiryForm
          isNMALToggleOn={isNMALToggleOn}
          projectId={projectId}
          agencyId={agencyId}
          creativeId={creativeId}
          extensionCampaignId={extensionCampaignId}
          lmsCampaignId={lmsCampaignId}
          projectTitle={projectTitle}
          highlightText={highlightText}
          paragraphDescription={paragraphDescription}
          enquirySubmissionUrl={enquirySubmissionUrl}
          onSubmitSuccess={onEnquirySuccess}
          logError={logError}
          prefillStorage={prefillStorage}
          abTest={abTest}
          trackExperiment={trackExperiment}
          phoneRequired={phoneRequired}
          runExperiment={runExperiment}
        />
        <Disclaimer />
      </StyledDialogContent>
    </Dialog>
  );
};

export default LeadAdEnquiryDialog;
