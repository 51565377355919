import generateTargetingDefault from './generateTargeting';

export default function withAdvertTargeting(
  options,
  generateTargeting = generateTargetingDefault,
) {
  const adTargeting = generateTargeting(
    options.appName,
    options.dataLayer,
    options.logError,
  );
  const { site, ...targetingParams } = adTargeting;
  const { config, params, ...otherOptions } = options;
  return {
    config: {
      site,
      ...config,
    },
    params: {
      ...targetingParams,
      ...params,
    },
    ...otherOptions,
  };
}
