import styled from "styled-components";
import {
  Button,
  H2,
  spacingHelper,
  SuccessLg,
} from "@rea-group/construct-kit-core";
import { CloseButtonBase } from "../shared/CloseButtonBase";

export const SuccessLgGreen = styled(SuccessLg)`
  color: ${({ theme }) => theme.palette.textSuccess};
  display: inline-block;
  margin-right: ${spacingHelper("large")};
  height: ${spacingHelper("threeExtraLarge")};
  width: ${spacingHelper("threeExtraLarge")};
`;

export const AddedToPlanSuccessMessage = styled(H2)`
  display: flex;
  align-items: center;
`;

export const RegisterButton = styled(Button)`
  width: 9.25rem;
`;

export const CloseButton = styled(CloseButtonBase)`
  right: ${spacingHelper("small")};
  top: ${spacingHelper("small")};
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    right: ${spacingHelper("large")};
    top: ${spacingHelper("large")};
  }
`;
