import {
  Stack,
  SuccessFilledLg,
  paletteHelper,
  spacingHelper,
} from '@construct-kit/core';
import styled from 'styled-components';

export const SuccessDialogStack = styled(Stack).attrs({
  gap: 'small',
  inset: 'extraLarge',
})``;

export const SuccessIcon = styled(SuccessFilledLg)`
  width: ${spacingHelper('extraLarge')};
  height: ${spacingHelper('extraLarge')};
  color: ${paletteHelper('textSuccess')};
`;
