import React, { useEffect } from 'react';
import { createBrowserUser } from '@realestate.com.au/user-react';

import LockeWindow from './components/locke-window';
import LockeForm from './components/locke-form';

export type MessageData = {
  loginSuccess: true;
};

type NoPromptWindowState = 'signIn' | 'createAccount';
type InteractionType = 'successful_registration' | 'successful_sign_in';

export enum UserAction {
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
}

type Props = {
  noPromptWindowState?: NoPromptWindowState;
  customHeading?: string;
  customSubHeading?: string;
  showPrompt: boolean;
  appElement: string;
  loginUrl: string;
  signUpUrl: string;
  callbackOrigin: string;
  onSuccess?: (response: MessageData, interaction: InteractionType) => void;
  onClose?: () => void;
  onError?: (err: unknown) => void;
  className?: string;
};

const LockeSignIn = ({
  noPromptWindowState,
  customHeading = 'Sign in',
  customSubHeading,
  showPrompt,
  appElement,
  loginUrl,
  signUpUrl,
  callbackOrigin,
  onSuccess,
  onClose,
  onError,
  className,
}: Props) => {
  const handleError = (error: string) => {
    tabWindow && tabWindow.closeTab();
    if (typeof onError === 'function') {
      onError(error);
    } else {
      typeof onClose === 'function' && onClose();
    }
  };

  const handleMessage = (data: MessageData) => {
    const browserUser = createBrowserUser();
    const interactionType: InteractionType = browserUser.isNewUser
      ? 'successful_registration'
      : 'successful_sign_in';
    handleModalClose();
    typeof onSuccess === 'function' && onSuccess(data, interactionType);
  };

  const tabWindow: LockeWindow = new LockeWindow({
    callbackOrigin: callbackOrigin,
    handleMessage: handleMessage,
    handleError: handleError,
  });

  useEffect(() => {
    if (!showPrompt) {
      const url =
        noPromptWindowState === 'createAccount' ? signUpUrl : loginUrl;
      tabWindow && tabWindow.openTab(url);
    }
  }, [tabWindow, noPromptWindowState]);

  const handleModalClose = () => {
    tabWindow && tabWindow.closeTab();
    typeof onClose === 'function' && onClose();
  };

  const handleModalButtonsClick = (buttonName: UserAction) => {
    const url = buttonName === UserAction.SignUp ? signUpUrl : loginUrl;
    tabWindow && tabWindow.openTab(url);
  };

  return (
    <div className={className || ''}>
      {showPrompt && (
        <LockeForm
          heading={customHeading}
          subHeading={customSubHeading}
          appElement={appElement}
          onClose={handleModalClose}
          handleModalButtonsClick={handleModalButtonsClick}
        />
      )}
    </div>
  );
};

export default LockeSignIn;
