import { IS_CLIENT } from '../../../utils/browserUtils';

const windowHeight = !IS_CLIENT
  ? 0
  : window.innerHeight || document.documentElement.clientHeight;

function throttle(callback, limit) {
  let wait = false; // Initially, we're not waiting
  return () => {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(() => {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
}

function VideoAdPlaybackManager(
  video,
  videoContainer,
  playButton,
  soundControl,
  posterImage,
  throttleLimit = 250,
) {
  let hasVideoStarted = false;
  let scrolledOutOfViewPort = false;
  let finished = false;
  let isBrowserAutoPlayDisabled = false;

  function hidePosterAndShowVideo() {
    posterImage.style.display = 'none';
    videoContainer.style.visibility = 'visible';
    playButton.style.visibility = 'hidden';
  }

  function prepareViewForPlayback(muted) {
    if (!hasVideoStarted) {
      if (muted !== undefined) {
        video.muted = muted;
      }
      hidePosterAndShowVideo();

      hasVideoStarted = true;
    }
  }

  function prepareVideoWhenPlay() {
    // This is here to handle replaying video in fullscreen as we need to track the play
    // again and display the video
    video.addEventListener('play', () => prepareViewForPlayback());
  }

  function isElementVisible(el, winHeight) {
    try {
      const rect = el.getBoundingClientRect();

      // Return false if it's not in the viewport

      return rect.bottom > 0 && rect.top <= winHeight;
    } catch {
      return false;
    }
  }

  function isAutoPlayEnabled() {
    if (finished || isBrowserAutoPlayDisabled) {
      return false;
    }

    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    return connection !== undefined && connection.type === 'wifi';
  }

  function hideSoundControl() {
    soundControl.style.display = 'none';
  }

  function showSoundControl() {
    soundControl.style.display = '';
  }

  function isPlaying() {
    return hasVideoStarted && !video.paused;
  }

  function shouldPause(videoVisible, posterImageVisible) {
    return isPlaying() && !videoVisible && !posterImageVisible;
  }

  function playVideo(muted) {
    prepareViewForPlayback(muted);
    video.play();
  }

  function enableControlsHideSoundControlAndTurnOffAutoPlay() {
    video.controls = true;
    hideSoundControl();
    isBrowserAutoPlayDisabled = true;
  }

  function autoplayVideo() {
    if (!hasVideoStarted) {
      video.muted = true;
    }

    const playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          prepareViewForPlayback(true);
        })
        .catch(enableControlsHideSoundControlAndTurnOffAutoPlay);
    } else {
      // we don't know if video autoplayed or not so must pause the video just in case
      video.pause();
      enableControlsHideSoundControlAndTurnOffAutoPlay();
    }
  }

  function shouldAutoplay(videoVisible, posterImageVisible, isAutoplayEnabled) {
    if (!isAutoplayEnabled) {
      return false;
    }

    // plays video when video is not playing and
    // 1. poster image is visible and video never ended before
    // 2. poster image is visible and video played before but is scrolled out of and into view again
    // 3. video is visible and paused
    if (!isPlaying()) {
      if (!videoVisible && !posterImageVisible) {
        scrolledOutOfViewPort = true;
        return false;
      }
      if (posterImageVisible && !video.ended) {
        video.scrolledOutOfViewPort = false;
        return true;
      }
      if (posterImageVisible && video.ended && scrolledOutOfViewPort) {
        return true;
      }
      if (videoVisible && video.paused) {
        return true;
      }
    }
    return false;
  }

  function autoplayVideoWhenScrolledIntoViewPort() {
    const posterImageVisible = isElementVisible(posterImage, windowHeight);
    const videoVisible = isElementVisible(video, windowHeight);
    const isAutoplayEnabled = isAutoPlayEnabled();

    if (isAutoplayEnabled) {
      showSoundControl();
    } else {
      hideSoundControl();
    }

    video.controls = !isAutoplayEnabled;

    if (shouldAutoplay(videoVisible, posterImageVisible, isAutoplayEnabled)) {
      autoplayVideo();
    } else if (shouldPause(videoVisible, posterImageVisible)) {
      video.pause();
    }
  }

  function addListenerToAutoPlay() {
    window.addEventListener(
      'scroll',
      throttle(autoplayVideoWhenScrolledIntoViewPort, throttleLimit),
      false,
    );
  }

  function addClickListenerToPlay() {
    playButton.addEventListener('click', () => {
      playVideo(false);
    });
  }

  function resetWhenPlaybackEnds() {
    video.addEventListener('ended', () => {
      finished = true;
      scrolledOutOfViewPort = false;
      hasVideoStarted = false;
      hideSoundControl();
      // Video will loop in IE11 if the video is not paused manually
      // when we set the currentTime to 0.1.
      video.pause();
      // Set currentTime to 0.1 instead of 0 to fix a weird issue in Safari in iOS9 which causes
      // the ended event to be fired twice the first time and then it doesn't fire after that. https://bugs.webkit.org/show_bug.cgi?id=150348
      video.currentTime = 0.1;
      video.controls = true;
    });
  }

  function toggleSoundControlToShowSoundOnIcon() {
    soundControl.className = soundControl.className.replace(
      ' native-video-sound-control-off',
      '',
    );
  }

  function toggleSoundControlToShowSoundOffIcon() {
    soundControl.className += ' native-video-sound-control-off';
  }

  function addClickListenerToSoundControl() {
    soundControl.addEventListener('click', () => {
      video.muted = !video.muted;

      if (video.muted) {
        toggleSoundControlToShowSoundOnIcon();
      } else {
        toggleSoundControlToShowSoundOffIcon();
      }
    });
  }

  prepareVideoWhenPlay();

  autoplayVideoWhenScrolledIntoViewPort();
  addListenerToAutoPlay();

  addClickListenerToPlay();

  resetWhenPlaybackEnds();

  addClickListenerToSoundControl();
}

export default VideoAdPlaybackManager;
