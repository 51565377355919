import loadSafeframeAssetsImpl from './loadSafeframeAssets';
import { SAFEFRAME_ASSETS_PATH } from '../../rui-env-variables';
import { log } from '../../utils/loggingUtil';
import { IS_CLIENT } from '../../utils/browserUtils';

const receiveMessage = (event) => {
  let data;
  try {
    data = JSON.parse(event.data);
  } catch {
    return;
  }

  if (data?.type !== 'resizeAd') {
    return;
  }
  const { creativeWidth, creativeHeight, containerId, adPosition } = data;
  const adIFrame = document.getElementById(containerId);
  const safeFrameDiv = document.getElementById(`sf_pos_rel_el_${containerId}`);

  if (!adIFrame || !safeFrameDiv) {
    return;
  }

  adIFrame.style.height = `${creativeHeight}px`;
  safeFrameDiv.style.height = `${creativeHeight}px`;
  adIFrame.style.width = `${creativeWidth}px`;
  safeFrameDiv.style.width = `${creativeWidth}px`;

  log('adPosition:', adPosition);
  log('adSizeWidth:', creativeWidth);
  log('adSizeHeihgt:', creativeHeight);
  log('adId:', containerId);
};

IS_CLIENT && window.addEventListener('message', receiveMessage, false);

const getDebugParams = (currentWindow) => {
  const params = new URLSearchParams(currentWindow.location.search);

  const allowedParams = ['rui_debug'];
  const newParams = new URLSearchParams();
  params.forEach((value, key) => {
    if (allowedParams.includes(key)) {
      newParams.append(key, value);
    }
  });

  return newParams;
};

const createPrebidSafeframe = (src, adSize) => {
  const [width, height] = adSize.split('x');
  const iframeEl = document.createElement('iframe');
  iframeEl.src = src;
  iframeEl.name = 'prebidSafeframe';
  iframeEl.width = width;
  iframeEl.height = height;
  iframeEl.scrolling = 'no';
  iframeEl.marginHeight = '0';
  iframeEl.marginWidth = '0';
  return iframeEl.outerHTML;
};

const generateSafeframeAd = (
  adSpot,
  loadSafeframeAssets = loadSafeframeAssetsImpl,
  currentWindow = window,
  safeframeCdn = SAFEFRAME_ASSETS_PATH,
) => {
  const {
    element: container,
    guid: containerId,
    loadCompleteCallback,
    resizeCompleteCallback,
    nativeContent,
    prebidSize,
    allowSandboxTopNavByUserActivation,
  } = adSpot;

  adSpot.insertAdvertisementHeading();
  const availableSizes =
    prebidSize || adSpot.getAvailableSizes().replace(',native', '');
  const initialSize = prebidSize || adSpot.getInitialSize(availableSizes);
  const isMultiSize = adSpot.isMultiSize(availableSizes);
  const autoHide = String(adSpot.config.autoHide) === 'true';
  container.id = containerId;

  return loadSafeframeAssets().then(() => {
    let html;
    const debugParams = getDebugParams(currentWindow);
    if (nativeContent !== undefined) {
      html = nativeContent;
    } else if (adSpot.prebidAdId) {
      const params = new URLSearchParams({
        adId: adSpot.prebidAdId,
        mediaType: adSpot.mediaType,
        ...(adSpot.isDemoApp ? { isDemoApp: adSpot.isDemoApp } : {}),
        ...Object.fromEntries(debugParams),
      });

      html = createPrebidSafeframe(
        `${safeframeCdn}/html/prebidSafeframe.html?${params.toString()}`,
        initialSize,
      );
    } else {
      const adLink = adSpot.getLink();
      html = `<script src="${adLink}" type="text/javascript" id="adScript"></script>`;
    }

    if (!currentWindow?.$sf?.host) {
      return;
    }

    if (
      !currentWindow.$sf.host.Config() ||
      !currentWindow.$sf.host.Config().renderFile?.includes(safeframeCdn)
    ) {
      let renderFile = `${safeframeCdn}/html/r.html${debugParams.size > 0 ? `?${debugParams.toString()}` : ''}`;
      log(`Update safeframe renderFile: ${renderFile}`);
      new currentWindow.$sf.host.Config({
        renderFile,
        positions: currentWindow.$sf.host.Config()?.positions,
      });
    }

    new currentWindow.$sf.host.PosConfig(
      {
        id: containerId,
        size: initialSize,
        tgt: '_blank',
        supports: {
          'exp-push': isMultiSize,
        },
        autoHide,
        loadCompleteCallback: (adProperties) => {
          if (typeof loadCompleteCallback === 'function') {
            loadCompleteCallback(adProperties, adProperties.isAdLoaded);
          }
        },
        resizeCompleteCallback: (adProperties) => {
          if (typeof resizeCompleteCallback === 'function') {
            resizeCompleteCallback(adProperties, adProperties.isAdLoaded);
          }
        },
        safeframeRenderDomContentLoadedCallback: () => {},
        postbidBidCompletedCallback: () => {},
        isPrebid: !!prebidSize,
        allowSandboxTopNavByUserActivation,
      },
      containerId,
    );

    const posMeta = new currentWindow.$sf.host.PosMeta({
      isMultiSize,
      currentSize: initialSize,
      availableSizes,
      clientHeight:
        currentWindow.innerHeight || currentWindow.document.body.clientHeight,
      containerId,
      adPosition: adSpot.params.pos,
    });

    const position = new currentWindow.$sf.host.Position(
      containerId,
      html,
      posMeta,
    );

    currentWindow.$sf.host.render(position);

    return Promise.resolve('Safeframe Ad loaded');
  });
};

export default generateSafeframeAd;
