import {
  Alert,
  Label,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
} from '@construct-kit/core';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import submitEnquiry from '../helper/submitEnquiry';
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../helper/validation';
import {
  ABTestProps,
  ClientStorage,
  MaybeErrorMessage,
  PrefillData,
  TrackFunction,
} from '../types';
import {
  bedroomConfigOptions,
  budgetConfigOptions,
  buyTimePeriod,
  LeadFormAdditionalFieldExperimentConfig as ExperimentConfig,
  LeadFormAdditionalFieldEventKey,
} from './constants';
import {
  EnquiryButton,
  StyledBuyTime,
  StyledEnquiryForm,
  StyledProjectTitle,
  StyledText,
} from './styles/EnquiryForm.style';

export interface EnquiryFormProps {
  projectId: string;
  agencyId: string;
  creativeId: string;
  extensionCampaignId: string;
  projectTitle: string;
  highlightText: string;
  paragraphDescription: string;
  enquirySubmissionUrl: string;
  lmsCampaignId: string;
  onSubmitSuccess: () => void;
  logError: (message: string, error?: Error) => void;
  prefillStorage: ClientStorage;
  abTest: React.ComponentType<ABTestProps>;
  trackExperiment: TrackFunction;
  phoneRequired: boolean;
  runExperiment: boolean;
  isNMALToggleOn?: boolean;
}

const EnquiryForm = ({
  isNMALToggleOn,
  projectId,
  agencyId,
  creativeId,
  extensionCampaignId,
  projectTitle,
  highlightText,
  paragraphDescription,
  enquirySubmissionUrl,
  lmsCampaignId,
  logError,
  onSubmitSuccess,
  prefillStorage,
  abTest: ABTest,
  trackExperiment,
  phoneRequired,
  runExperiment,
}: EnquiryFormProps): JSX.Element => {
  const [nameErrorMessage, setNameErrorMessage] = useState<MaybeErrorMessage>();
  const [emailErrorMessage, setEmailErrorMessage] =
    useState<MaybeErrorMessage>();
  const [phoneErrorMessage, setPhoneErrorMessage] =
    useState<MaybeErrorMessage>();

  const [shouldDisableSubmitButton, setShouldDisableSubmitButton] =
    useState(false);
  const [shouldShowNetworkErrorMessage, setShouldShowNetworkErrorMessage] =
    useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bedroomConfig, setBedroomConfig] = useState('');
  const [budgetConfig, setBudgetConfig] = useState('');
  const [buyTimePeriodConfig, setBuyTimePeriodConfig] = useState('');

  useEffect(() => {
    const performPrefill = (): void => {
      const maybePrefillData = prefillStorage.getPrefillData();

      if (maybePrefillData == undefined) {
        return;
      }

      const {
        name: maybePrefillName,
        email: maybePrefillEmail,
        phoneNumber: maybePrefillPhoneNumber,
        bedroomConfig: maybeBedroomPrefillConfig,
        budgetConfig: maybeBudgetPrefillConfig,
        buyTimePeriodConfig: maybeBuyTimePeriodPrefillConfig,
      } = maybePrefillData;

      maybePrefillName != undefined && setName(maybePrefillName);
      maybePrefillEmail != undefined && setEmail(maybePrefillEmail);
      maybePrefillPhoneNumber != undefined &&
        setPhoneNumber(maybePrefillPhoneNumber);
      maybeBedroomPrefillConfig != undefined &&
        setBedroomConfig(maybeBedroomPrefillConfig);
      maybeBudgetPrefillConfig != undefined &&
        setBudgetConfig(maybeBudgetPrefillConfig);
      maybeBuyTimePeriodPrefillConfig != undefined &&
        setBuyTimePeriodConfig(maybeBuyTimePeriodPrefillConfig);
    };
    performPrefill();
  }, [prefillStorage]);

  const handleNameBlur = (): void => {
    setNameErrorMessage(validateName(name));
  };

  const handleEmailBlur = (): void => {
    setEmailErrorMessage(validateEmail(email));
  };

  const handlePhoneBlur = (): void => {
    setPhoneErrorMessage(validatePhoneNumber(phoneNumber, phoneRequired));
  };

  const handleSubmitResponse = async (response: Response): Promise<void> => {
    if (response.ok) {
      onSubmitSuccess();
      if (runExperiment) {
        trackExperimentByMetric();
      }
    } else {
      const responseText = await response.text();
      throw new Error(responseText);
    }
  };

  const trackExperimentByMetric = (): void => {
    const bedroomKey =
      bedroomConfig !== ''
        ? LeadFormAdditionalFieldEventKey.bedroomKey
        : undefined;
    const budgetKey =
      budgetConfig !== ''
        ? LeadFormAdditionalFieldEventKey.budgetKey
        : undefined;
    const buyTimePeriodKey =
      buyTimePeriodConfig !== ''
        ? LeadFormAdditionalFieldEventKey.buyTimePeriodKey
        : undefined;

    [ExperimentConfig.eventKey, bedroomKey, budgetKey, buyTimePeriodKey]
      .filter((key): key is string => key != undefined)
      .forEach((metricKey) => trackExperiment(metricKey));
  };

  const handleSubmit = async (
    e: SyntheticEvent<EventTarget>,
  ): Promise<void> => {
    e.preventDefault();
    const maybeNameErrorMessage = validateName(name);
    const maybeEmailErrorMessage = validateEmail(email);
    const maybePhoneNumberErrorMessage = validatePhoneNumber(
      phoneNumber,
      phoneRequired,
    );
    const isReadyToSubmit = [
      maybeNameErrorMessage,
      maybeEmailErrorMessage,
      maybePhoneNumberErrorMessage,
    ].every((message) => message === undefined);

    setNameErrorMessage(maybeNameErrorMessage);
    setEmailErrorMessage(maybeEmailErrorMessage);
    setPhoneErrorMessage(maybePhoneNumberErrorMessage);
    setShouldShowNetworkErrorMessage(false);

    if (isReadyToSubmit) {
      setShouldDisableSubmitButton(true);
      try {
        const prefillDataToSave: PrefillData = {
          name,
          email,
          phoneNumber,
          bedroomConfig,
          budgetConfig,
          buyTimePeriodConfig,
          updatedAt: Date.now(),
        };
        prefillStorage.setPrefillData(prefillDataToSave);
        const response = await submitEnquiry(
          {
            projectId,
            agencyId,
            creativeId,
            extensionCampaignId,
            name,
            email,
            phoneNumber,
            bedroomConfig,
            budgetConfig,
            buyTimePeriodConfig,
            lmsCampaignId,
            enquirySubmissionUrl,
          },
          isNMALToggleOn,
        );

        await handleSubmitResponse(response);
      } catch (err) {
        setShouldShowNetworkErrorMessage(true);
        setShouldDisableSubmitButton(false);

        if (!(err instanceof Error)) {
          return;
        }

        logError(
          `Sending enquiry form failed: lmsCampaignId: ${lmsCampaignId}, enquirySubmissionUrl: ${enquirySubmissionUrl}, projectId: ${
            projectId ?? ''
          }`,
          err,
        );
      }
    }
  };

  return (
    <StyledEnquiryForm noValidate={true} onSubmit={handleSubmit}>
      <StyledProjectTitle>{projectTitle}</StyledProjectTitle>
      <Text variant="subtitle01">{highlightText}</Text>
      <StyledText>{paragraphDescription}</StyledText>
      <Stack gap="medium">
        <TextInput
          label="Name (required)"
          id="name"
          iconLeft={false}
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="name"
          errorMessage={nameErrorMessage}
          onBlur={handleNameBlur}
        />
        <TextInput
          label="Email (required)"
          id="email"
          type="email"
          iconLeft={false}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          onBlur={handleEmailBlur}
          errorMessage={emailErrorMessage}
        />
        <TextInput
          label={`Phone${phoneRequired ? ' (required)' : ''}`}
          id="phone"
          type="tel"
          iconLeft={false}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          autoComplete="tel"
          onBlur={handlePhoneBlur}
          errorMessage={phoneErrorMessage}
        />
        {runExperiment ? (
          <ABTest experimentConfig={ExperimentConfig}>
            {(variation) =>
              variation === 'variationA' ? (
                <>
                  <Select
                    label="How many bedrooms would you like?"
                    options={bedroomConfigOptions}
                    selectedOption={
                      bedroomConfigOptions.find(
                        (option) => option.value === bedroomConfig,
                      ) || null
                    }
                    onSelectedOptionChange={(option) => {
                      option && setBedroomConfig(option.value);
                    }}
                    placeholder="Select"
                  />
                  <Select
                    label="What's your budget?"
                    options={budgetConfigOptions}
                    selectedOption={
                      budgetConfigOptions.find(
                        (option) => option.value === budgetConfig,
                      ) || null
                    }
                    onSelectedOptionChange={(option) => {
                      option && setBudgetConfig(option.value);
                    }}
                    placeholder="Select"
                  />
                  <Stack gap="extraSmall">
                    <Label>When do you want to buy?</Label>
                    <StyledBuyTime>
                      {buyTimePeriod.map((item) => (
                        <Radio
                          name="buy-time-period"
                          label={item}
                          value={item}
                          key={item}
                          onChange={(event) =>
                            setBuyTimePeriodConfig(event.target.value)
                          }
                          checked={buyTimePeriodConfig === item}
                        />
                      ))}
                    </StyledBuyTime>
                  </Stack>
                </>
              ) : null
            }
          </ABTest>
        ) : null}
        {shouldShowNetworkErrorMessage && (
          <Alert variant="warning" role="alert">
            There was an error sending your message. Please try again.
          </Alert>
        )}
        <EnquiryButton type="submit" disabled={shouldDisableSubmitButton}>
          Send enquiry
        </EnquiryButton>
      </Stack>
    </StyledEnquiryForm>
  );
};

export default EnquiryForm;
