import AdvertorialAdSpot from './adSpot/AdvertorialAdSpot';
import AdvertorialDevTools from './rui-advertorial-devtools';
import { log, logError } from './utils/loggingUtil';
import requestAd, { adTypes } from './generator/requestAd';
import renderAdGenerator from './generator/renderAd';
import generateJavaScriptAd from './generator/generateJavaScriptAd';
import FetchError from './utils/customErrorType';

export const generateCreateAd = (Advertorial) => (options) => {
  const adSpot = new AdvertorialAdSpot(options);

  if (AdvertorialDevTools.isDisabled(adSpot)) {
    log('AdSpot disabled by AdvertorialDevTools', adSpot);
    return;
  }

  adSpot.reset();

  if (Advertorial) {
    const adSpotWithDebugging = AdvertorialDevTools.addDebuggingParams(adSpot);

    Advertorial.allAds.push(adSpotWithDebugging);
  }

  let generateAdPromise;
  if (adSpot.config.type === 'native') {
    generateAdPromise = adSpot.config.shouldRenderAd
      ? requestAd(adSpot).then(renderAdGenerator(adSpot))
      : requestAd(adSpot).then((response) => {
          adSpot.loadCompleteCallback?.(
            response.content,
            response.adType !== adTypes.NO_AD,
          );
        });
  } else {
    generateAdPromise = generateJavaScriptAd(
      adSpot.getLink(),
      adSpot.element,
      adSpot.loadCompleteCallback,
    );
  }

  generateAdPromise
    .then((msg) => {
      log('Loaded Ad Success:', msg);
    })
    .catch((err) => {
      if (typeof adSpot.loadCompleteCallback === 'function') {
        adSpot.loadCompleteCallback(undefined, false);
      }
      logError('Loaded Ad Error', err);
      if (!(err instanceof FetchError)) {
        adSpot.logError?.('Ad load error', err);
      }
    });
};

const createAd = generateCreateAd();

export default createAd;
