import { logWarning } from '../utils/loggingUtil';
import AdvertorialSas from './sasProvider';
import AdvertorialGlobal from './sasGlobalProvider';

const AdvertorialAdProvider = {};

AdvertorialAdProvider.PROVIDERS = {
  SAS: AdvertorialSas,
  GLOBAL: AdvertorialGlobal,
};

AdvertorialAdProvider.DEFAULT_PROVIDER = AdvertorialSas;

// TODO: need to find a place for this other than window
AdvertorialAdProvider.getCode = () => window.ruiAdProvider || null;

AdvertorialAdProvider.getCurrent = () =>
  AdvertorialAdProvider.PROVIDERS[AdvertorialAdProvider.getCode()] ||
  AdvertorialAdProvider.DEFAULT_PROVIDER;

AdvertorialAdProvider.isDefault = () => {
  const isDefault =
    AdvertorialAdProvider.getCurrent() ===
    AdvertorialAdProvider.DEFAULT_PROVIDER;

  if (!isDefault) {
    logWarning(
      `Not using default ad provider. Using ${AdvertorialAdProvider.getCode()}.`,
    );
  }

  return isDefault;
};

export default AdvertorialAdProvider;
