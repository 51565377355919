import AdvertorialAdSpot from './adSpot/AdvertorialAdSpot';
import renderAdGenerator from './generator/renderAd';

const renderAd = (adContent, container) => {
  return new Promise((resolve) => {
    const adSpot = new AdvertorialAdSpot({
      config:
        adContent.adType === 'html'
          ? {
              sz: adContent.sz,
            }
          : {},
      container,
      loadCompleteCallback: () => {
        resolve();
      },
    });

    if (adContent.adType === 'html') {
      delete adContent.sz;
    }

    renderAdGenerator(adSpot)(adContent);
  });
};

export default renderAd;
