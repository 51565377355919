import React from "react";
import { Text, spacingHelper } from "@rea-group/construct-kit-core";
import styled from "styled-components";

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingHelper("twoExtraSmall")};
`;

type Props = {
  icon: React.ReactNode;
  label: string;
  displayValue?: string | number;
  showDisplayValue?: boolean;
};

const Feature = ({
  icon,
  label,
  displayValue,
  showDisplayValue = true,
}: Props) => {
  const showFeature = Boolean(displayValue);

  if (showFeature) {
    return (
      <FeatureContainer aria-label={label}>
        {icon}
        {showDisplayValue && <Text>{displayValue}</Text>}
      </FeatureContainer>
    );
  }
};

export default Feature;
