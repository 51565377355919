import React, { FC } from "react";
import styled from "styled-components";
import { Stack, Text, paletteHelper } from "@rea-group/construct-kit-core";

type Props = {
  children: React.ReactNode;
  hideGuideMessage?: boolean;
};

const StyledStack = styled(Stack).attrs({
  gap: "1rem",
  inset: "1rem",
})`
  background-color: ${paletteHelper("backgroundSecondaryBase")};
  border-radius: ${({ theme }) => theme.border.radius.large};
`;

const WrapperWithGuideMessage: FC<Props> = ({ children, hideGuideMessage }) => {
  if (hideGuideMessage) return <>{children}</>;

  return (
    <StyledStack>
      <Text variant="title02">Let the agent know you’re coming</Text>
      {children}
    </StyledStack>
  );
};

export default WrapperWithGuideMessage;
