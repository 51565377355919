import Advertorial from '@media/rui-advertorial';
import { cachedAdSizes } from './pinAds';

interface AdUnit {
  position: string;
  slot: {
    name: string;
    sizes: { w: number; h: number }[];
  };
}

const startPrebidWhenIframeLoaded = (
  prebidConfig: AdUnit[],
  appName: string,
  dataLayer: Record<string, unknown>,
): void => {
  const config = prebidConfig.map((adUnit) => {
    const cachedSize = cachedAdSizes[adUnit.position];
    if (adUnit.position.includes('strip') && cachedSize) {
      if (cachedSize === 'native') {
        return {
          ...adUnit,
          slot: { ...adUnit.slot, sizes: [{ w: 0, h: 0 }] },
        };
      }

      const matchedSizes = adUnit.slot.sizes.filter(
        (size) => size.h === cachedSize.h,
      );

      if (matchedSizes.length === 0) {
        return {
          ...adUnit,
          slot: { ...adUnit.slot, sizes: [{ w: 0, h: 0 }] },
        };
      }

      const closestSize =
        matchedSizes.length === 1
          ? matchedSizes[0]
          : matchedSizes.reduce(
              (prev, curr) =>
                Math.abs(curr.w - cachedSize.w) <
                Math.abs(prev.w - cachedSize.w)
                  ? curr
                  : prev,
              matchedSizes[0],
            );

      return { ...adUnit, slot: { ...adUnit.slot, sizes: [closestSize] } };
    }

    return adUnit;
  });

  Advertorial.startPrebidBidding(config, {
    appName,
    dataLayer,
  });
};

export default startPrebidWhenIframeLoaded;
