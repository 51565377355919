import {
  arePersonalisedAdsAllowed,
  outputErrorToConsole,
  allowPersonalisedAdsCache,
  cacheExistsAndNotExpired,
  convertResponseToJson,
  setAllowPersonalisedAdsCache,
  removeTokenIfInvalid,
} from './allowPersonalisedAdsUtil';
import { isRcaUserLoggedIn, getRcaUserId } from './utils/auth';

const commercialPreferenceApiUrl = process.env.COMMERCIAL_PREFERENCE_API_URL;
const privacySettingsQuery = `query CommunicationPreferences {
    privacySettings {
      identity
      enabled
    }
  }`;

const fetchCommercialPreference = () =>
  fetch(commercialPreferenceApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ query: privacySettingsQuery }),
  });

const validateResponse = (response) => {
  if (
    response.errors &&
    response.errors[0].extensions?.response?.status === 401
  ) {
    throw Error(response.errors[0].extensions.response.statusText);
  }
  return response?.data?.privacySettings;
};

const fetchRcaAllowPersonalisedAds = () =>
  fetchCommercialPreference()
    .then(convertResponseToJson)
    .then(validateResponse)
    .then(arePersonalisedAdsAllowed);

let isfetching = false;
const allowPersonalisedAdsForLoggedInRcaUser = () => {
  if (!isRcaUserLoggedIn()) {
    return true;
  }

  const cache = allowPersonalisedAdsCache();

  if (cacheExistsAndNotExpired(cache)) {
    return cache.value;
  }

  if (!isfetching) {
    isfetching = true;
    fetchRcaAllowPersonalisedAds()
      .then((myAccountAllowPersonalisedAds) =>
        setAllowPersonalisedAdsCache(
          myAccountAllowPersonalisedAds,
          getRcaUserId(),
        ),
      )
      .catch(outputErrorToConsole)
      .finally(() => {
        isfetching = false;
      });
  }
  return false;
};

const allowRcaPersonalisedAds = () => {
  removeTokenIfInvalid(isRcaUserLoggedIn(), getRcaUserId());
  return allowPersonalisedAdsForLoggedInRcaUser();
};

export default allowRcaPersonalisedAds;
