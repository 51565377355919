import { getCache, putCache } from './native/nativeAdCache';
import { waitForPrebidIfRequired } from './prebid';
import { checkIfEmptyAd } from '../adSpot/adSpotUtil';
import FetchError from '../utils/customErrorType';
import { getPrebidParamUrlPart } from '../provider/sasProvider';

const contentTypes = {
  JSON: 'application/json',
  HTML: 'text/html',
  HTML_UTF_8: 'text/html; charset=utf-8',
};

export const adTypes = {
  NATIVE: 'native',
  HTML: 'html',
  PREBID: 'prebid',
  PREBID_PASSBACK: 'prebid-passback',
  NO_AD: 'no-ad',
};

const racePromiseWithTimeout = (adPromise, adSpot) => {
  if (adSpot.nativeTimeout) {
    const maxTimeout = new Promise((_, reject) => {
      setTimeout(() => {
        reject(
          new Error(
            'Do not receive ad server response within timeout. Aborting native ad render!',
          ),
        );
      }, adSpot.nativeTimeout);
    });
    return Promise.race([maxTimeout, adPromise]);
  }
  return adPromise;
};

const cacheContent = (nativeAdCache, cacheKey) => (advertorialResponse) => {
  if (cacheKey) {
    nativeAdCache.putCache(cacheKey, advertorialResponse);
  }
  return advertorialResponse;
};

const isTrustedHtmlTagInContent = (adSpot, adContent) => {
  const { viewId, random } = adSpot;
  const isTrustedHtmlRegEx = /<!-- is-trusted-html (\d+) -->/;
  // only get the first match to protect against ads brute forcing a match
  const firstMatch = adContent.match(isTrustedHtmlRegEx);
  return firstMatch && firstMatch[1] === `${viewId}${random}`;
};

const isTrustedCeltraHtmlTagInContent = (adSpot, adContent) => {
  const { viewId, random } = adSpot;
  const isTrustedHtmlRegEx = /<!-- is-trusted-celtra-html (\d+) -->/;
  // only get the first match to protect against ads brute forcing a match
  const firstMatch = adContent.match(isTrustedHtmlRegEx);
  return firstMatch && firstMatch[1] === `${viewId}${random}`;
};

const getHtmlAdResponse = (contentType, adSpot) => (adContent) => {
  const isEmptyAd = checkIfEmptyAd('html', adContent);

  if (isEmptyAd) {
    return { contentType, adType: adTypes.NO_AD };
  }

  const isTrustedHtml = isTrustedHtmlTagInContent(adSpot, adContent);

  const isTrustedCeltraHtml = isTrustedCeltraHtmlTagInContent(
    adSpot,
    adContent,
  );

  return {
    contentType,
    adType: adTypes.HTML,
    content: adContent,
    isTrustedHtml,
    isTrustedCeltraHtml,
  };
};

const getJsonAdResponse = (contentType, adSpot, nativeAdCache) => (content) => {
  if (content.adtype === 'prebid') {
    return { contentType, adType: adTypes.PREBID, content };
  }
  return cacheContent(
    nativeAdCache,
    adSpot.cacheKey,
  )({
    contentType,
    adType: adTypes.NATIVE,
    content,
  });
};

const handleResponse = (adSpot, nativeAdCache) => (response) => {
  if (!response.ok) {
    return Promise.reject(new Error('Response code is not 200'));
  }

  const contentType = response.headers.get('Content-Type').toLowerCase();

  switch (contentType) {
    case contentTypes.JSON:
      return response
        .json()
        .then(getJsonAdResponse(contentType, adSpot, nativeAdCache));
    case contentTypes.HTML:
    case contentTypes.HTML_UTF_8:
      return response.text().then(getHtmlAdResponse(contentType, adSpot));
    default:
      return Promise.reject(
        new Error(`Response type not expected: ${contentType}`),
      );
  }
};

const runPrebidIfRequired = (adSpot) =>
  waitForPrebidIfRequired(adSpot).then(({ bidResult, isDemoApp }) => {
    adSpot.isDemoApp = isDemoApp;

    return bidResult;
  });

const makeAdCall = (url) =>
  fetch(url, { credentials: 'include' }).catch(() => {
    throw new FetchError('Fetch network error');
  });

const callPassbackUrl = (prebidPromise, passbackurl) =>
  prebidPromise.then((bidResult) =>
    makeAdCall(`${passbackurl}${getPrebidParamUrlPart(bidResult)}`),
  );

// This function must always return some kind of ad response
const maybeCallPassbackUrl = (prebidPromise) => (response) => {
  const contentType = response.headers.get('Content-Type').toLowerCase();

  if (contentType === contentTypes.JSON) {
    const clonedResponse = response.clone();

    return response.json().then((content) => {
      const { adtype, passbackurl } = content;

      if (adtype === adTypes.PREBID_PASSBACK) {
        return callPassbackUrl(prebidPromise, passbackurl);
      }

      return clonedResponse;
    });
  }

  return response;
};

const requestAd = (adSpot, nativeAdCache = { getCache, putCache }) => {
  const cachedResponse =
    adSpot.cacheKey && nativeAdCache.getCache(adSpot.cacheKey);

  if (cachedResponse) {
    return Promise.resolve(cachedResponse);
  }

  const requestAdWithPrebid = () => {
    const prebidPromise = runPrebidIfRequired(adSpot);

    // Discussed with @Lewis Lee, only strip ads will use prebidPassback
    if (adSpot.isPrebid && adSpot.isStripAd()) {
      return makeAdCall(adSpot.getLink()).then(
        maybeCallPassbackUrl(prebidPromise),
      );
    }

    return prebidPromise.then((bidResult) =>
      makeAdCall(adSpot.getLink(bidResult)),
    );
  };

  return racePromiseWithTimeout(requestAdWithPrebid(), adSpot).then(
    handleResponse(adSpot, nativeAdCache),
  );
};

export default requestAd;
