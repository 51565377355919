import styled from "styled-components";
import {
  Button,
  H2,
  Link,
  paletteHelper,
  spacingHelper,
  SuccessLg,
} from "@rea-group/construct-kit-core";
import DialogBase from "../DialogBase";
import { CloseButtonBase } from "../CloseButtonBase";

const DIALOG_WIDTH = "750px";
const DIALOG_HEIGHT = "342px";

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5rem;
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    padding-top: unset;
  }
`;

export const Title = styled(H2)`
  color: ${paletteHelper("textPrimary")};
  display: inline-block;
`;

export const SuccessLgGreen = styled(SuccessLg)`
  color: ${({ theme }) => theme.palette.textSuccess};
  display: inline-block;
  margin-right: ${spacingHelper("large")};
  height: ${spacingHelper("threeExtraLarge")};
  width: ${spacingHelper("threeExtraLarge")};
`;

export const ResponsiveLinkButton = styled(Link).attrs({
  variant: "button-primary",
  fullWidth: true,
})`
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    flex: 1;
    margin-left: ${spacingHelper("extraSmall")};
  }
`;

export const ResponsiveButton = styled(Button).attrs({
  variant: "outline",
})`
  margin-top: ${spacingHelper("medium")};
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    flex: 1;
    margin-right: ${spacingHelper("extraSmall")};
    margin-top: 0;
  }
`;

export const Dialog = styled(DialogBase)`
  padding: ${spacingHelper("medium")};
  height: ${DIALOG_HEIGHT};
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    padding: ${spacingHelper("extraLarge")};
    width: ${DIALOG_WIDTH};
    height: auto;
  }
`;

export const CloseButton = styled(CloseButtonBase)`
  right: ${spacingHelper("small")};
  top: ${spacingHelper("small")};
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    right: ${spacingHelper("large")};
    top: ${spacingHelper("large")};
  }
`;
