import CarouselAdComponent from './carousel/CarouselAdComponent';
import VideoAdComponent from './video/VideoAdComponent';
import GalleryAdComponent from './gallery/GalleryAdComponent';
import BasicAdComponent from './basic/BasicAdComponent';
import VideoGalleryAdComponent from './video-gallery/VideoGalleryAdComponent';
import OustreamVideoAdComponent from './outstream-video/OustreamVideoAdComponent';

export const createNativeComponentRender = (adtype) => {
  switch (adtype) {
    case 'carousel':
    case 'carousel-lead':
      return new CarouselAdComponent(adtype);
    case 'video':
      return new VideoAdComponent();
    case 'photogallery':
      return new GalleryAdComponent();
    case 'photogallery-video':
      return new VideoGalleryAdComponent();
    case 'outstream-video':
      return new OustreamVideoAdComponent();
    default:
      return new BasicAdComponent(adtype);
  }
};
