import { LeadAdData, AdaptedLeadAdData } from '../../types';

export const adaptLeadAdDataResponse = ({
  phonerequired,
  runexperiment,
  projectid,
  agencyid,
  creativeid,
  extensioncampaignid,
  ...rest
}: LeadAdData): AdaptedLeadAdData => ({
  ...rest,
  projectid: sasOptionToString(projectid),
  agencyid: sasOptionToString(agencyid),
  creativeid: sasOptionToString(creativeid),
  extensioncampaignid: sasOptionToString(extensioncampaignid),
  phonerequired: sasStringToBoolean(phonerequired, false),
  runexperiment: sasStringToBoolean(runexperiment, true),
});

const sasStringToBoolean = (input: string, defaultValue: boolean): boolean => {
  if (typeof input === 'string') {
    switch (input.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

const sasOptionToString = (input: string | undefined): string =>
  input ? input : '';
