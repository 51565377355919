import React from "react";
import { Address, Event } from "@realestate.com.au/planner-add-to-calendar";
import { SummaryButtonsWrapper, SummaryWrapper } from "./styles";
import InspectionSummary from "./InspectionSummary";
import { Button, Link } from "@rea-group/construct-kit-core";

type Props = {
  event: Event;
  address: Address;
  templatedUrl: string;
  onAddToCalendarClick: () => void;
  sourcePage: string;
  sourceElement: string;
};

const InspectionSummaryWithButtons = ({
  event,
  address,
  templatedUrl,
  onAddToCalendarClick,
  sourcePage,
  sourceElement,
}: Props) => {
  let plannerUrl = `/planner`;
  if (sourcePage && sourceElement) {
    plannerUrl += `?sourcePage=${sourcePage}&sourceElement=${sourceElement}`;
  }

  return (
    <SummaryWrapper>
      <InspectionSummary
        displayDateTime={{ date: event.displayDate, time: event.displayTime }}
        address={address}
        templatedUrl={templatedUrl}
      />
      <SummaryButtonsWrapper>
        <Button
          variant="naked"
          sizeVariant="medium"
          onClick={onAddToCalendarClick}
        >
          Add to calendar
        </Button>
        <Link variant="button-naked" sizeVariant="medium" href={plannerUrl}>
          See my plan
        </Link>
      </SummaryButtonsWrapper>
    </SummaryWrapper>
  );
};

export default InspectionSummaryWithButtons;
