import renderTemplateImpl from '../renderTemplate';
import NativeAdTrackerImpl from '../NativeAdTracker';
import carouselTemplateImpl from './carouselTemplate';

const transformDirection = {
  PREV: -1,
  NEXT: 1,
};

class CarouselAdComponent {
  constructor(
    adType,
    renderTemplate = renderTemplateImpl,
    carouselTemplate = carouselTemplateImpl,
    NativeAdTracker = NativeAdTrackerImpl,
    fetch = window.fetch.bind(window),
    delaySlideClickTime = 1000,
  ) {
    this.adtype = adType;
    this.renderTemplate = renderTemplate;
    this.NativeAdTracker = NativeAdTracker;
    this.fetch = fetch;
    this.carouselTemplate = carouselTemplate;
    this.offset = 0;
    this.pageIndex = 0;
    this.delaySlideClickTime = delaySlideClickTime;
    this.isLeadAd = adType === 'carousel-lead';
  }

  processJson(json) {
    const pages = json.pages.map((page) => {
      const actionTypeKey = `actionType${page.actiontype
        .substring(0, 1)
        .toUpperCase()}${page.actiontype.substring(1)}`;

      return {
        ...page,
        [actionTypeKey]: true,
      };
    });

    return {
      ...json,
      pages,
    };
  }

  getCarouselPages(json) {
    return json.pages.map((page) => ({
      ...page,
      viewed: false,
    }));
  }

  addAdClickEventListeners(renderTarget, adSpot, processedJson) {
    const brandClickElement = renderTarget.getElementsByClassName(
      'native-carousel__brand',
    )[0];

    const imageQuerySelector = this.isLeadAd
      ? '.native-carousel__image'
      : '.native-carousel__image a';
    const imageClickElements =
      renderTarget.querySelectorAll(imageQuerySelector);

    const textClickElements = renderTarget.getElementsByClassName(
      'native-carousel__text',
    );

    const ctaElementsQuerySelector = this.isLeadAd
      ? '.native-carousel__action-button'
      : '.native-carousel__action-link';
    const ctaElements = renderTarget.querySelectorAll(ctaElementsQuerySelector);

    const clickElements = [
      brandClickElement,
      ...imageClickElements,
      ...textClickElements,
      ...ctaElements,
    ];

    clickElements.forEach(
      (element) =>
        element &&
        element.addEventListener('click', () => {
          if (this.isLeadAd) {
            adSpot.leadClickCallback(this.toLeadAdJson(processedJson));
          }
        }),
    );
  }

  addTermsClickEventListeners(renderTarget) {
    const termsOpenButtons = [
      ...renderTarget.getElementsByClassName(
        'native-carousel__terms-open-button',
      ),
    ];
    if (termsOpenButtons.length === 0) {
      return;
    }

    const termsCloseButtons = [
      ...renderTarget.getElementsByClassName(
        'native-carousel__terms-close-button',
      ),
    ];
    if (termsCloseButtons.length === 0) {
      return;
    }

    const overlay = renderTarget.getElementsByClassName(
      'native-carousel__card-overlay',
    )[0];
    if (!overlay) {
      return;
    }

    termsOpenButtons.forEach((button) =>
      button.addEventListener('click', () => overlay.classList.add('show')),
    );
    termsCloseButtons.forEach((button) =>
      button.addEventListener('click', () => overlay.classList.remove('show')),
    );
  }

  render(json, renderTarget, adSpot) {
    this.offset = 0;
    this.pageIndex = 0;

    const processedJson = this.processJson(json);
    processedJson.isLeadAd = this.isLeadAd;

    return this.renderTemplate(
      renderTarget,
      processedJson,
      this.carouselTemplate,
    ).then(() => {
      this.loadCarousel(renderTarget, processedJson);
      this.addAdClickEventListeners(renderTarget, adSpot, processedJson);
      this.addTermsClickEventListeners(renderTarget);
    });
  }

  loadCarousel(element, json) {
    const carouselPages = this.getCarouselPages(json);
    this.displayCarousel(element, carouselPages);
    this.trackFirstCarouselPageView(carouselPages);
    this.trackThirdParty(json.trackers);
    this.addLogoClickTracking(element, json.trackers);
  }

  addLogoClickTracking(element, trackers) {
    const logo = element.getElementsByClassName('native-carousel__brand')[0];
    if (logo) {
      logo.addEventListener('click', () => {
        this.NativeAdTracker.trackLogoClick(trackers);
      });
    }
  }

  trackThirdParty(trackers) {
    this.NativeAdTracker.trackDownloadImpression(trackers);
  }

  displayCarousel(element, carouselPages) {
    const slides = element.getElementsByClassName('native-carousel__slide');
    const wrapper = element.getElementsByClassName(
      'native-carousel__slide-wrapper',
    )[0];
    const nextArrow = element.getElementsByClassName(
      'native-carousel__arrow-next',
    )[0];
    const prevArrow = element.getElementsByClassName(
      'native-carousel__arrow-prev',
    )[0];
    const nextArrowPlaceholder = element.getElementsByClassName(
      'native-carousel__arrow-next-placeholder',
    )[0];
    const prevArrowPlaceholder = element.getElementsByClassName(
      'native-carousel__arrow-prev-placeholder',
    )[0];

    if (slides.length === 0) return;

    const slideWidthMarginPx = this.getSlideWidthMargin(slides);
    const slideWidthPx = slides[0].offsetWidth;
    const slideWidthWithMargin = this.getSlideWidthPercent(
      slideWidthPx + slideWidthMarginPx,
      wrapper,
    );
    const slideWidthWithoutMargin = this.getSlideWidthPercent(
      slideWidthPx,
      wrapper,
    );
    const nextPageSnipetWidth = 100 - slideWidthWithoutMargin;
    const slideWidthMinusNextSlideWidth =
      slideWidthWithoutMargin - nextPageSnipetWidth;

    this.addNextArrowClickEventListener(
      element,
      slides,
      wrapper,
      nextArrow,
      nextArrowPlaceholder,
      prevArrow,
      prevArrowPlaceholder,
      carouselPages,
      slideWidthWithMargin,
      slideWidthMinusNextSlideWidth,
    );

    this.addPrevArrowClickEventListener(
      element,
      slides,
      wrapper,
      nextArrow,
      nextArrowPlaceholder,
      prevArrow,
      prevArrowPlaceholder,
      carouselPages,
      slideWidthWithMargin,
      slideWidthMinusNextSlideWidth,
    );
  }

  getSlideWidthMargin(slides) {
    const computedStyle = getComputedStyle(slides[0]);
    return parseInt(computedStyle.marginRight);
  }

  getSlideWidthPercent(slideWidthPx, wrapper) {
    const slideParentWidthPx = wrapper.offsetWidth;
    return Math.round((slideWidthPx / slideParentWidthPx) * 100);
  }

  addNextArrowClickEventListener(
    element,
    slides,
    wrapper,
    nextArrow,
    nextArrowPlaceholder,
    prevArrow,
    prevArrowPlaceholder,
    carouselPages,
    slideWidth,
    slideWidthMinusNextSlideWidth,
  ) {
    nextArrow.addEventListener('click', () => {
      this.offset -= this.isSecondLastPage(slides)
        ? slideWidthMinusNextSlideWidth
        : slideWidth;

      this.switchPage(
        element,
        wrapper,
        slides,
        carouselPages,
        nextArrow,
        nextArrowPlaceholder,
        prevArrow,
        prevArrowPlaceholder,
        transformDirection.NEXT,
      );
    });
  }

  addPrevArrowClickEventListener(
    element,
    slides,
    wrapper,
    nextArrow,
    nextArrowPlaceholder,
    prevArrow,
    prevArrowPlaceholder,
    carouselPages,
    slideWidth,
    slideWidthMinusNextSlideWidth,
  ) {
    prevArrow.addEventListener('click', () => {
      this.offset += this.isLastPage(slides)
        ? slideWidthMinusNextSlideWidth
        : slideWidth;

      this.switchPage(
        element,
        wrapper,
        slides,
        carouselPages,
        nextArrow,
        nextArrowPlaceholder,
        prevArrow,
        prevArrowPlaceholder,
        transformDirection.PREV,
      );
    });
  }

  switchPage(
    element,
    wrapper,
    slides,
    carouselPages,
    nextArrow,
    nextArrowPlaceholder,
    prevArrow,
    prevArrowPlaceholder,
    pageChange,
  ) {
    wrapper.style.transform = `translateX(${this.offset}%)`;

    this.pageIndex += pageChange;

    this.switchCardContent(element);

    this.showOrHideArrows(
      slides,
      nextArrow,
      nextArrowPlaceholder,
      prevArrow,
      prevArrowPlaceholder,
    );

    const carouselPage = carouselPages[this.pageIndex];
    this.trackCarouselPageView(carouselPage);
  }

  switchCardContent(element) {
    const cardContents = element.getElementsByClassName(
      'native-carousel__card-content',
    );

    [...cardContents].forEach((cardContent, i) => {
      cardContent.style.display = i === this.pageIndex ? 'flex' : 'none';
    });
  }

  showOrHideArrows(
    slides,
    nextArrow,
    nextArrowPlaceholder,
    prevArrow,
    prevArrowPlaceholder,
  ) {
    if (this.isFirstPage()) {
      prevArrow.style.display = 'none';
      this.enableSlideClickAfterDelay(prevArrowPlaceholder, () =>
        this.isFirstPage(),
      );
    } else {
      prevArrow.style.display = 'block';
    }

    if (this.isLastPage(slides)) {
      nextArrow.style.display = 'none';
      this.enableSlideClickAfterDelay(nextArrowPlaceholder, () =>
        this.isLastPage(slides),
      );
    } else {
      nextArrow.style.display = 'block';
    }
  }

  enableSlideClickAfterDelay(arrowPlaceholder, shouldHidePlaceholder) {
    arrowPlaceholder.style.display = 'block';
    setTimeout(() => {
      if (shouldHidePlaceholder()) {
        arrowPlaceholder.style.display = 'none';
      }
    }, this.delaySlideClickTime);
  }

  isFirstPage() {
    return this.pageIndex === 0;
  }

  isSecondLastPage(slides) {
    return this.pageIndex === slides.length - 2;
  }

  isLastPage(slides) {
    return this.pageIndex === slides.length - 1;
  }

  trackFirstCarouselPageView(carouselPages) {
    if (carouselPages.length > 0) {
      this.trackCarouselPageView(carouselPages[0]);
    }
  }

  trackCarouselPageView(carouselPage) {
    if (carouselPage.viewedbeacon && !carouselPage.viewed) {
      this.fetch(carouselPage.viewedbeacon, { credentials: 'include' });
      carouselPage.viewed = true;
    }
  }

  toLeadAdJson(processedJson) {
    const currentPage = processedJson.pages[this.pageIndex];
    return {
      adtype: this.adtype,
      admessagetext: currentPage.admessagetext,
      heroimageurl: currentPage.heroimageurl,
      brandlogourl: processedJson.brandlogourl,
      projecttitle: processedJson.projecttitle,
      projectid: processedJson.projectid,
      agencyid: processedJson.agencyid,
      phonerequired: processedJson.phonerequired,
      runexperiment: processedJson.runexperiment,
      addescription: processedJson.addescription,
      actionurl: processedJson.actionurl,
      impressionurl: processedJson.impressionurl,
      leadcompleteurl: processedJson.leadcompleteurl,
      // todo need to delete this later
      enquirysubmissionurl: processedJson.enquirysubmissionurl,
      lmscampaignid: processedJson.lmscampaignid,
      trackers: processedJson.trackers,
      creativeid: processedJson.creativeid,
      extensioncampaignid: processedJson.extensioncampaignid,
    };
  }
}

export default CarouselAdComponent;
