import generateSafeframeAdImpl from './safeframe/generateSafeframeAd';
import generateFriendlyIframeAdImpl from './generateFriendlyIframeAd';
import generateCeltraAdImpl from './generateCeltraAd';

export default (
    generateSafeframeAd = generateSafeframeAdImpl,
    generateFriendlyIframeAd = generateFriendlyIframeAdImpl,
    generateCeltraAd = generateCeltraAdImpl,
  ) =>
  (adSpot, response) => {
    const { content, isTrustedHtml, isTrustedCeltraHtml } = response;
    adSpot.nativeType = 'html';
    adSpot.nativeContent = content;

    if (isTrustedHtml) {
      return generateFriendlyIframeAd(adSpot, content);
    }

    if (isTrustedCeltraHtml) {
      return generateCeltraAd(adSpot, content);
    }

    return generateSafeframeAd(adSpot);
  };
