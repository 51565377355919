import { Link } from '@construct-kit/core';
import { useState } from 'react';
import {
  StyledButton,
  StyledDisclaimer,
  StyledDisclaimerDetail,
} from './styles/Disclaimer.style';

const Disclaimer = (): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledDisclaimer>
      <StyledButton onClick={() => setIsExpanded(!isExpanded)}>
        Personal Information Collection Statement
      </StyledButton>
      {isExpanded && (
        <StyledDisclaimerDetail>
          Your personal information and associated behavioural data related to
          search activities will be passed to the Agency and/or its authorised
          service provider to assist the Agency to contact you about your
          property enquiry. They are required not to use your information for
          any other purpose. Our{' '}
          <Link
            variant="inline"
            href="https://about.realestate.com.au/privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy policy
          </Link>{' '}
          explains how we store personal information and how you may access,
          correct or complain about the handling of personal information.
        </StyledDisclaimerDetail>
      )}
      <StyledDisclaimerDetail>
        This form is only to be used for sending genuine email enquiries to the
        Agent. realestate.com.au Pty Ltd reserves its right to take any legal or
        other appropriate action in relation to misuse of this service.
      </StyledDisclaimerDetail>
    </StyledDisclaimer>
  );
};

export default Disclaimer;
