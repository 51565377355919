import { log, clearLoggingData } from './utils/loggingUtil';
import {
  setFeatureToggles,
  getFeatureToggle,
} from './utils/featureTogglesUtils';
import { IS_CLIENT } from './utils/browserUtils';
import { resetViewId } from './guidGenerator';
import renderAd from './renderAd';
import { generateCreateAd } from './createAd';
import clearAdContext from './cleaner';
import withAdvertTargeting from './targeting';
import startPrebidBidding from './api/startPrebidBidding';
import { clearPrebidState } from './generator/prebid';

const Advertorial = {
  allAds: [],
};

Advertorial.startPrebidBidding = (adUnits, options) =>
  startPrebidBidding(adUnits, {
    ...options,
    isDemandManagerTest: getFeatureToggle('demandManagerTest'),
  });

Advertorial.createAdWithDataLayer = (options) => {
  if (!options.dataLayer || !options.appName) {
    log('Missing required fields "dataLayer" and/or "appName"');
    return;
  }
  Advertorial.createAd(withAdvertTargeting(options));
};

Advertorial.createAd = generateCreateAd(Advertorial);

Advertorial.renderAd = renderAd;

Advertorial.refreshView = () => {
  resetViewId();
  Advertorial.allAds = [];
  clearAdContext();
  clearPrebidState();
  clearLoggingData();
};

const featureToggles = ['sasStaging', 'demandManagerTest', 'rui_debug'];
IS_CLIENT && setFeatureToggles(featureToggles);

export default Advertorial;
