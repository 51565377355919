import { useEffect, useRef } from 'react';
import Advertorial from '@media/rui-advertorial';

type Props = {
  appName: string;
  prebidConfig?: Record<string, unknown>[];
  dataLayer?: Record<string, unknown>;
  variation?: string | null;
};

const Prebid = ({
  dataLayer,
  prebidConfig,
  appName,
  variation,
}: Props): null => {
  const dataLayerRef = useRef(dataLayer);

  useEffect(() => {
    dataLayerRef.current = dataLayer;
  }, [dataLayer]);

  useEffect(() => {
    prebidConfig &&
      Advertorial.startPrebidBidding(prebidConfig, {
        appName,
        dataLayer: dataLayerRef.current,
        variation: variation ?? undefined,
      });
  }, [appName, prebidConfig, variation]);

  return null;
};

export default Prebid;
