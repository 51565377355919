import React from "react";
import { Select, SelectOption } from "@rea-group/construct-kit-core";
import { EventItem } from "../../../../types";

type Props = {
  events: Array<EventItem>;
  onEventSelect: (eventItem?: EventItem) => void;
};

export const InspectionDropdown = ({ events, onEventSelect }: Props) => {
  const selectOptions = events.map((event) => ({
    value: event.input.startTime,
    label: event.event.displayDate + ", " + event.event.displayTime,
  }));

  const onSelectedOptionChange = (selectedOption?: SelectOption | null) => {
    const selectedEventItem = events.find(
      (eventItem) => eventItem.input.startTime === selectedOption?.value
    );
    onEventSelect(selectedEventItem);
  };

  return (
    <Select
      options={selectOptions}
      onSelectedOptionChange={onSelectedOptionChange}
      label="Inspection times"
      hideLabel
    />
  );
};
