import { createNativeComponentRender as createNativeComponentRenderImpl } from './native/createNativeComponentFactory';
import createJsonAdHandlerCommon from './createJsonAdHandlerCommon';

const createJsonAdHandler =
  (createNativeComponentRender = createNativeComponentRenderImpl) =>
  (adSpot, adContent) => {
    const {
      content: { adtype },
    } = adContent;
    const nativeComponentRenderer = createNativeComponentRender(adtype);

    return createJsonAdHandlerCommon(nativeComponentRenderer)(
      adSpot,
      adContent,
    );
  };

export default createJsonAdHandler;
