import mustache from 'mustache';

const onRejected = (reason) => {
  throw new Error(reason);
};

const handleResponseStatus = (response) => {
  if (response.status === 200) {
    return response;
  }
  throw new Error('Cannot load ad template from URL.');
};

const loadTemplate = (url) =>
  fetch(url)
    .then(handleResponseStatus, onRejected)
    .then((response) => response.text());

const embedScripts = (renderTarget, embeddedScripts) => {
  if (Array.isArray(embeddedScripts)) {
    embeddedScripts.forEach((script) => {
      if (script.url === undefined) {
        return;
      }

      const scriptTag = document.createElement('script');
      scriptTag.src = script.url;

      renderTarget.appendChild(scriptTag);
    });
  }
};

const renderTemplate = (renderTarget, json, defaultTemplate) => {
  const {
    forcetemplateurl: forceTemplateUrl,
    embeddedscripts: embeddedScripts,
    site,
    area,
  } = json;

  let getTemplate;

  // temporarily add this code to handle rca native uplift for RCA, so RCA PDP page render the native ads correctly
  if (
    site === 'rca' &&
    ['buy.details', 'rent.details', 'sold.details', 'leased.details'].includes(
      area,
    )
  ) {
    getTemplate = loadTemplate(
      'https://ads-cdn.reastatic.net/rea-native-ad/template/20190916172051/responsive/basic-website-template-rca.html.mst',
    );
  } else if (forceTemplateUrl) {
    getTemplate = loadTemplate(forceTemplateUrl);
  } else if (defaultTemplate) {
    getTemplate = Promise.resolve(defaultTemplate);
  } else {
    return Promise.reject(new Error('No native ad template found.'));
  }

  return getTemplate.then((template) => {
    const rendered = mustache.render(template, json);
    renderTarget.innerHTML = rendered;

    embedScripts(renderTarget, embeddedScripts);
  });
};

export default renderTemplate;
