import { getQueryParams } from './browserUtils';

const featureToggles = [];

const setFeatureToggles = (toggleNames) => {
  const params = getQueryParams(window);

  toggleNames.forEach((toggleName) => {
    if (toggleName in params) {
      featureToggles[toggleName] = params[toggleName] === 'true';
    }
  });
};

const getFeatureToggle = (toggleName) => featureToggles[toggleName];

export { setFeatureToggles, getFeatureToggle };
