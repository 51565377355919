import React, { FC } from "react";
import ResponsiveModal from "../shared/ResponsiveModal";
import DialogBox, { Props as DialogBoxProps } from "./DialogBox";

type Props = DialogBoxProps & {
  isOpen: boolean;
  modalMountID: string;
};

const AddToPlannerModal: FC<Props> = (props) => {
  return (
    <ResponsiveModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      modalMountID={props.modalMountID}
    >
      <DialogBox {...props} />
    </ResponsiveModal>
  );
};

export default AddToPlannerModal;
