import type { EventSummaryData, AddToCalendarSummary } from "../types";

export const constructSummary = ({
  address,
  displayDate,
  displayTime,
  displayString,
}: EventSummaryData): AddToCalendarSummary => {
  const shortAddress = address.display.shortAddress
    ? `${address.display.shortAddress}, `
    : "";
  return {
    eventLocation: `${displayString} for ${shortAddress}${address.suburb} on `,
    eventDate: `${displayDate} at ${displayTime}`,
  };
};
