const nativeAdCache = {};

const filterDownloadImpression = (trackers) =>
  trackers &&
  trackers.filter(
    (tracker) => tracker.actionname !== 'downloadimpressionbeacon',
  );

const putCache = (key, adJson) => {
  const cachedJson = {
    ...adJson,
    trackers: filterDownloadImpression(adJson.trackers),
    advimptracker: undefined,
  };
  nativeAdCache[key] = cachedJson;
};

const getCache = (key) => nativeAdCache[key];

export { putCache, getCache };
