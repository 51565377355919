import { ErrorType } from "./error-dialog/types";

export const handleError = (
  error: unknown,
  setError: (errorType: ErrorType) => void
) => setError(getErrorType(error));

export const shouldRenderError = (
  error: ErrorType | null
): error is ErrorType => {
  return error === "network" || error === "unknown";
};

export const getErrorType = (error: unknown) =>
  (error as Error).message === "Failed to fetch" ? "network" : "unknown";
