import { IS_CLIENT } from './browserUtils';

const createCookie = (name, value, days, domain) => {
  let cookie;
  let date;

  cookie = `${name}=${value}`;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    cookie += `; expires=${date.toGMTString()}`;
  }

  if (domain) {
    cookie += `; domain=${domain}`;
  }

  cookie += '; path=/';
  document.cookie = cookie;
};

const getCookie = (name) => {
  if (!IS_CLIENT) {
    return null;
  }

  let i;
  let len;
  let c;
  const nameEq = `${name}=`;
  const ca = document.cookie.split(';');

  // cannot use LMI.Lang.forEach here because of the return
  for (i = 0, len = ca.length; i < len; i += 1) {
    c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEq) === 0) {
      return c.substring(nameEq.length, c.length);
    }
  }
  return null;
};

const removeCookie = (name, domain) => {
  createCookie(name, '', -1, domain);
};

export { getCookie, removeCookie, createCookie };
