import renderTemplateImpl from '../renderTemplate';
import basicTemplateImpl from './basicTemplate';

class BasicAdComponent {
  constructor(
    adType,
    renderTemplate = renderTemplateImpl,
    basicTemplate = basicTemplateImpl,
  ) {
    this.adType = adType;
    this.renderTemplate = renderTemplate;
    this.basicTemplate = basicTemplate;
    this.isLeadAd = adType === 'basic-lead';
  }

  addAdClickEventListeners(renderTarget, adSpot, adContentJson) {
    const brandClickElement = renderTarget.getElementsByClassName(
      'native-basic__brand',
    )[0];

    const imageElementClassName = this.isLeadAd
      ? '.native-basic__image'
      : '.native-basic__image a';
    const imageClickElement = renderTarget.querySelector(imageElementClassName);
    const textClickElement =
      renderTarget.getElementsByClassName('native-basic__text')[0];
    const ctaElementsClassName = this.isLeadAd
      ? 'native-basic__action-button'
      : 'native-basic__action-link';
    const ctaElements =
      renderTarget.getElementsByClassName(ctaElementsClassName);

    const clickElements = [
      brandClickElement,
      imageClickElement,
      textClickElement,
      ...ctaElements,
    ];

    clickElements.forEach(
      (element) =>
        element &&
        element.addEventListener('click', (event) => {
          if (this.isLeadAd) {
            adSpot.leadClickCallback(this.toLeadAdJson(adContentJson));
            if (element === ctaElements[0]) {
              event.stopPropagation();
            }
          }
        }),
    );
  }

  addTermsEventListeners(renderTarget) {
    const termsOpenButton = renderTarget.getElementsByClassName(
      'native-basic__terms-open-button',
    )[0];
    if (!termsOpenButton) {
      return;
    }

    const termsCloseButton = renderTarget.getElementsByClassName(
      'native-basic__terms-close-button',
    )[0];
    if (!termsCloseButton) {
      return;
    }

    const overlay = renderTarget.getElementsByClassName(
      'native-basic__card-overlay',
    )[0];
    if (!overlay) {
      return;
    }

    termsOpenButton.addEventListener('click', () =>
      overlay.classList.add('show'),
    );
    termsCloseButton.addEventListener('click', () =>
      overlay.classList.remove('show'),
    );
  }

  render(adContentJson, renderTarget, adSpot) {
    adContentJson.isLeadAd = this.isLeadAd;
    return this.renderTemplate(
      renderTarget,
      adContentJson,
      this.basicTemplate,
    ).then(() => {
      this.addAdClickEventListeners(renderTarget, adSpot, adContentJson);
      this.addTermsEventListeners(renderTarget);
    });
  }

  toLeadAdJson(processedJson) {
    return {
      adtype: processedJson.adtype,
      brandlogourl: processedJson.brandlogourl,
      projecttitle: processedJson.projecttitle,
      projectid: processedJson.projectid,
      agencyid: processedJson.agencyid,
      phonerequired: processedJson.phonerequired,
      runexperiment: processedJson.runexperiment,
      addescription: processedJson.addescription,
      actionurl: processedJson.actionurl,
      impressionurl: processedJson.impressionurl,
      leadcompleteurl: processedJson.leadcompleteurl,
      // todo need to delete this
      enquirysubmissionurl: processedJson.enquirysubmissionurl,
      lmscampaignid: processedJson.lmscampaignid,
      admessagetext: processedJson.admessagetext,
      heroimageurl: processedJson.heroimageurl,
      trackers: processedJson.trackers,
      creativeid: processedJson.creativeid,
      extensioncampaignid: processedJson.extensioncampaignid,
    };
  }
}
export default BasicAdComponent;
