import {
  Stack,
  spacingHelper,
  H2,
  paletteHelper,
  Inline,
} from "@rea-group/construct-kit-core";
import styled from "styled-components";
import DialogBase from "./DialogBase";
import { CloseButtonBase } from "./CloseButtonBase";

const SMALL_DIALOG_WIDTH = "376px";
const DIALOG_WIDTH = "750px";

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacingHelper("threeExtraLarge")};
`;

export const Title = styled(H2).attrs({ variant: "title01" })`
  color: ${paletteHelper("textPrimary")};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    justify-content: center;
  }
`;

export const CloseButton = styled(CloseButtonBase)`
  right: ${spacingHelper("extraSmall")};
  top: ${spacingHelper("extraSmall")};
`;

export const SmallDialog = styled(DialogBase)`
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    min-height: auto;
    width: ${SMALL_DIALOG_WIDTH};
  }
`;

export const Dialog = styled(DialogBase)`
  padding: ${spacingHelper("4rem medium")};
  & > *:not(:last-child) {
    margin-bottom: ${spacingHelper("large")};
  }
  ${({ theme }) => theme.layoutBreakpoints.tablet} {
    padding: ${spacingHelper("extraLarge")};
    width: ${DIALOG_WIDTH};
    height: auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.layoutBreakpoints.medium} {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: ${spacingHelper("extraSmall")};
  }
`;

export const SummaryWrapper = styled(Stack)`
  border: ${paletteHelper("borderSecondary")} 1px solid;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: ${spacingHelper("large large 0")};
  a,
  button {
    border-radius: 0;
  }
  button:not(:last-child) {
    border-right: ${paletteHelper("borderSecondary")} 1px solid;
    margin-right: 0;
  }
`;

export const SummaryButtonsWrapper = styled(Inline)`
  display: flex;
  align-items: center;
  border-top: ${paletteHelper("borderSecondary")} 1px solid;
  padding: ${spacingHelper("extraSmall")} 0;
`;
