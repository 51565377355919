import type { EventData, EventInput } from "../types";
import { eventService } from "../service";
import AddEventToPlannerQuery from "./AddEventToPlanner.graphql";

async function addEventToPlanner(input: EventInput): Promise<EventData> {
  return await eventService("addEventToPlanner", AddEventToPlannerQuery, {
    input,
  });
}
export { addEventToPlanner };
