// http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
const createNewGuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

let viewId = new Date().getTime();

const getViewId = () => viewId;

const resetViewId = () => {
  viewId = new Date().getTime();
};

const generateRandom = () => Math.floor(Math.random() * 1e10);

export {
  createNewGuid as default,
  createNewGuid,
  generateRandom,
  getViewId,
  resetViewId,
};
