import {
  AppleLg,
  EventAddLg,
  Link as BasicLink,
  paletteHelper,
  GoogleGLg,
  Strong,
  spacingHelper,
  OutlookLg,
} from "@rea-group/construct-kit-core";
import React, { SyntheticEvent } from "react";
import type {
  AddToCalendarWithTracking,
  Icon as IconElement,
  IconMap,
} from "../types";
import styled from "styled-components";

const Icon = styled.div`
  align-items: center;
  background: ${paletteHelper("backgroundSecondaryBase")};
  border-radius: 50%;
  color: ${paletteHelper("textPrimary")};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  margin-right: ${spacingHelper("medium")};
`;
export const iconMap: IconMap = {
  apple: <AppleLg />,
  google: <GoogleGLg />,
  other: <EventAddLg />,
  outlook: <OutlookLg />,
};
const getIcon = (iconElement: IconElement) => (
  <Icon>{iconMap[iconElement]}</Icon>
);

const Link = styled(BasicLink).attrs({ variant: "primary" })`
  align-items: center;
  display: flex;
`;

const AddToCalendarLink = ({
  display,
  filename,
  href,
  icon,
  onSaveToCalendarSuccess: onSaveToCalendarSuccess,
}: AddToCalendarWithTracking) => {
  const isGoogle = icon === "google";
  const rel = isGoogle ? "noopener noreferrer" : undefined;
  const target = isGoogle ? "_blank" : undefined;
  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onSaveToCalendarSuccess();
  };
  return (
    <Link
      aria-label={`Save to ${display}`}
      download={filename}
      href={href}
      icon={getIcon(icon)}
      onClick={onClick}
      rel={rel}
      target={target}
    >
      <Strong>{display}</Strong>
    </Link>
  );
};

export default AddToCalendarLink;
