import { useEffect, useRef } from 'react';
import Advertorial from '@media/rui-advertorial';
import { CustomParams } from '../ad-functions/types';

type Props = {
  adKey: string;
  appName: string;
  className?: string;
  position: string;
  sizes: string[];
  id?: string;
  onLoad?: (adProperties: Record<string, unknown>, isAdLoaded: boolean) => void;
  dataLayer?: Record<string, unknown>;
  customParams?: CustomParams;
};

const ScriptAd = ({
  adKey,
  id,
  appName,
  className,
  position,
  sizes,
  onLoad,
  dataLayer,
  customParams,
}: Props): JSX.Element => {
  const adEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (adEl.current) {
      adEl.current.innerHTML = '';
    }

    Advertorial.createAdWithDataLayer({
      appName,
      container: adEl.current,
      config: {
        type: 'lazyjs',
        sz: sizes.join(','),
      },
      params: { pos: position, ...customParams },
      dataLayer,
      loadCompleteCallback: onLoad,
    });
  }, [adKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return <aside id={id} className={className} aria-hidden="true" ref={adEl} />;
};

export default ScriptAd;
