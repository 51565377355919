import { getQueryParams } from './utils/browserUtils';
import { log } from './utils/loggingUtil';

const KILL_SWITCH_PARAM = 'rui_ads_disabled';
const POS_WHITELIST_PARAM = 'rui_ads_only_pos';

const paramOverrideWhitelist = [
  { name: 'env', queryStringKey: 'rui_ads_env' },
  { name: 'fcid', queryStringKey: 'rui_ads_fcid' },
];

function allAdsDisabled(params) {
  return params[KILL_SWITCH_PARAM] === 'true';
}

function hasQueryParam(key, params) {
  return params[key] !== undefined && params[key] !== null;
}

function posRestricted(params) {
  return hasQueryParam(POS_WHITELIST_PARAM, params);
}

function posInWhitelist(params, adSpot) {
  return params[POS_WHITELIST_PARAM].split(',').indexOf(adSpot.params.pos) > -1;
}

function addTargetingParam(adSpot, name, queryStringKey, params) {
  adSpot.params[name] = params[queryStringKey].replace(/[^\w-]/g, '');
  log(
    `AdvertorialDevTools setting "${name}='${adSpot.params[name]}" for AdSpot. ${adSpot}`,
  );
  return adSpot;
}

const AdvertorialDevTools = {
  isDisabled: (adSpot) => {
    const params = getQueryParams();
    return (
      allAdsDisabled(params) ||
      (posRestricted(params) && !posInWhitelist(params, adSpot))
    );
  },
  addDebuggingParams: (adSpot) => {
    let adSpotWithParams = adSpot;
    const params = getQueryParams();
    for (let i = 0; i < paramOverrideWhitelist.length; i += 1) {
      if (hasQueryParam(paramOverrideWhitelist[i].queryStringKey, params)) {
        adSpotWithParams = addTargetingParam(
          adSpot,
          paramOverrideWhitelist[i].name,
          paramOverrideWhitelist[i].queryStringKey,
          params,
        );
      }
    }
    return adSpotWithParams;
  },
};

export default AdvertorialDevTools;
