const NativeAdTracker = {};
const videoPercentKeyMap = {
  1: 'start',
  25: 'firstQuartile',
  50: 'midpoint',
  75: 'thirdQuartile',
  100: 'complete',
};

function trackByImage(tracker) {
  new Image().src = tracker;
}

function track(trackers, actionName) {
  if (Array.isArray(trackers)) {
    trackers.forEach((tracker) => {
      if (tracker.actionname === actionName) {
        tracker.beaconurls.forEach((beaconUrl) => {
          if (beaconUrl) {
            trackByImage(beaconUrl);
          }
        });
      }
    });
  }
}

NativeAdTracker.trackDownloadImpression = (trackers) => {
  track(trackers, 'downloadimpressionbeacon', true);
};

NativeAdTracker.trackVideoPlayButtonClick = (trackers) => {
  track(trackers, 'clickToPlay');
};

NativeAdTracker.trackSoundControlClick = (trackers) => {
  track(trackers, 'soundControlClick');
};

NativeAdTracker.trackLogoClick = (trackers) => {
  track(trackers, 'brandwebsiteurl', true);
};

NativeAdTracker.trackPercentagePlayed = (trackers, percent) => {
  track(trackers, videoPercentKeyMap[percent]);
};

NativeAdTracker.trackHeroAds = (trackers) => {
  trackers.forEach((val) => {
    const tracker = val.replace('<RANDOM>', new Date().getTime());
    trackByImage(tracker);
  });
};

export default NativeAdTracker;
