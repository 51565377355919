import checkIsAdContainerInViewport from './checkIsAdContainerInViewport';
import renderOutstreamVideoSafeframe from './renderOutstreamVideoSafeframe';

class OustreamVideoAdComponent {
  render(content, renderTarget, adSpot) {
    return checkIsAdContainerInViewport(adSpot.element).then(() =>
      renderOutstreamVideoSafeframe(content, renderTarget, adSpot),
    );
  }
}

export default OustreamVideoAdComponent;
