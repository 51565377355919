import React, { FC } from "react";
import { Address, Event } from "@realestate.com.au/planner-add-to-calendar";
import { CrossLg, Text } from "@rea-group/construct-kit-core";
import {
  Dialog,
  CloseButton,
  Header,
} from "../shared/registration-dialog/styles";
import {
  SuccessLgGreen,
  AddedToPlanSuccessMessage,
  RegisterButton,
} from "./styles";
import WrapperWithGuideMessage from "../shared/WrapperWithGuideMessage";
import InspectionSummaryWithButtons from "../shared/InspectionSummaryWithButtons";
import { SEE_PLANNED_INSPECTIONS_MODAL_CONFIRM } from "../../const";

type Props = {
  event: Event;
  address: Address;
  templatedUrl: string;
  shouldShowRegister: boolean;
  onAddToCalendarClick: () => void;
  onClose: () => void;
  showRegistrationDialog: () => void;
  sourcePage: string;
};

const AddedToPlanDialog: FC<Props> = ({
  event,
  address,
  templatedUrl,
  onAddToCalendarClick,
  shouldShowRegister,
  onClose,
  showRegistrationDialog,
  sourcePage,
}) => {
  return (
    <Dialog>
      <Header>
        <AddedToPlanSuccessMessage>
          <SuccessLgGreen />
          Added to your plan!
        </AddedToPlanSuccessMessage>
      </Header>
      <CloseButton onClick={onClose} icon={<CrossLg />}>
        Close
      </CloseButton>
      <InspectionSummaryWithButtons
        event={event}
        address={address}
        templatedUrl={templatedUrl}
        onAddToCalendarClick={onAddToCalendarClick}
        sourcePage={sourcePage}
        sourceElement={SEE_PLANNED_INSPECTIONS_MODAL_CONFIRM}
      />
      {shouldShowRegister && (
        <WrapperWithGuideMessage>
          <Text>
            Confirm your details beforehand to spend more time inspecting on the
            day.
          </Text>
          <RegisterButton onClick={showRegistrationDialog}>
            Register
          </RegisterButton>
        </WrapperWithGuideMessage>
      )}
    </Dialog>
  );
};

export default AddedToPlanDialog;
