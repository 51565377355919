import { adTypes } from './requestAd';
import createJsonAdHandler from './createJsonAdHandler';
import createHtmlAdHandler from './createHtmlAdHandler';
import createPrebidAdHandler from './createPrebidAdHandler';

const jsonAdHandler = createJsonAdHandler();
const htmlAdHandler = createHtmlAdHandler();
const prebidAdHandler = createPrebidAdHandler();

const handleNoAd = (adSpot) => {
  adSpot.loadCompleteCallback?.(undefined, false);
  return Promise.resolve('No ad returned');
};

const renderAd =
  (
    adSpot,
    handleJsonAd = jsonAdHandler,
    handleHtmlAd = htmlAdHandler,
    handlePrebidAd = prebidAdHandler,
  ) =>
  (response) => {
    const { adType } = response;

    switch (adType) {
      case adTypes.NO_AD:
        return handleNoAd(adSpot, response);
      case adTypes.NATIVE:
        return handleJsonAd(adSpot, response);
      case adTypes.PREBID:
        return handlePrebidAd(adSpot, response);
      case adTypes.HTML:
        return handleHtmlAd(adSpot, response);
      default:
        return Promise.reject();
    }
  };

export default renderAd;
