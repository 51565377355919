import { IS_CLIENT } from './browserUtils';
import { getFeatureToggle } from './featureTogglesUtils';

let eventData = {};
const loggingPrefix = '%cAdvertorial';
const loggingStyle =
  'display: inline-block; color: #fff; background: #1CB954; padding: 1px 4px; border-radius: 3px;';

const isDebugEnabled = () => getFeatureToggle('rui_debug') === true;

const log = (...message) => {
  if (isDebugEnabled()) {
    console.log(loggingPrefix, loggingStyle, ...message);
  }
};

const logWarning = (...message) => {
  console.warn(loggingPrefix, loggingStyle, ...message);
};

const logError = (...message) => {
  console.error(loggingPrefix, loggingStyle, ...message);
};

const logEvent = (event, context) => {
  const {
    data: { event_name: eventName, ...otherData },
  } = event;
  const {
    media_ads: {
      data: { group_id: groupId, position },
    },
  } = context;

  eventData[groupId] = {
    groupId,
    position,
    ...(eventData[groupId] || {}),
    ...otherData,
  };

  log(`${position} ${eventName}`, otherData);
};

const logTable = (fields) => {
  console.table(Object.values(eventData), fields);
};

if (IS_CLIENT) {
  window.RuiAdvertorial = {
    logTable,
  };
}

const clearLoggingData = () => {
  eventData = {};
};

export {
  log,
  logWarning,
  logError,
  logEvent,
  clearLoggingData,
  isDebugEnabled,
};
