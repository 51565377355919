const clearSafeframes = () => {
  if (window.$sf && window.$sf.host) {
    window.$sf.host.cleanup();
  }
};

const clearAdContext = () => {
  clearSafeframes();
};

export default clearAdContext;
