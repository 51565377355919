import { log } from '../utils/loggingUtil';

const replace = (value, mapping) => mapping[value] || value;
export const lowercase = (value) => value?.toLowerCase();
export const removeNonAlpha = (value) => value?.replace(/[^a-z]*/g, '');

const isPdp = (appName, pageType) =>
  (appName === 'property-listings-web' && pageType === 'property details') ||
  (appName === 'rea_global' &&
    (pageType === 'project profile details' ||
      pageType === 'property details'));

const isSrp = (appName, pageType) =>
  (appName === 'property-listings-web' &&
    (pageType === 'search result - list' ||
      pageType === 'search results - list')) ||
  (appName === 'rea_global' &&
    (pageType === 'search result - map' ||
      pageType === 'search result - list'));

const isInspectionSrp = (appName, pageType) =>
  appName === 'property-listings-web' && pageType === 'search result - ofi';

const isAgencySearch = (appName, pageType, siteSection) =>
  appName === 'consumer-search' &&
  (pageType === 'search result - list' ||
    pageType === 'search results - list') &&
  siteSection === 'find agent';

const isGlobalHomepage = (appName, pageType) =>
  appName === 'rea_global' &&
  (pageType === 'homepage' || pageType === 'countrypage');

const mapPage = (datalayer) => ({
  site: datalayer.page.data.site,
  platform:
    replace(datalayer.page.data.responsive_layout, {
      XL: 'web',
      L: 'web',
      M: 'midi',
      S: 'msite',
    }) || 'web',
  channel: datalayer.page.data.site_section,
  sect: replace(datalayer.page.data.page_type, {
    'search result - list': 'resultslist',
    'search result - map': 'resultsmap',
    'search result - ofi': 'salesofi',
    'property details': 'details',
    'project profile details': 'details',
    'search results - list': 'resultslist',
  }),
});

const mapPdp = (datalayer) => {
  const {
    agency_id,
    construction_status,
    marketing_price_range,
    price_range,
    product_depth,
  } = datalayer.listing.data;

  const listingDetails = {
    agent: lowercase(agency_id),
    price: lowercase(
      marketing_price_range ? marketing_price_range : price_range,
    ),
    constructionstatus: construction_status,
    productDepth: product_depth,
  };

  const {
    property_type,
    suburb,
    state,
    postcode,
    bedrooms,
    bathrooms,
    car_spaces,
    marketing_region,
    region,
  } = datalayer.property.data;

  const propertyDetails = {
    proptype: property_type,
    sub: removeNonAlpha(lowercase(suburb)),
    state: lowercase(state),
    pcode: postcode,
    beds: bedrooms,
    baths: bathrooms,
    cars: car_spaces,
    region: marketing_region ? marketing_region : region,
  };

  return {
    ...mapPage(datalayer),
    ...listingDetails,
    ...propertyDetails,
    posttags: replace(datalayer.page.data.site_section, {
      buy: 'buyx',
      sold: 'soldx',
      rent: 'rentx',
    }),
  };
};

const mapSrp = (datalayer) => {
  const {
    agency_id,
    suburb,
    state,
    marketing_price_range,
    price_range,
    bedrooms,
    bathrooms,
    car_spaces,
    construction_status,
    property_type,
    marketing_region,
    region,
    postcode,
  } = datalayer.first_search_result.data;
  const firstSearchResultDetails = {
    agent: lowercase(agency_id),
    sub: removeNonAlpha(lowercase(suburb)),
    state: lowercase(state),
    price: lowercase(
      marketing_price_range ? marketing_price_range : price_range,
    ),
    beds: bedrooms,
    baths: bathrooms,
    cars: car_spaces,
    constructionstatus: construction_status,
    proptype: property_type,
    region: marketing_region ? marketing_region : region,
    pcode: postcode,
  };

  const { bedrooms_min, bedrooms_max } =
    datalayer.listing_search_parameters.data;
  const listingSearchParametersDetails = {
    bedmin: replace(bedrooms_min, { studio: '0' }) || '-1',
    bedmax: replace(bedrooms_max, { studio: '0' }) || '-1',
  };

  return {
    ...mapPage(datalayer),
    ...firstSearchResultDetails,
    ...listingSearchParametersDetails,
    posttags:
      datalayer.page.data.page_type === 'search result - ofi'
        ? 'inspectionx'
        : replace(datalayer.page.data.site_section, {
            buy: 'buyx',
            sold: 'soldx',
            rent: 'rentx',
          }),
  };
};

const mapAgencySearch = (datalayer) => {
  const pageDetails = {
    platform: datalayer.page.data.platform,
    channel: replace(datalayer.page.data.site_section, {
      'find agent': 'agent',
    }),
  };

  const { suburb, state, postcode, channel } =
    datalayer.agency_search_parameters_data.data;

  const agencySearchParametersDetails = {
    sub: removeNonAlpha(lowercase(suburb)),
    state: lowercase(state),
    pcode: postcode,
    cat: replace(channel, {
      Buy: 'selling',
      Rent: 'leasing',
    }),
  };

  return {
    ...mapPage(datalayer),
    ...pageDetails,
    ...agencySearchParametersDetails,
  };
};

const removeUndefinedValues = (result) =>
  Object.keys(result)
    .filter((key) => result[key] !== undefined)
    .reduce((acc, key) => {
      acc[key] = result[key];
      return acc;
    }, {});

const generateTargeting = (appName, datalayer, logError) => {
  if (
    (datalayer?.page?.data &&
      typeof datalayer.page.data === 'object' &&
      Object.keys(datalayer.page.data).length === 0) ||
    !datalayer?.page?.data
  ) {
    const logMessage = 'Missing page schema in "dataLayer"';
    log(logMessage);
    logError?.(logMessage);

    return {
      platform: 'web',
    };
  }

  const { page_type, site_section } = datalayer.page.data;

  if (isPdp(appName, page_type)) {
    return removeUndefinedValues(mapPdp(datalayer));
  }

  if (isSrp(appName, page_type) || isInspectionSrp(appName, page_type)) {
    return removeUndefinedValues(mapSrp(datalayer));
  }

  if (isAgencySearch(appName, page_type, site_section)) {
    return removeUndefinedValues(mapAgencySearch(datalayer));
  }

  if (isGlobalHomepage(appName, page_type)) {
    return removeUndefinedValues(mapPage(datalayer));
  }

  return removeUndefinedValues(mapPage(datalayer));
};

export default generateTargeting;
