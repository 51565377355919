import renderTemplateImpl from '../renderTemplate';
import VideoAdPlaybackManagerImpl from './VideoAdPlaybackManager';
import VideoAdTrackerImpl from './VideoAdTracker';
import VideoAdFullscreenManagerImpl from './VideoAdFullscreenManager';
import VideoAdSeekingManagerImpl from './VideoAdSeekingManager';
import videoTemplateImpl from './videoTemplate';

class VideoAdComponent {
  constructor(
    renderTemplate = renderTemplateImpl,
    videoTemplate = videoTemplateImpl,
    VideoAdPlaybackManager = VideoAdPlaybackManagerImpl,
    VideoAdTracker = VideoAdTrackerImpl,
    VideoAdFullscreenManager = VideoAdFullscreenManagerImpl,
    VideoAdSeekingManager = VideoAdSeekingManagerImpl,
  ) {
    this.renderTemplate = renderTemplate;
    this.videoTemplate = videoTemplate;
    this.VideoAdPlaybackManager = VideoAdPlaybackManager;
    this.VideoAdTracker = VideoAdTracker;
    this.VideoAdFullscreenManager = VideoAdFullscreenManager;
    this.VideoAdSeekingManager = VideoAdSeekingManager;
  }

  processJson(json) {
    const actionTypeKey = `actionType${json.actiontype
      .substring(0, 1)
      .toUpperCase()}${json.actiontype.substring(1)}`;

    return {
      ...json,
      [actionTypeKey]: true,
    };
  }

  addTermsClickEventListeners(renderTarget) {
    const termsOpenButton = renderTarget.getElementsByClassName(
      'native-video__terms-open-button',
    )[0];
    const termsCloseButton = renderTarget.getElementsByClassName(
      'native-video__terms-close-button',
    )[0];
    const overLay = renderTarget.getElementsByClassName(
      'native-video__card-overlay',
    )[0];
    if (!termsOpenButton || !termsCloseButton || !overLay) {
      return;
    }

    termsOpenButton.addEventListener('click', () =>
      overLay.classList.add('show'),
    );
    termsCloseButton.addEventListener('click', () =>
      overLay.classList.remove('show'),
    );
  }

  render(json, renderTarget) {
    const processedJson = this.processJson(json);

    return this.renderTemplate(
      renderTarget,
      processedJson,
      this.videoTemplate,
    ).then(() => {
      this.initVideo(renderTarget, processedJson);
      this.addTermsClickEventListeners(renderTarget);
    });
  }

  initVideo(renderTarget, json) {
    const playButton =
      renderTarget.getElementsByClassName('native-video__play')[0];
    const soundControl = renderTarget.getElementsByClassName(
      'native-video__sound-control',
    )[0];
    const posterImage = renderTarget.getElementsByClassName(
      'native-video__image',
    )[0];
    const video = renderTarget.getElementsByTagName('video')[0];
    const videoContainer = renderTarget.getElementsByClassName(
      'native-video__element-container',
    )[0];
    const logo = renderTarget.getElementsByClassName('native-video__brand')[0];

    video.controls = false;

    this.VideoAdPlaybackManager(
      video,
      videoContainer,
      playButton,
      soundControl,
      posterImage,
    );
    this.VideoAdTracker(video, soundControl, playButton, logo, json.trackers);
    this.VideoAdFullscreenManager(
      video,
      videoContainer,
      playButton,
      posterImage,
    );
    this.VideoAdSeekingManager(video);
  }
}

export default VideoAdComponent;
