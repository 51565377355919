const basicTemplate = `
<section class="native-basic">
  <div class="native-basic__sponsored-label">
    {{#adsponsoredtext}}
      {{adsponsoredtext}}
    {{/adsponsoredtext}}
    {{^adsponsoredtext}}
      ADVERTISEMENT
    {{/adsponsoredtext}}
  </div>
  <div class="native-basic__card">
    {{#termstitle}}
    {{#termstext}}
      <div class="native-basic__card-overlay">
        <h2>Conditions</h2>
        <div class="native-basic__terms">{{termstext}}</div>
        <button class="native-basic__terms-close-button" type="button"><a class="native-basic__terms-close-icon"></a>Close</button>
      </div>
    {{/termstext}}
    {{/termstitle}}
    {{#isLeadAd}}
      <div class="native-basic__brand"><img src="{{brandlogourl}}" alt="logo"></div>
    {{/isLeadAd}}
    {{^isLeadAd}}
      {{#brandwebsiteurl}}
        <a class="native-basic__brand" href="{{brandwebsiteurl}}" target="_blank" rel="noopener"><img src="{{brandlogourl}}" alt="logo"></a>
      {{/brandwebsiteurl}}
      {{^brandwebsiteurl}}
        <div class="native-basic__brand"><img src="{{brandlogourl}}" alt="logo"></div>
      {{/brandwebsiteurl}}
    {{/isLeadAd}}
    <div class="native-basic__image-wrapper">
      <div class="native-basic__image" data-src="{{heroimageurl}}" style="background-image: url('{{heroimageurl}}');">
        {{^isLeadAd}}
          <a href="{{clickurl}}" target="_blank" rel="noopener"></a>
        {{/isLeadAd}}  
        {{#privateinspectiontext}}
          <div class="native-basic_private_inspection-label">
            {{privateinspectiontext}}
          </div>
        {{/privateinspectiontext}}
      </div>
    </div>
    <div class="native-basic__card_cta">
      <div class="native-basic__card-content {{#termstitle}}{{#termstext}}native-basic__card-content-with-terms{{/termstext}}{{/termstitle}}{{^admessagetext2}} native-basic__card-content-no-description{{/admessagetext2}}">
        <div class="native-basic__text">
          {{#isLeadAd}}
            <div class="native-basic__message-text native-basic__title">
              {{admessagetext}}
            </div>
            {{#admessagetext2}}
              <div class="native-basic__message-text native-basic__description">
                {{admessagetext2}}
              </div>
            {{/admessagetext2}}
          {{/isLeadAd}}
          {{^isLeadAd}}
            <a class="native-basic__message-text native-basic__title" href="{{clickurl}}" target="_blank" rel="noopener">
              {{admessagetext}}
            </a>
            {{#admessagetext2}}
              <a class="native-basic__message-text native-basic__description" href="{{clickurl}}" target="_blank" rel="noopener">
                {{admessagetext2}}
              </a>
            {{/admessagetext2}}
          {{/isLeadAd}}
        </div>
        <div class="native-basic__cta">
          {{#isLeadAd}}
            <button class="native-basic__action-button native-basic__action-button-form">
              {{#actionmessagetext}}
                {{actionmessagetext}}
              {{/actionmessagetext}}
              {{^actionmessagetext}}
                Enquire
              {{/actionmessagetext}}
            </button>
          {{/isLeadAd}}
          {{^isLeadAd}}
            <a href="{{clickurl}}" target="_blank" rel="noopener" class="native-basic__action-link native-basic__action-link-website">
              {{#actionmessagetext}}
                {{actionmessagetext}}
              {{/actionmessagetext}}
              {{^actionmessagetext}}
                Enquire
              {{/actionmessagetext}}
            </a>
          {{/isLeadAd}}
        </div>
      </div>
    {{#termstitle}}
    {{#termstext}}
      <button class="native-basic__terms-open-button native-basic__terms-up-arrow">{{termstitle}}</button>
    {{/termstext}}
    {{/termstitle}}
    </div>
  </div>
  {{#beaconurl}}<img src="{{beaconurl}}" class="native-basic__track-img"/>{{/beaconurl}}
  {{#advimptracker}}<img src="{{advimptracker}}" class="native-basic__track-img"/>{{/advimptracker}}
</section>
`;

export default basicTemplate;
