import { MaybeErrorMessage } from '../types';

const isBlank = (s: string): boolean => s.trim() === '';

const containsBadUrl = (s: string): boolean => {
  const urlPattern = new RegExp(
    '(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?',
  );
  return urlPattern.test(s);
};

const isValidEmail = (s: string): boolean => /@.*\./.test(s);

const isValidPhone = (s: string): boolean =>
  /^[+]?[\s\d]*[(]?\d+[)]?[-\s\d]+$/.test(s);
const isSufficientNumber = (s: string): boolean =>
  s.replace(/\D/g, '').length >= 8;

export const errorMessagesTemplate = {
  required: 'Please fill in this field.',
  containsDots: 'Please remove any URLs from this message.',
  emailInvalid: 'Please provide a valid email address.',
  phoneInvalid: {
    insufficient: 'Phone number should be a minimum of 8 digits.',
    format: 'Please provide a valid phone number.',
  },
};

export const validateName = (name: string): MaybeErrorMessage => {
  if (isBlank(name)) {
    return errorMessagesTemplate.required;
  }

  if (containsBadUrl(name)) {
    return errorMessagesTemplate.containsDots;
  }
};

export const validateEmail = (email: string): MaybeErrorMessage => {
  if (isBlank(email)) {
    return errorMessagesTemplate.required;
  }

  if (!isValidEmail(email)) {
    return errorMessagesTemplate.emailInvalid;
  }
};

export const validatePhoneNumber = (
  phoneNumber: string,
  phoneRequired: boolean,
): MaybeErrorMessage => {
  if (isBlank(phoneNumber)) {
    return phoneRequired ? errorMessagesTemplate.required : undefined;
  }

  if (!isValidPhone(phoneNumber)) {
    return errorMessagesTemplate.phoneInvalid.format;
  }

  if (!isSufficientNumber(phoneNumber)) {
    return errorMessagesTemplate.phoneInvalid.insufficient;
  }
};
