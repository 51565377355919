import { getPrebidIframeUrl } from '@media/rui-advertorial';

type Props = {
  locationSearch: string;
};

const buildScript = (src: string): string => `
(function () {
  if (window.loadPrebidIframePromise === undefined) {
    var getPlatform = function () {
      if (window.innerWidth <= 500) {
        return 'msite';
      }
      else if (window.innerWidth <= 999) {
        return 'midi';
      }
      else {
        return 'web';
      }
    };

    var platform = getPlatform();
    var src = '${src}'.replace('(platform)', platform);
    window.loadPrebidIframePromise = new Promise((resolve) => {
      var iframeEl = document.createElement('iframe');
      iframeEl.src = src;
      iframeEl.id = 'prebid';
      iframeEl.name = 'prebid';
      iframeEl.width = '0';
      iframeEl.height = '0';
      iframeEl.style.display = 'none';
      iframeEl.sandbox =
        'allow-scripts allow-popups allow-popups-to-escape-sandbox allow-forms allow-same-origin';
      iframeEl.addEventListener('load', function () {
        resolve(iframeEl);
      });
      document.getElementsByTagName('body')[0].appendChild(iframeEl);
    });
  }
})();
`;

const PrebidIframe = ({ locationSearch }: Props): JSX.Element => (
  <script
    dangerouslySetInnerHTML={{
      __html: buildScript(getPrebidIframeUrl(locationSearch)),
    }}
  ></script>
);

export default PrebidIframe;
