import Advertorial from '@media/rui-advertorial';
import { useEffect, useState } from 'react';
import { AdConfig, AdResponse } from './types';

const useAdResponse = (
  adConfig: AdConfig,
): {
  isLoading: boolean;
  sponsorData: AdResponse | null;
  isAdLoaded: boolean | undefined;
} => {
  const [sponsorData, setSponsorData] = useState<AdResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdLoaded, setIsAdLoaded] = useState<boolean>();

  useEffect(() => {
    const container = document.createElement('div');
    const {
      appName,
      sizes,
      dataLayer,
      position,
      section,
      isPrebid,
      customParams,
    } = adConfig;

    Advertorial.createAdWithDataLayer({
      appName,
      container,
      config: {
        shouldRenderAd: false,
        sz: sizes.join(','),
        ...(sizes.includes('native') && { version: '4' }),
        isPrebid: isPrebid,
      },
      params: {
        pos: position,
        ...(section && { sect: section }),
        ...customParams,
      },
      dataLayer,
      loadCompleteCallback: (
        adResponse: AdResponse,
        isAdLoaded: boolean,
      ): void => {
        setSponsorData(adResponse ?? null);
        setIsLoading(false);
        setIsAdLoaded(isAdLoaded);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, sponsorData, isAdLoaded };
};

export default useAdResponse;
