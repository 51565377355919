function convertSizeArrayToString(sizes) {
  let szString = '';
  for (let i = 0; i < sizes.length; i += 1) {
    szString += `${
      typeof sizes[i] === 'string' ? sizes[i] : sizes[i].join('x')
    },`;
  }
  return szString.substring(0, szString.length - 1);
}

function getWidthsAndHeights(size) {
  const sizes = size.split(',');
  const widthsAndHeights = sizes.reduce(
    (acc, val) => {
      const dimensions = val.split('x');

      if (dimensions.length < 2) {
        return acc;
      }

      const newWidths = acc.widths.concat(parseInt(dimensions[0]));
      const newHeights = acc.heights.concat(parseInt(dimensions[1]));

      return { ...acc, widths: newWidths, heights: newHeights };
    },
    { widths: [], heights: [] },
  );

  return widthsAndHeights;
}

// Converts sizes used by mobile ios ads (which are an array of arrays) into
// a comma-separated string. eg [[100,200],[50,150]] => '100x200,50x150'
const convertArrayToString = (sizes) => {
  if (typeof sizes === 'string') {
    return sizes;
  }
  return convertSizeArrayToString(sizes);
};

const getInitialSize = (sizes) => {
  const widthsAndHeights = getWidthsAndHeights(sizes);

  const maxWidth = Math.max.apply(null, widthsAndHeights.widths);
  const maxHeight = Math.max.apply(null, widthsAndHeights.heights);

  return `${maxWidth}x${maxHeight}`;
};

export { convertArrayToString, getInitialSize };
